<template>
    <div class="EmptyProductList">
        <p
            v-if="message !== ''"
            class="EmptyProductList-message"
            v-html="message"
        />
        <div class="EmptyProductList-illustWrapper">
            <img
                :src="getAnimationBackground"
                class="EmptyProductList-illustBackground"
                alt=""
            >
            <lottie-player
                :path="getAnimationUrl"
                :height="animationHeight"
                :width="animationWidth"
                class="EmptyProductList-illustAnimatedElement"
            />
        </div>
    </div>
</template>

<script>
import LottiePlayer from 'vue-lottie-player'

export default {
    components: {
        LottiePlayer
    },
    props: {
        message: {
            type: String,
            default: ''
        }
    },
    data () {
        return {
            animationHeight: '70%',
            animationWidth: 'auto'
        }
    },
    computed: {
        getAnimationBackground () {
            return `${process.env.VUE_APP_CDN_URL}img/default/empty-lists-illustrations/empty-list-bg.svg`
        },
        getAnimationUrl () {
            return `${process.env.VUE_APP_CDN_URL}img/default/empty-lists-illustrations/empty-product-list-animation.json`
        }
    }
}
</script>

<style lang="scss" scoped>
@import 'globalScss';

.EmptyProductList {
    padding: 0 $s4;

    &-message {
        @extend %h2;

        margin-bottom: 50px;

        // Filter used on phone / tablet
        padding-right: calc(48px + $s4 * 2);
    }

    &-illustWrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
    }

    &-illustBackground {
        width: 90%;
    }

    &-illustAnimatedElement {
        position: absolute;
        top: 50%;
        right: 0;
        left: 0;
        margin-top: -20%;
    }
}
</style>
