<template>
    <div
        v-if="basket.products.length > 0"
        class="CartBasket"
    >
        <p
            v-if="basket.info === 'PROVIDERACCOUNTANDDEVICE'"
            class="CartBasket-number text-left"
        >
            <v-icon icon="mdi mdi-cellphone" />
            <span v-if="basket.deviceLabel"> {{ basket.deviceLabel }} </span>
        </p>
        <p
            v-if="getLabel !== '' || getSubLabel !== ''"
            class="CartBasket-label"
        >
            <span v-if="isCardIconDisplayed">
                <v-icon icon="mdi mdi-card-bulleted-outline CartBasket-icon" />
                {{ getLabel }}
            </span>
            <span v-else-if="getLabel !== ''">{{ getLabel }}</span>
            <span
                v-if="getSubLabel !== ''"
                class="CartBasket-number"
            >
                {{ getSubLabel }}
            </span>
        </p>
        <ul class="CartBasket-list">
            <cart-item
                v-for="cartItem in getBasketProducts"
                v-bind="cartItem"
                :key="cartItem.id"
                :isEditable="isEditable"
                class="CartBasket-item"
            />
        </ul>
    </div>
</template>

<script setup>
import { isEmpty } from 'global-utils'
import { computed } from 'vue'
import { useI18n } from 'vue-i18n'
import CartItem from '@/StoreWeb/components/cart/CartItem.vue'
import config from 'config'

const { t } = useI18n()

const props = defineProps({
    additionalData: {
        type: Object,
        default: null
    },
    basket: {
        type: Object,
        required: true
    },
    isEditable: {
        type: Boolean,
        default: false
    }
})
const hasAddNewProductToBasketAction = (products) => {
    return products.some(product =>
        product.item.actions.some(action => action.type === 'ADD_NEW_PRODUCT_TO_BASKET')
    )
}

const getUpdatedProduct = (product, basket) => {
    return {
        ...product,
        actions: [{
            label: t('cart:fare_media_loading:title'),
            type: 'ADD_NEW_PRODUCT_TO_BASKET',
            route: {
                name: config.landing_page === 'catalog' ? 'home' : 'catalog',
                params: {
                    isTemporary: true,
                    providerId: props.basket.providerId,
                    providerUserId: props.basket.recipientUser?.associationId !== 0 ? props.basket.recipientUser.userId : props.basket.providerUserId,
                    providerUserExternalId: props.basket.recipientUser?.associationId !== 0 ? props.basket.recipientUser.userId : props.basket.providerUserExternalId,
                    fareMediaId: basket.fareMediaId
                }
            }
        }]
    }
}

const getBasketProducts = computed(() => {
    const basket = props.basket
    const products = basket.products || []

    const isValidBasket = !isEmpty(basket) &&
        (basket.basketType === 'FAREMEDIA' || basket.type === 'FAREMEDIA') &&
        basket.providerId &&
        basket.providerUserId &&
        basket.providerUserExternalId &&
        basket.fareMediaId
    if (isValidBasket && hasAddNewProductToBasketAction(products)) {
        const updatedProduct = getUpdatedProduct(products[0], basket)
        return [updatedProduct, ...products.slice(1)]
    }

    return products
})

const getLabel = computed(() => {
    if (!isEmpty(props.basket.ownerFirstName) &&
        !isEmpty(props.basket.ownerLastName) &&
        props.basket.info !== 'PROVIDERACCOUNT' &&
        props.basket.info !== 'ACCOUNT') {
        if (props.basket.ownerFirstName !== 'anonymous' &&
            props.basket.ownerLastName !== 'anonymous') {
            return t('fare_media:card_of').replace('%cardOwner%', props.basket.ownerFirstName + ' ' + props.basket.ownerLastName)
        }
        return t('fare_media:card_number').replace('%cardNumber%', props.basket.fareMediaId)
    }
    return ''
})
const getSubLabel = computed(() => {
    if (isEmpty(props.basket.ownerFirstName) &&
        isEmpty(props.basket.ownerLastName)) {
        if (props.basket.type === 'FAREMEDIA') {
            return ''
        } else if ((props.basket.info === 'PROVIDERACCOUNTANDFAREMEDIA' || props.basket.info === 'PROVIDERFAREMEDIA') &&
            !isEmpty(props.basket.fareMediaId)) {
            return `n° ${props.basket.fareMediaId}`
        }
    } else if (props.basket.ownerFirstName !== 'anonymous' &&
        props.basket.ownerLastName !== 'anonymous') {
        return `n° ${props.basket.fareMediaId}`
    }
    return ''
})

const isCardIconDisplayed = computed(() => {
    return props.basket.info === 'PROVIDERACCOUNTANDFAREMEDIA' || props.basket.info === 'PROVIDERFAREMEDIA'
})
</script>

<style lang="scss" scoped>
@import 'globalScss';

.CartBasket {
    padding-top: $s4;
    border-top: 1px solid $color-lightgray2;
    text-align: left;

    &:first-child {
        padding-top: 0;
        border-top: 0;
    }

    &-user {
        @extend %h3;

        margin: 0 0 20px;
        padding: 12px;
        border-radius: 10px;
        background: $color-lightgray1;
        color: $color-lighterText;

        @media (min-width: $tablet-breakpoint) {
            max-width: 50%;
        }
    }

    &-label {
        @extend %h4;

        :deep(& + .CartBasket-list) {
            padding-left: $s7;
        }

        :deep(& + .CartBasket-list .CartItem:first-child) {
            padding-top: 0;
            border-top: 0;
        }
    }

    &-icon {
        color: $color-lighterText;
    }

    &-list {
        margin: 0;
        padding: 0 0 0 10px;
        list-style: none;
    }
}
</style>
