<template>
    <div class="ProductRequiredFareProfiles">
        <p class="ProductRequiredFareProfiles-description">
            {{ getRequiredFareProfilesDescription }}
        </p>

        <div class="ProductRequiredFareProfiles-customButtonWrapper">
            <v-btn
                color="primary"
                variant="flat"
                @click.prevent="getRouteToUpdateProfile"
            >
                {{ $t('ask:profile') }}
            </v-btn>
        </div>
    </div>
</template>

<script setup>
import { computed } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRouter } from 'vue-router'

const props = defineProps({
    requiredFareProfiles: {
        type: Array,
        required: true
    },
    selectedFareMedia: {
        type: Object,
        required: true
    }
})

const { t } = useI18n()
const router = useRouter()
const getRequiredFareProfilesDescription = computed(() => {
    const profileLabels = props.requiredFareProfiles.map(item => item.label.toLowerCase()).join(', ')
    return t('required_fare_profiles:description') + ' ' + profileLabels
})

const getRouteToUpdateProfile = () => {
    const route = {
        name: 'tickets',
        params: {
            fareMediaId: props.selectedFareMedia?.id,
            tab: 'cards',
            accountId: props.selectedFareMedia?.accountId,
            profileId: props.requiredFareProfiles[0]?.id
        }
    }
    router.push(route)
}
</script>

<style lang="scss" scoped>
@import 'globalScss';

.ProductRequiredFareProfiles {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 145px;
    padding: .5rem;
    font-size: 14px;
    line-height: 1.6em;

    &-customButtonWrapper {
        text-align: right;
    }

    &-customButton {
        display: inline-flex;
        justify-content: flex-end;
        align-items: center;
        min-height: 44px;
        margin-top: 15px;
        padding: 5px 15px;
        border-radius: $border-radius-xsmall;
        background: $color-brandPrimary;
        font-size: 14px;
        font-weight: 500;
        color: $button-primary-foregroundColor;
        transition: background-color .3s;

        &:hover,
        &:active {
            background: darken($color-brandPrimary, 10%);
        }
    }
}
</style>
