<template>
    <div class="FareMediaSelector mb-8">
        <v-card class="rounded-xl">
            <v-card-title
                class="h3 ma-0 FareMediaSelector-title"
            >
                <template v-if="!isEmpty(selectedFareMedia?.id)">
                    <span
                        :class="'Icon' + selectedFareMedia.getIcon()"
                        class="FareMediaSelector-typeIcon"
                    />
                    <span class="FareMediaSelector-description">{{ t('sale_option:basket_info:provider_account_and_faremedia') }}</span>
                </template>
                <template v-else>
                    <span class="FareMediaSelector-description">{{ t('fare_media_selector:default_title') }}</span>
                </template>
            </v-card-title>
            <v-card-text>
                <div
                    v-if="isAuthenticated"
                    class="d-flex justify-space-between align-start gap-3"
                >
                    <div
                        v-if="fareMediaTitle !== ''"
                        class="FareMediaSelector-state"
                    >
                        <span>
                            <router-link
                                :to="{ name: 'tickets', params: { fareMediaId: selectedFareMedia.id }}"
                                class="FareMediaSelector-cardName"
                            >
                                {{ fareMediaTitle }}
                            </router-link>
                            <span v-if="fareMediaSubtitle !== ''">
                                {{ fareMediaSubtitle }}
                            </span>
                        </span>
                    </div>
                    <v-btn
                        v-if="allFareMedias.length > 0"
                        class="rounded-xl"
                        color="primary"
                        elevation="0"
                        @click="setShowFareMediaChoiceModal"
                    >
                        <template
                            v-if="isEmpty(selectedFareMedia)"
                        >
                            {{ t('fare_media:select_card_button_text') }}
                        </template>
                        <template v-else>
                            <v-icon>
                                mdi-swap-horizontal
                            </v-icon>
                            {{ t('button:switch') }}
                        </template>
                    </v-btn>
                    <div
                        v-else
                        class="d-flex flex-wrap gap-2"
                    >
                        <v-btn
                            v-if="providersSupportingLinking.length > 0"
                            class="rounded-xl"
                            color="primary"
                            elevation="0"
                            @click="setShowFareMediaLinkingModal"
                        >
                            <template
                                v-if="isEmpty(selectedFareMedia)"
                            >
                                {{ t('fare_media:select_card_button_text') }}
                            </template>
                            <template v-else>
                                <v-icon>
                                    mdi-swap-horizontal
                                </v-icon>
                                {{ t('button:switch') }}
                            </template>
                        </v-btn>
                        <v-btn
                            v-if="showFareMediaIdModalButton"
                            class="rounded-xl"
                            color="primary"
                            elevation="0"
                            variant="outlined"
                            @click="setShowFareMediaIdModal"
                        >
                            {{ t('button:read_card') }}
                        </v-btn>
                    </div>
                </div>
                <template v-else-if="selectedFareMedia?.id">
                    <div class="d-flex justify-space-between align-start gap-3">
                        <div
                            v-if="fareMediaTitle !== ''"
                            class="FareMediaSelector-state"
                        >
                            <span>
                                <router-link
                                    :to="{ name: 'walletLight', params: { providerId: selectedFareMedia.providerId, fareMediaId: selectedFareMedia.id }}"
                                    class="FareMediaSelector-cardName"
                                >
                                    {{ fareMediaTitle }}
                                </router-link>
                                <span v-if="fareMediaSubtitle !== ''">
                                    {{ fareMediaSubtitle }}
                                </span>
                            </span>
                        </div>
                        <v-btn
                            class="rounded-xl"
                            color="primary"
                            elevation="0"
                            @click="setShowFareMediaIdModal"
                        >
                            <v-icon>
                                mdi-swap-horizontal
                            </v-icon>
                            {{ t('button:switch') }}
                        </v-btn>
                    </div>
                    <div class="FareMediaSelector-loginMessage">
                        {{ t('fare_media_selector:login_for_retrieving_card') }}
                        <v-btn
                            class="FareMediaSelector-loginMessageButton d-inline-flex py-0"
                            color="primary"
                            elevation="0"
                            variant="text"
                            size="x-small"
                            @click="logIn"
                        >
                            {{ t('button:login') }}
                        </v-btn>
                    </div>
                </template>
                <div
                    v-else
                    class="d-flex flex-wrap gap-2"
                >
                    <v-btn
                        class="rounded-xl"
                        color="primary"
                        elevation="0"
                        @click="logIn"
                    >
                        {{ t('button:login') }}
                    </v-btn>
                    <v-btn
                        class="rounded-xl"
                        color="primary"
                        elevation="0"
                        variant="outlined"
                        @click="setShowFareMediaIdModal"
                    >
                        {{ t('button:read_card') }}
                    </v-btn>
                </div>
            </v-card-text>
        </v-card>
        <fare-media-id-modal v-model="showFareMediaIdModal" />
        <fare-media-linking-modal v-model="showFareMediaLinkingModal" />
    </div>
</template>

<script setup>
import { computed, ref } from 'vue'
import { useRoute } from 'vue-router'
import config from 'config'
import { useStore } from 'vuex'
import { useI18n } from 'vue-i18n'
import { isEmpty } from 'global-utils'
import FareMediaIdModal from '@/StoreWeb/components/fare-media/FareMediaIdModal'
import FareMediaLinkingModal from '@/StoreWeb/components/fare-media/FareMediaLinkingModal'
import { startLogInProcess, useLogin } from '@/StoreWeb/js/composables/login-utils'

const emit = defineEmits(['switchFareMediaContext'])

const { isAuthenticated } = useLogin()
const { t } = useI18n()
const store = useStore()
const route = useRoute()

const showFareMediaIdModal = ref(false)
const showFareMediaLinkingModal = ref(false)

const getCartFareMedias = computed(() => store.getters.getCartFareMedias)
const fareMedias = computed(() => store.getters.getWalletFareMedias)
const allFareMedias = computed(() => [...getCartFareMedias.value, ...fareMedias.value])
const fareMediaSubtitle = computed(() => hasSelectedFareMedia.value && !selectedFareMedia.value.isAccount ? selectedFareMedia.value.getSubtitle() : '')
const fareMediaTitle = computed(() => hasSelectedFareMedia.value && !selectedFareMedia.value.isAccount ? selectedFareMedia.value.getTitle() : '')
const hasSelectedFareMedia = computed(() => selectedFareMedia.value?.id !== undefined)
const selectedFareMedia = computed(() => store.state.userModule.selectedFareMedia)

const showFareMediaIdModalButton = computed(() => {
    return isEmpty(config?.providers?.fare_media)
        ? []
        : config.providers.filter(provider => provider.fare_media?.manual_input || provider.fare_media?.reader)
})
const providersSupportingLinking = computed(() => {
    return isEmpty(config.providers)
        ? []
        : config.providers.filter(provider =>
            provider?.fare_media?.add || provider?.device?.add || provider?.account?.add
        )
})

function logIn () {
    startLogInProcess(route)
}

function setShowFareMediaChoiceModal () {
    emit('switchFareMediaContext')
}

function setShowFareMediaIdModal () {
    showFareMediaIdModal.value = true
}

function setShowFareMediaLinkingModal () {
    showFareMediaLinkingModal.value = true
}
</script>

<style lang="scss" scoped>
@import 'globalScss';

.FareMediaSelector {
    &-typeIcon {
        margin-right: $s1;
        font-size: 36px;
        color: $color-brandPrimary;
    }

    &-title {
        @include verticalCenter;

        white-space: break-spaces;
    }

    &-description {
        display: block;
    }

    &-cardName {
        display: block;
        font-weight: $fontWeight-defaultBold;
        color: $color-brandPrimary;
        transition: color .3s ease-in-out;

        &:hover,
        &:active {
            color: $color-brandPrimaryDark;
        }
    }

    &-loginMessage {
        margin-top: $s4;
        margin-bottom: 0;
        padding: $s2 $s4;
        border-radius: $borderRadius-large;
        background-color: rgba($button-primary-backgroundColor, .08);
        font-size: 14px;
        color: $color-lightText;
    }

    &-loginMessageButton :deep(.v-btn__content) {
        font-size: 14px;
        font-weight: $fontWeight-defaultBold;
    }
}
</style>
