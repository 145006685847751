<template>
    <div class="FareMediaAddModal">
        <modal
            v-model="showModal"
            :title="$t('fare_media:add_modal:title')"
            @close-modal="resetNextActionAndClose"
        >
            <div class="FareMediaAddModal-body">
                <div
                    v-if="showLoader"
                    class="d-flex justify-center align-center pa-5"
                >
                    <v-progress-circular
                        indeterminate
                        color="primary"
                    />
                </div>
                <template v-else>
                    <p class="FareMediaAddModal-instructions">
                        {{ $t('fare_media:add_modal:subtitle') }}
                    </p>
                    <fare-media-add-actions
                        @link-fare-media="setShowAccountAssociationAddForm"
                        @order-fare-media="setShowFareMediaAddNewModal(closeModal)"
                    />
                </template>
                <div class="FareMediaAddModal-back">
                    <v-btn
                        class="FareMediaAddModal-backButton"
                        color="primary"
                        variant="text"
                        @click="onBackButtonClick"
                    >
                        {{ $t('button:back') }}
                    </v-btn>
                </div>
            </div>
        </modal>
        <account-association-add-modal
            v-if="showAccountAssociationAddModal"
            v-model="showAccountAssociationAddModal"
        />
    </div>
</template>

<script setup>
import { computed, ref, watch } from 'vue'
import { useStore } from 'vuex'
import { isEmpty } from 'global-utils'
import FareMediaAddActions from '@/StoreWeb/components/fare-media/FareMediaAddActions.vue'
import AccountAssociationAddModal from '@/StoreWeb/components/account/association/AccountAssociationAddModal.vue'
import * as accountAssociationActionTypes from '@/StoreWeb/store/modules/account-association/action-types'
import { useModalActions } from '@/StoreWeb/js/composables/add-faremedia-modals-utils'

const emit = defineEmits(['closeModal', 'goNextAction', 'update:modelValue'])

const props = defineProps({
    isComingFromFareMediaChoiceModal: {
        type: Boolean,
        default: true
    },
    modelValue: {
        type: Boolean,
        required: true
    }
})

const store = useStore()
const { afterCloseAction, getBackAction, setShowFareMediaAddNewModal } = useModalActions()

const showAccountAssociationAddModal = ref(false)

const accountAssociationPendingRequests = computed(() => store.state.accountAssociationModule.accountAssociationPendingRequests)
const accountAssociationTypes = computed(() => store.state.accountAssociationModule.accountAssociationTypes)
const showLoader = computed(() => accountAssociationPendingRequests.value.getAssociationTypes)
const showModal = computed({
    get: () => props.modelValue,
    set: (newValue) => {
        if (!newValue) {
            emit('goNextAction', afterCloseAction.value)
        }

        emit('update:modelValue', newValue)
    }
})

function closeModal () {
    showModal.value = false
}

function setShowAccountAssociationAddForm () {
    afterCloseAction.value = 'shouldShowAccountAssociationAddModal'
    closeModal()
    showAccountAssociationAddModal.value = true
}

function onBackButtonClick () {
    afterCloseAction.value = getBackAction({
        currentModal: 'FareMediaAddModal',
        isComingFromFareMediaChoiceModal: props.isComingFromFareMediaChoiceModal
    })

    closeModal()
}

function resetNextActionAndClose () {
    afterCloseAction.value = null
    closeModal()
    emit('closeModal')
}

watch(() => props.modelValue, (newValue) => {
    if (newValue && isEmpty(accountAssociationTypes.value)) {
        store.dispatch(accountAssociationActionTypes.GET_ACCOUNT_ASSOCIATION_TYPES)
    }
})
</script>

<style lang="scss" scoped>
@import 'globalScss';

.FareMediaAddModal {
    &-body {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

    &-instructions {
        margin-bottom: 25px;
        color: $color-lighterText;
    }

    &-addCard {
        margin: 0;
        font-size: 14px;
        font-weight: $fontWeight-defaultBold;
        text-align: center;

        &Link {
            display: inline-flex;
            align-items: center;
            cursor: pointer;
            color: $color-brandPrimary;
            transition: color .3s;

            &:hover,
            &:active {
                color: $color-brandPrimaryDark;
            }
        }

        &Icon {
            font-size: 36px;
        }
    }

    &-back {
        margin: 0;
        text-align: center;
    }

    &-backButton {
        font-weight: $fontWeight-defaultBold;
    }
}
</style>
