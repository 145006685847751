<template>
    <header
        :class="[(getAuthenticated ? 'MobileHeaderBar--isAuthenticated' : '')]"
        class="MobileHeaderBar"
    >
        <div
            v-if="config.debug_mode"
            class="MobileHeaderBar-left"
        >
            <v-btn
                class="MobileHeaderBar-button"
                label="Debugger"
                size="small"
                color="primary"
                title="Permet d'afficher les éléments masqués nécessaires pour le debugging"
                variant="flat"
                @click.prevent="toggleShowDebugButtons"
            >
                Debugger
            </v-btn>
            <v-btn
                v-if="displayDebugButtons"
                class="MobileHeaderBar-button"
                label="DebugModal"
                color="primary"
                size="small"
                title="Permet d'afficher la modale de debug'"
                variant="flat"
                @click.prevent="setShowModal"
            >
                DebugModal
            </v-btn>
            <v-btn
                v-if="displayDebugButtons && debugMode"
                class="MobileHeaderBar-button"
                buttonType="button"
                label="Refresh"
                color="primary"
                size="small"
                title="Permet de rafraichir la page après avoir activé des mocks"
                variant="flat"
                @click.prevent="refreshPage"
            >
                Refresh
            </v-btn>
            <span>v1.0.0</span>
        </div>
        <div class="MobileHeaderBar-right">
            <button
                v-if="debugMode && debugInformationsDisplay > 2"
                @click="goToCatalogWithAddCartFlowInternalLink"
            >
                Catalog with addToCartFlow
            </button>
            <header-cart class="MobileHeaderBar-cart" />
        </div>
        <div class="MobileHeaderBar-debugModal" />
        <modal
            v-model="showModal"
            :title="'Mobile debugging modal'"
        >
            <div class="MobileHeaderBar-modalBody">
                <debug-panel
                    @close-panel="showModal = false"
                />
            </div>
        </modal>
    </header>
</template>

<script setup>
import { ref, computed } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import HeaderCart from '@/StoreWeb/components/cart/HeaderCart'
import DebugPanel from '@/StoreWeb/components/common/debug/DebugPanel'
import { useLogin } from '@/StoreWeb/js/composables/login-utils'
import config from 'config'

const store = useStore()
const router = useRouter()
const { isAuthenticated } = useLogin()

const showModal = ref(false)
const displayDebugButtons = ref(false)

const debugInformationsDisplay = computed(() => store.state.debuggingModule.debugInformationsDisplay)
const debugMode = computed(() => store.state.debuggingModule.debugMode)
const getAuthenticated = computed(() => isAuthenticated.value)

function goToCatalogWithAddCartFlowInternalLink () {
    const sessionId = localStorage.getItem('applicationSessionId')
    const routeInfo = router.resolve({ name: 'internalLinks', params: { action: 'catalog' }, query: { sessionId } })
    window.location.href = routeInfo.href
}

function refreshPage () {
    window.location.reload()
}

function setShowModal () {
    showModal.value = true
}

function toggleShowDebugButtons () {
    displayDebugButtons.value = !displayDebugButtons.value
}
</script>

<style lang="scss" scoped>
@import 'globalScss';

.MobileHeaderBar {
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    position: sticky;
    top: 0;
    max-width: 100vw;
    height: rem-calc(60);
    border-bottom: rem-calc(1) solid #e8e8e8;
    box-shadow: 0 0 9px 0 rgba(0, 0, 0, .09);
    background: $color-white;

    &-cart {
        display: inline-block;
        vertical-align: top;
        height: 59px;
    }

    &-left {
        display: inline-flex;
        justify-content: flex-start;
        align-items: center;
        gap: 10px;
        padding-left: 15px;
    }

    &-right {
        display: inline-flex;
        justify-content: flex-end;
        flex-grow: 1;
        padding-right: 15px;
    }

    &-cart,
    &-notificationList {
        width: 40px;
        min-width: 40px;
    }

    &-clearTokens,
    &-setExpiredUserToken {
        display: inline-block;
        vertical-align: middle;
        height: 59px;
        padding: 5px;
        font-size: 14px;
        text-align: center;
    }

    &-modalList {
        margin: 0;
        padding: 0;
        list-style: none;
    }

    &-modalListItem {
        margin-bottom: 20px;

        &Link {
            cursor: pointer;
        }
    }

    &-debugModeToggleSwitch {
        background: none !important;
    }

    &-mockSwitchItem {
        margin-bottom: 10px;
    }
}
</style>
