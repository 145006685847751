<template>
    <div class="SaleOptionAnonymousFareMediaChoice">
        <alert-message
            v-if="wrongFareMediaErrorMessage !== null"
            :message="wrongFareMediaErrorMessage"
            class="mb-6"
            type="error"
        />
        <v-text-field
            class="SaleOptionAnonymousFareMediaChoice-field"
            :hide-details="true"
            :label="$t('sale_option:card_number')"
            :model-value="selectedFareMedia"
            density="compact"
            variant="underlined"
            @update:model-value="fareMediaUpdated"
        >
            <template #append>
                <v-btn
                    v-if="getShowGetFareMediaIdThroughNfcButton"
                    :title="$t('sale_option:get_fare_media_id_through_nfc_button')"
                    class="SaleOptionAnonymousFareMediaChoice-nfcButton v-btn__iconStyle"
                    color="primary"
                    elevation="0"
                    variant="text"
                    @click="getFareMediaIdThroughNFC"
                >
                    <v-icon>mdi-nfc</v-icon>
                </v-btn>
                <v-btn
                    :disabled="isSubmitButtonDisabled"
                    :title="$t('button:validate')"
                    class="SaleOptionAnonymousFareMediaChoice-button v-btn__iconStyle"
                    color="primary"
                    elevation="0"
                    @click.stop="updateFareMedia"
                >
                    <icomoon-icon
                        name="Check"
                        class="SaleOptionAnonymousFareMediaChoice-buttonIcon"
                    />
                </v-btn>
            </template>
        </v-text-field>
        <a
            class="SaleOptionAnonymousFareMediaChoice-link font-italic"
            :href="$t('how_to_write_card_number:link')"
            target="_blank"
        >
            {{ $t('how_to_write_card_number:description') }}
        </a>
    </div>
</template>

<script setup>
import { computed, onMounted, ref } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import { isEmpty } from 'global-utils'
import * as applicationSessionActionTypes from '@/StoreWeb/store/modules/application-session/action-types'
import * as applicationSessionMutationTypes from '@/StoreWeb/store/modules/application-session/mutation-types'
import * as externalActionTypes from '@/StoreWeb/store/modules/application-session/external-action-types'
import * as internalActionTypes from '@/StoreWeb/store/modules/application-session/internal-action-types'
import AddToCartFlowManager from '@/StoreWeb/managers/AddToCartFlowManagerSingleton'
import IcomoonIcon from '@/StoreWeb/components/common/IcomoonIcon'
import { useI18n } from 'vue-i18n'
import AlertMessage from '@/StoreWeb/components/common/AlertMessage'
import { checkAndGetFareMedia, isValidFareMediaId } from '@/StoreWeb/js/mixins/faremedia-utils'

const store = useStore()
const router = useRouter()
const { t } = useI18n()

const emit = defineEmits(['anonymousFareMediaUpdated'])

const props = defineProps({
    provider: {
        type: String,
        default: ''
    }
})
const addToCartFlowManager = ref(AddToCartFlowManager.getInstance())
const selectedFareMedia = ref('')
const wrongFareMediaErrorMessage = ref(null)

const isMobileApp = computed(() => store.state.isMobileApp)
const applicationSession = computed(() => store.state.applicationSessionModule.applicationSession)
const getShowGetFareMediaIdThroughNfcButton = computed(() => {
    return isMobileApp.value &&
        !isEmpty(applicationSession.value) &&
        !isEmpty(applicationSession.value.data) &&
        applicationSession.value.data.isNfcSupported
})
const isSubmitButtonDisabled = computed(() => {
    return !selectedFareMedia.value || !isValidFareMediaId(selectedFareMedia.value, props.provider)
})

const fareMediaUpdated = (fareMedia) => {
    selectedFareMedia.value = fareMedia

    if (fareMedia && !isValidFareMediaId(fareMedia, props.provider)) {
        wrongFareMediaErrorMessage.value = t('sale_option:card_number:error:wrong_number')
    } else {
        wrongFareMediaErrorMessage.value = null
    }
}

const getFareMediaIdThroughNFC = async () => {
    addToCartFlowManager.value.setAddToCartFlowStateToLocalStorage()
    if (isMobileApp.value) {
        const baseUrl = `${window.location.origin}/internalLinks/${internalActionTypes.GO_TO_CATALOG}?sessionId=${applicationSession.value.id}&initialRequest=${externalActionTypes.GET_FARE_MEDIA_ID_THROUGH_NFC}`

        applicationSession.value.data.addToCartFlow = localStorage.getItem('addToCartFlow')
        applicationSession.value.sessionContext = {
            currentStep: baseUrl,
            resumeStepSuccess: `${baseUrl}&status=success`,
            resumeStepError: `${baseUrl}&status=error`
        }
        store.commit(applicationSessionMutationTypes.SET_APPLICATION_SESSION, applicationSession.value)
        await store.dispatch(applicationSessionActionTypes.UPDATE_APPLICATION_SESSION)
        const externalLink = router.resolve({
            name: 'externalLinks',
            params: { action: externalActionTypes.GET_FARE_MEDIA_ID_THROUGH_NFC },
            query: { sessionId: applicationSession.value.id }
        })
        if (!isEmpty(externalLink)) {
            window.location.href = window.location.origin + externalLink.href
        }
    }
}

const updateFareMedia = async () => {
    const response = await checkAndGetFareMedia({
        providerId: props.provider,
        fareMediaId: selectedFareMedia.value
    }, true)

    if (response.isValid && response?.fareMedia?.id) {
        wrongFareMediaErrorMessage.value = null
        emit('anonymousFareMediaUpdated', {
            basketInfo: 'PROVIDERFAREMEDIA',
            value: {
                fareMediaId: selectedFareMedia.value,
                providerUserExternalId: response.fareMedia.providerUserExternalId
            }
        })
    } else {
        wrongFareMediaErrorMessage.value = response?.error?.message ?? t('sale_option:card_number:error:wrong_number')
    }
}

onMounted(() => {
    if (
        isMobileApp.value &&
        applicationSession.value &&
        !isEmpty(applicationSession.value.data) &&
        applicationSession.value.data.fareMediaId
    ) {
        selectedFareMedia.value = applicationSession.value.data.fareMediaId
        emit('anonymousFareMediaUpdated', {
            basketInfo: 'PROVIDERFAREMEDIA',
            goToNext: false,
            value: selectedFareMedia.value
        })
    }
})
</script>

<style lang="scss" scoped>
@import 'globalScss';

.SaleOptionAnonymousFareMediaChoice {
    &-link {
        font-size: 14px;
        color: black;
    }

    &-buttonsArea {
        display: inline-flex;
        justify-content: space-between;
        align-items: center;
        gap: 15px;
        padding: 0 0 32px;
    }

    &-button,
    &-nfcButton {
        &.v-btn.v-size--large {
            width: 44px;
            min-width: 44px;
        }
    }

    &-button {
        &Icon {
            font-size: 24px;
        }
    }
}
</style>
