export const ACTIVATE_ACCOUNT = 'activateAccount'
export const DEACTIVATE_ACCOUNT = 'deactivateAccount'
export const USER_DATA_CLAIM = 'userDataClaim'
export const DELETE_ACCOUNT = 'deleteAccount'
export const GET_CERTIFICATE = 'getCertificate'
export const DOWNLOAD_FARE_MEDIA_CERTIFICATE = 'downloadFareMediaCertificate'
export const GET_DIRECT_DEBITS = 'getDirectDebits'
export const GET_INVOICE = 'getInvoice'
export const GET_NOTIFICATIONS = 'getNotifications'
export const GET_ORDER = 'getOrder'
export const GET_ORDERS = 'getOrders'
export const GET_PERSONAL_INFO = 'getPersonalInfo'
export const GET_WALLET = 'getWallet'
export const LINK_FARE_MEDIA = 'linkFareMedia'
export const SET_EMAIL = 'setEmail'
export const SET_NOTIFICATION_AS_READ = 'setNotificationAsRead'
export const SET_PERSONAL_INFO = 'setPersonalInfo'
export const SET_PHONE = 'setPhone'
export const TERMINATE_PLAN = 'terminatePlan'
export const UPDATE_PERSONAL_INFO = 'updatePersonalInfo'
export const UNLINK_FARE_MEDIA = 'unlinkFareMedia'
export const SET_NEW_ACCOUNT = 'setNewAccount'
export const SET_NEW_THIRD_ACCOUNT = 'setNewThirdAccount'
export const GET_NEW_PROFILE_ACCOUNT = 'getNewProfileAccountAction'
export const SET_NEW_PROFILE_ACCOUNT = 'setNewProfileAccountAction'
export const GET_CONSENTS = 'getConsents'
export const CREATE_PROVIDER_ACCOUNT = 'createProviderAccountAction'
export const EXECUTE_ACTION_ON_WALLET_CONTRACT = 'executeActionOnWalletContract'
export const RESET_CERTIFICATE_MODAL_STATE = 'resetCertificateModalState'
