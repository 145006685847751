import Cookies from 'js-cookie'
import { isEmpty } from 'global-utils'
import store from '@/StoreWeb/store/store'
import LoginManager from 'user/Login/managers/LoginManager'
import * as loginActionTypes from 'user/Login/store/action-types'
import User from '@/StoreWeb/models/user/User'
import cachios from 'cachios'
import config from 'config'
import { computed } from 'vue'
import * as userMutationTypes from '@/StoreWeb/store/modules/user/mutation-types'

export function getIsAuthenticated () {
    if (config.use_web_harmony) {
        const userToken = Cookies.get('userToken')

        return !isEmpty(userToken)
    } else {
        return store.state.login.authenticated
    }
}

export function getUserTokenAccessToken () {
    if (config.use_web_harmony) {
        const userToken = Cookies.get('userToken')

        if (!isEmpty(userToken)) {
            return JSON.parse(userToken).accessToken
        }

        return null
    } else {
        return LoginManager.userToken.accessToken
    }
}

export function normalizeUserInfos (userInfos) {
    const user = new User(userInfos.attributes.email)

    user.firstName = userInfos.attributes.firstName ? userInfos.attributes.firstName.ucFirst() : ''
    user.lastName = userInfos.attributes.lastName ? userInfos.attributes.lastName.ucFirst() : ''
    user.externalUserId = typeof userInfos.externalUserId !== 'undefined' ? userInfos.externalUserId : ''
    user.technicalId = typeof userInfos.technicalId !== 'undefined' ? userInfos.technicalId : ''

    return user
}

export function getUserInfos () {
    if (config.use_web_harmony) {
        const userInfos = Cookies.get('userInfos')

        if (!isEmpty(userInfos)) {
            return JSON.parse(userInfos)
        }

        return null
    } else {
        return LoginManager.userInfos
    }
}

export function getRawUserInfos () {
    if (config.use_web_harmony) {
        const userInfos = Cookies.get('userInfos')

        if (!isEmpty(userInfos)) {
            return JSON.parse(userInfos)
        }

        return null
    } else {
        return LoginManager.rawUserInfos
    }
}

export function getKasConfigurationSettings () {
    if (config.use_web_harmony) {
        const kasConfigurationSettings = localStorage.getItem('kasConfigurationSettings')

        if (!isEmpty(kasConfigurationSettings)) {
            return JSON.parse(kasConfigurationSettings)
        }

        return null
    } else {
        return store.state.login.kasConfigurationSettings
    }
}

export function logIn (url) {
    if (typeof url === 'object') {
        url = url?.fullPath ? `${window.location.origin}${url.fullPath}` : window.location.href
    }

    if (config.use_web_harmony) {
        document.body.dispatchEvent(new CustomEvent('kas-login', {
            detail: {
                redirectTo: url ?? window.location.href
            }
        }))
    } else {
        LoginManager.startLogInProcess(url ?? window.location.href)
    }
}

export function logOut () {
    if (config.use_web_harmony) {
        delete cachios.axiosInstance.defaults.headers.common.Authorization
        document.body.dispatchEvent(new CustomEvent('kas-logout'))
    } else {
        store.dispatch(`login/${loginActionTypes.LOGOUT}`)
    }
}

// Alias de la fonction logIn
export const startLogInProcess = logIn

export function updateAuthenticatedStatusAfterLogin () {
    store.commit(userMutationTypes.SET_IS_AUTHENTICATED, getIsAuthenticated())
}

export function updateUserInfosAfterLogin () {
    const rawUserInfos = getRawUserInfos()
    const userInfos = normalizeUserInfos(rawUserInfos)

    store.commit(userMutationTypes.SET_RAW_USER_INFOS, rawUserInfos)
    store.commit(userMutationTypes.SET_USER_INFOS, userInfos)
}

export function useLogin () {
    const isAuthenticated = computed(() => {
        if (config.use_web_harmony) {
            return store.state.userModule.isAuthenticated
        }

        return store.state.login.authenticated
    })
    const kasConfigurationSettings = computed(() => {
        if (config.use_web_harmony) {
            return store.state.userModule.kasConfigurationSettings
        }

        return store.state.login.kasConfigurationSettings
    })
    const rawUserInfos = computed(() => {
        if (config.use_web_harmony) {
            return store.state.userModule.rawUserInfos
        }

        return store.state.login.rawUserInfos
    })
    const userInfos = computed(() => {
        if (config.use_web_harmony) {
            return store.state.userModule.userInfos
        }

        return store.state.login.userInfos
    })

    return {
        isAuthenticated,
        kasConfigurationSettings,
        rawUserInfos,
        userInfos
    }
}
