<template>
    <div class="CartConfirmation">
        <alert-message
            v-if="error"
            :message="error"
            class="UserAccountSubscriptionModal-errorMessage"
            type="error"
        />
        <div class="Container CartConfirmation-container">
            <v-btn
                color="primary"
                variant="tonal"
                @click.prevent="returnButtonClicked"
            >
                {{ $t('cart:return') }}
            </v-btn>
            <h1 class="CartConfirmation-title">
                {{ $t('cart:confirmation:title') }}
            </h1>
            <cart-content
                class="CartConfirmation-cartContent"
                :show-both-prices-if-possible="true"
            />
        </div>
        <payment-mode-form class="CartConfirmation-paymentModeList" />
        <div
            v-if="codeType === 'HTML'"
            class="CartConfirmation-paymentFormWrapper"
            v-html="purifiedCode"
        />
    </div>
</template>

<script setup>
import { useStore } from 'vuex'
import CartContent from '@/StoreWeb/components/cart/CartContent'
import PaymentModeForm from '@/StoreWeb/components/payment/PaymentModeForm'
import AlertMessage from '@/StoreWeb/components/common/AlertMessage.vue'
import { computed, onUpdated, ref, watch } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRouter } from 'vue-router'
import * as mainMutationTypes from '@/StoreWeb/store/modules/main/mutation-types'
import * as cartActionTypes from '@/StoreWeb/store/modules/cart/action-types'
import { showImpossibleToReopenCartAlert } from '@/StoreWeb/js/composables/cart-utils'
import DOMPurify from 'dompurify'

const store = useStore()
const router = useRouter()
const { t } = useI18n()

const error = ref('')
const getCart = computed(() => store.getters.getCart)
const isCartStatusAllowsReopening = computed(() => ['PROVIDERS_TOTAL_CONFIRMED', 'PAYMENT_PROCESSING', 'PAYMENT_DECLINED'].includes(getCart.value?.status))
const isWalletLoading = computed(() => store.state.userModule.userPendingRequests.getWallet)
const payment = computed(() => store.state.paymentModule.payment)
const codeType = computed(() => {
    if (payment.value !== null) {
        return payment.value.code
    }
    return ''
})
const code = computed(() => {
    if (codeType.value === 'HTML') {
        return payment.value.code
    }
    return ''
})
const purifiedCode = computed(() => {
    return DOMPurify.sanitize(code.value, { ADD_TAGS: ['form', 'input', 'button'], ADD_ATTR: ['action', 'method', 'type', 'name', 'value'] })
})

watch(() => isWalletLoading.value, (value) => {
    manageWalletLoader(value)
})

onUpdated(() => {
    if (codeType.value === 'HTML') {
        const form = document.querySelector('.CartConfirmation-paymentFormWrapper form')
        if (form !== null) {
            form.submit()
        }
    }
})

function returnButtonClicked () {
    // When we want to return to the cart (exit the cart confirmation view), we have 2 different cases :
    // - the cart is not "blocked": we just redirect to the cart (cart status allows reopening)
    // - the cart is "blocked" (cart status does not allow simple reopening): we try to force reopen the cart via the backend, and if it's not possible, we show an alert
    if (!isCartStatusAllowsReopening.value) {
        router.push({ name: 'cart' })
        return
    }

    store.dispatch(cartActionTypes.REOPEN_CART, {
        id: getCart.value.id,
        wsOptions: {
            cacheAsync: false,
            cacheValidity: 0
        },
        cachiosOptions: {
            force: true
        }
    })
        .then(() => {
            router.push({ name: 'cart' })
        })
        .catch(error => {
            showImpossibleToReopenCartAlert({
                error,
                cartId: getCart.value.id
            })
        })
}

function manageWalletLoader (value) {
    value
        ? store.commit(mainMutationTypes.SET_SHOW_GLOBAL_LOADER, { keepShow: true, title: t('default_loading:title'), subtitle: t('default_loading:subtitle'), value: true })
        : store.commit(mainMutationTypes.SET_SHOW_GLOBAL_LOADER, { keepShow: false, resetTexts: true, value: false })
}
</script>

<style lang='scss' scoped>
@import 'globalScss';

.CartConfirmation {
    background: $color-lightest;

    &-container {
        padding-bottom: 25px;
    }

    &-title {
        @extend %h1;

        margin-bottom: 30px;
    }

    &-paymentFormWrapper {
        display: none;
    }
}
</style>
