<template>
    <div class="ma-0">
        <HomePageComponent
            class="HomePage-component"
            @banner-button-clicked="manageRedirection"
            @cards-button-clicked="manageRedirection"
        />
        <support-reader-modal
            v-model="showSupportReaderModal"
            @operation-finished="onOperationFinished"
        />
        <modal-connection
            v-model="showConnectionModal"
            :isMobile="$vuetify.display.smAndDown"
            :type="currentCardType"
            :withCardReader="isReload"
            @connection="goToConnection"
            @open-card-reader="openCardReaderModal"
        />
    </div>
</template>

<script setup>
import { useStore } from 'vuex'
import SupportReaderModal from '@/StoreWeb/components/miscellaneous/conduent-support-reader/SupportReaderModal.vue'
import { computed, nextTick, ref, watch } from 'vue'
import { useRouter } from 'vue-router'
import { isEmpty } from 'global-utils'
import addCartFlowMixins from '@/StoreWeb/js/mixins/add-cart-flow-utils'
import { setSelectedFareMediaCookie } from '@/StoreWeb/js/mixins/wallet-utils'
import * as anonymousActionTypes from '@/StoreWeb/store/modules/anonymous/action-types'
import * as anonymousMutationTypes from '@/StoreWeb/store/modules/anonymous/mutation-types'
import { useLogin } from '@/StoreWeb/js/composables/login-utils'

const store = useStore()
const router = useRouter()
const { isAuthenticated } = useLogin()
const { triggerLogin } = addCartFlowMixins()

const showSupportReaderModal = ref(false)
const showConnectionModal = ref(false)
const currentCardType = ref('')

const supportContent = computed(() => store.state.anonymousModule.supportContent)

const isReload = computed(() => currentCardType.value === openCardReaderEnum.RELOAD)

watch(showSupportReaderModal, (newValue) => {
    if (!newValue) {
        currentCardType.value = ''
    }
})

const openCardReaderEnum = {
    SUBSCRIPTION: 'subscription',
    RELOAD: 'reload',
    BUY_TICKET: 'buyTicket'
}

function manageRedirection (event) {
    currentCardType.value = event

    if ([openCardReaderEnum.SUBSCRIPTION, openCardReaderEnum.RELOAD].includes(event)) {
        if (isAuthenticated.value) {
            const redirectTo = isReload.value
                ? { name: 'tickets' }
                : {
                    name: 'catalog',
                    query: {
                        catalogFilters: 'TRAVEL_FREQUENCY=EVERY_DAYS',
                        resetFilters: 'true'
                    }
                }

            router.push(redirectTo)
        } else {
            showConnectionModal.value = true
        }
    } else if (event === openCardReaderEnum.BUY_TICKET) {
        router.push({
            name: 'catalog',
            query: {
                catalogFilters: 'TRAVEL_FREQUENCY=SOME_JOURNEYS',
                resetFilters: 'true'
            }
        })
    }
}

function goToConnection () {
    triggerLogin({
        callback: showConnectionModal.value = false,
        route: { name: 'catalog' }
    })
}

async function onOperationFinished (data) {
    if (isReload.value && !isEmpty(data.support)) {
        const params = {
            providerId: 'conduent',
            fareMediaId: data.support.fareMediaId,
            isTemporary: false
        }

        setSelectedFareMediaCookie(params, true)
        if (isEmpty(supportContent.value)) {
            const content = await store.dispatch(anonymousActionTypes.GET_SUPPORT_CONTENT, params)
            if (content?.id) {
                store.commit(anonymousMutationTypes.SET_SUPPORT_CONTENT, content)
            } else {
                await router.push({ name: 'walletLight', params })
            }
        }

        if (!isEmpty(supportContent.value)) {
            await router.push({ name: 'walletLight', params })
        }
    }
}

function openCardReaderModal () {
    showConnectionModal.value = false

    nextTick(() => {
        showSupportReaderModal.value = true
    })
}
</script>

<style lang='scss' scoped>
@import 'globalScss';

.HomePage-component {
    padding-bottom: rem-calc(30);

    @media #{map-get($display-breakpoints, 'md-and-up')} {
        padding-bottom: rem-calc(30);
    }

    @media #{map-get($display-breakpoints, 'md-and-up')} {
        padding-left: rem-calc(10);
    }
}
</style>
