export default class {
    constructor (
        id
    ) {
        this._cropEnabled = false
        this._description = ''
        this._externalId = ''
        this._id = id
        this._maxSize = 5100
        this._mimeTypes = ['image/jpeg', 'image/png', 'application/pdf']
        this._name = ''
        this._provider = null
        this._supportedTypes = ['JPG', 'PNG', 'PDF', 'JPEG']
    }

    get cropEnabled () {
        return this._cropEnabled
    }

    set cropEnabled (cropEnabled) {
        this._cropEnabled = cropEnabled
    }

    get description () {
        return this._description
    }

    set description (description) {
        this._description = description
    }

    get externalId () {
        return this._externalId
    }

    set externalId (externalId) {
        this._externalId = externalId
    }

    get id () {
        return this._id
    }

    set id (id) {
        this._id = id
    }

    get maxSize () {
        return this._maxSize
    }

    set maxSize (maxSize) {
        this._maxSize = maxSize
    }

    get mimeTypes () {
        return this._mimeTypes
    }

    set mimeTypes (mimeTypes) {
        this._mimeTypes = mimeTypes
    }

    get name () {
        return this._name
    }

    set name (name) {
        this._name = name
    }

    get provider () {
        return this._provider
    }

    set provider (provider) {
        this._provider = provider
    }

    get supportedTypes () {
        return this._supportedTypes
    }

    set supportedTypes (supportedTypes) {
        this._supportedTypes = supportedTypes
    }

    isCropAvailable () {
        const count = this.supportedTypes.length
        let i
        for (i = 0; i < count; i++) {
            if (['BMP', 'GIF', 'JPG', 'JPEG', 'PNG', 'TIF', 'TIFF', 'WEBP'].find(type => type === this.supportedTypes[i])) {
                return true
            }
        }
        return false
    }

    toJSON () {
        return {
            cropEnabled: this.cropEnabled,
            description: this.description,
            externalId: this.externalId,
            id: this.id,
            maxSize: this.maxSize,
            mimeTypes: this.mimeTypes,
            name: this.name,
            provider: this.provider,
            supportedTypes: this.supportedTypes
        }
    }
}
