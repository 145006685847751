import { isEmpty } from 'global-utils'
import * as cartMutationTypes from '@/StoreWeb/store/modules/cart/mutation-types'
import * as applicationSessionActionTypes from '@/StoreWeb/store/modules/application-session/action-types'
import * as applicationSessionMutationTypes from '@/StoreWeb/store/modules/application-session/mutation-types'
import emitter from 'global-emitter'
import AddToCartFlowSerializer from '@/StoreWeb/serializers/AddToCartFlowSerializerSingleton'
import cloneDeep from 'lodash.clonedeep'
import { checkHasToCreateMspAccount, triggerAddAccountEvent } from '@/StoreWeb/js/composables/msp-account-utils'
import * as catalogMutationTypes from '@/StoreWeb/store/modules/catalog/mutation-types'

export default class {
    constructor () {
        this._addToCartFlowConfig = null
        this._addToCartFlowContext = null
        this._addToCartFlowSteps = null
        this._addToCartFlowSerializer = AddToCartFlowSerializer.getInstance()
        this._currentStep = null
        this._currentStepIndex = null
        this._currentStepProduct = null
        this._product = null
        this._store = null
    }

    get addToCartFlowConfig () {
        return this._addToCartFlowConfig
    }

    set addToCartFlowConfig (addToCartFlowConfig) {
        this._addToCartFlowConfig = addToCartFlowConfig
    }

    get addToCartFlowSerializer () {
        return this._addToCartFlowSerializer
    }

    get addToCartFlowSteps () {
        return this._addToCartFlowSteps
    }

    set addToCartFlowSteps (addToCartFlowSteps) {
        this._addToCartFlowSteps = addToCartFlowSteps
        this.store.commit(cartMutationTypes.SET_ADD_TO_CART_FLOW, addToCartFlowSteps)
    }

    get addToCartFlowContext () {
        return this._addToCartFlowContext
    }

    set addToCartFlowContext (addToCartFlowContext) {
        this._addToCartFlowContext = addToCartFlowContext
        this.store.commit(cartMutationTypes.SET_ADD_TO_CART_FLOW_CONTEXT, addToCartFlowContext)
    }

    get currentStep () {
        return this._currentStep
    }

    set currentStep (currentStep) {
        this._currentStep = currentStep
        this.store.commit(cartMutationTypes.SET_ADD_TO_CART_FLOW_STEP, currentStep)
    }

    get currentStepIndex () {
        return this._currentStepIndex
    }

    set currentStepIndex (currentStepIndex) {
        this._currentStepIndex = currentStepIndex
        this.store.commit(cartMutationTypes.SET_ADD_TO_CART_FLOW_INDEX, currentStepIndex)
    }

    get currentStepProduct () {
        return this._currentStepProduct
    }

    set currentStepProduct (currentStepProduct) {
        this._currentStepProduct = currentStepProduct
    }

    get product () {
        return this._product
    }

    set product (product) {
        this._product = product
    }

    get store () {
        return this._store
    }

    set store (store) {
        this._store = store
    }

    getPendingAddToCartFlow () {
        let addCartFlow

        if (!isEmpty(this.store.state.cartModule.tempAddCartFlow)) {
            addCartFlow = this.store.state.cartModule.tempAddCartFlow
            this.store.commit(cartMutationTypes.SET_TEMP_ADD_CART_FLOW, null)
        } else if (
            !isEmpty(this.store.state.applicationSessionModule.applicationSession) &&
            !isEmpty(this.store.state.applicationSessionModule.applicationSession.data) &&
            !isEmpty(this.store.state.applicationSessionModule.applicationSession.data.addToCartFlow)
        ) {
            addCartFlow = JSON.parse(this.store.state.applicationSessionModule.applicationSession.data.addToCartFlow)
        } else {
            addCartFlow = localStorage.getItem('addToCartFlow')
            if (addCartFlow) {
                addCartFlow = JSON.parse(addCartFlow)
            }
        }

        return addCartFlow
    }

    checkPendingAddToCartFlow ({ eventName, context }) {
        const addCartFlow = this.getPendingAddToCartFlow()

        if (
            isEmpty(addCartFlow) ||
            (
                this.store.state.currentRoute.name === 'product' &&
                this.store.state.catalogModule.productDetails.id !== addCartFlow.productId
            )
        ) {
            return
        }

        const product = this.getAddToCartFlowProduct(addCartFlow)

        if (product) {
            const { hasToCreateMspAccount, provider } = checkHasToCreateMspAccount(product)

            if (hasToCreateMspAccount) {
                this.store.commit(catalogMutationTypes.SET_PRODUCT_TO_ADD_ONCE_ACCOUNT_FINALIZED, product)
                triggerAddAccountEvent(provider)
            } else {
                if (isEmpty(eventName)) {
                    eventName = 'continueAddToCartProcess'
                }
                this.restorePendingAddToCartFlow({
                    eventName,
                    addCartFlow,
                    context,
                    product
                })
            }
        }
    }

    getAddToCartFlowProduct (addCartFlow) {
        if (!isEmpty(this.store.state.catalogModule.productDetails)) {
            return this.store.state.catalogModule.productDetails
        }

        return this.store.state.catalogModule.products.find(item => (item.id === addCartFlow.productId && item.version === addCartFlow.productVersion))
    }

    restorePendingAddToCartFlow ({ eventName, addCartFlow, context, product }) {
        // this.currentStepIndex = addCartFlow.index
        // this.addToCartFlowSteps = addCartFlow.flow
        // this.store.commit(cartMutationTypes.SET_ADD_TO_CART_FLOW_INDEX, addCartFlow.index)
        // this.currentStep = this.getCurrentStep()
        // console.log('currentStep', this.currentStep)
        // this.store.commit(cartMutationTypes.SET_ADD_TO_CART_FLOW_STEP, this.currentStep)
        if (typeof product === 'undefined') {
            product = this.getAddToCartFlowProduct(addCartFlow)
        }

        // console.log('FOUND PRODUCT', product)
        // console.log('restorePendingAddToCartFlow >>> product', product)
        if (product) {
            this.product = product
            this.initAddCartFlow(product)

            this.addToCartFlowSteps = addCartFlow.addToCartFlowSteps
            this.addToCartFlowSteps = this.addToCartFlowSerializer.deserialize(this.addToCartFlowSteps)
            // console.log('addToCartFlowSteps', this.addToCartFlowSteps)

            this.currentStepIndex = addCartFlow.currentStepIndex

            this.addToCartFlowContext = context
            this.store.commit(cartMutationTypes.SET_IS_ADD_TO_CART_FLOW_FIRST_STEP, this.isFirstStep(this.currentStepIndex))
            this.store.commit(cartMutationTypes.SET_IS_ADD_TO_CART_FLOW_LAST_STEP, this.isLastStep(this.currentStepIndex))
            this.currentStep = this.getCurrentStep()
            if (this.checkCurrentFlowRestoration()) {
                emitter.emit(eventName, this.product)
            } else {
                this.resetAddCartFlow()
            }
        }
    }

    checkCurrentFlowRestoration () {
        if (
            this.currentStep.type === 'saleOption' &&
            this.product.hasToLoginOnAddToCart() &&
            !this.store.state.userModule.isAuthenticated
        ) {
            return false
        }
        return true
    }

    goToPreviousStep ({ keepAuthenticationMode }) {
        // console.log('goToPreviousStep')
        const previousStepInfos = this.getPreviousStep({}, true)

        // In case we need to reset saleOptionSelector step
        if (keepAuthenticationMode && previousStepInfos.previousStep.type === 'authenticationMode' && this.currentStep.type === 'saleOption') {
            this.currentStep.selectedSaleOption = null
            this.currentStep.selectedSaleOptionParameters = null
        } else {
            this.currentStep = previousStepInfos.previousStep
            this.currentStepIndex = previousStepInfos.currentStepIndex
            this.store.commit(cartMutationTypes.SET_IS_ADD_TO_CART_FLOW_FIRST_STEP, previousStepInfos.isFirstStep)
            this.store.commit(cartMutationTypes.SET_IS_ADD_TO_CART_FLOW_LAST_STEP, previousStepInfos.isLastStep)
        }
    }

    getPreviousStep ({ level, currentFlow, currentStepIndex }, goTo = false) {
        // console.log('getPreviousStep')
        if (typeof level === 'undefined') {
            level = 0
        }
        if (typeof currentFlow === 'undefined') {
            currentFlow = this.addToCartFlowSteps
        }
        if (typeof currentStepIndex === 'undefined') {
            currentStepIndex = this.currentStepIndex
        }

        // debugger
        // console.log('getPreviousStep >>> currentStepIndex', currentStepIndex)
        // if (currentStepIndex === [1, 0, 0]) {
        //     debugger
        // }
        let previousStep = null
        if (level < currentStepIndex.length - 1) {
            // On peut incrémenter le niveau enfant
            return this.getPreviousStep({
                level: level + 1,
                currentFlow: currentFlow[currentStepIndex[level]].steps,
                currentStepIndex
            }, goTo)
        } else {
            // debugger
            if (currentStepIndex[level] > 0) {
                // debugger
                // let currentStep = currentFlow[currentStepIndex[level]]
                // console.log('Update currentStepIndex LVL ' + level + ' TO ' + (currentStepIndex[level] + 1))
                currentStepIndex[level]--
                // this.store.commit(cartMutationTypes.SET_ADD_TO_CART_FLOW_INDEX, currentStepIndex)
                previousStep = currentFlow[currentStepIndex[level]]

                // if the previous step is to select a support, but a support is already selected,
                // this screen is not displayed.
                if (previousStep?.type === 'saleOption' && !isEmpty(this.store.state.userModule.selectedFareMedia)) {
                    return this.getPreviousStep({
                        level,
                        currentFlow: previousStep.steps,
                        currentStepIndex
                    }, goTo)
                }

                // // ---> CI-DESSOUS ==> A réfléchir
                // if (previousStep.type === 'variation') {
                //     console.log("PreviousStep is variation, next steps depends on previous steps ==> Let's do the necessary")
                //     console.log('VARIATION >>> SET TO NULL product', previousStep.product)
                //     console.log('VARIATION >>> SET TO [] steps')
                //     previousStep.product = null
                //     previousStep.steps = []
                // }
                // // ---> CI-DESSOUS ==> A réfléchir
                // if (previousStep.type === 'dynamic') {
                //     console.log("PreviousStep is dynamic, it depends on currentStep ==> Let's do the necessary")
                //     let selectedVariation = currentStep.selectedVariation
                //     console.log(selectedVariation.getAddToCartFlow(this.getFlowBuildingParams()))
                //     console.log('getPreviousStep >>> currentFlow', currentFlow[currentStepIndex[level]])
                //     console.log('getPreviousStep >>> selectedVariation', selectedVariation)
                //     previousStep.product = selectedVariation
                //     previousStep.steps = selectedVariation.getAddToCartFlow(this.getFlowBuildingParams())
                //     if (previousStep.steps.length > 0) {
                //         previousStep = previousStep.steps[0]
                //         // console.log('Update currentStepIndex LVL ' + (level + 1) + ' TO 0')
                //         currentStepIndex[level + 1] = 0
                //         // console.log('getPreviousStep >>> addToCartFlowSteps', this.addToCartFlowSteps)
                //         // console.log('getPreviousStep >>> currentStepIndex', currentStepIndex)
                //     }
                // }
            } else {
                // Soit c'était la première étape, soit il faut remonter au parent
                // console.log("Soit c'était la première étape, soit il faut remonter au parent")
                if (currentStepIndex[level] === 0) {
                    // console.log("C'était la première étape ==> On remonte au parent")
                    if (level < 2) {
                        // console.log("A priori, c'était la première étape du process !!!")
                    } else {
                        // previousStep = this.getParentPreviousStep(level)
                        previousStep = this.getParentPreviousStep(currentStepIndex, goTo)
                        if (previousStep !== null && !isEmpty(previousStep.steps)) {
                            const lastFinalChild = this.getLastFinalChild({
                                currentStepIndex,
                                flow: previousStep
                            })
                            currentStepIndex = lastFinalChild.currentStepIndex
                            previousStep = lastFinalChild.flow
                        }
                    }
                } else {
                    // console.log("Ce n'était la première étape ==> On peut décrémenter")
                    // console.log('Update currentStepIndex LVL ' + level + ' TO ' + (currentStepIndex[level] - 1))
                    currentStepIndex[level]--
                    previousStep = currentFlow[currentStepIndex[level]]
                }
                // if (previousStep.type === 'variation' || previousStep.type === 'dynamic') {
                //     debugger
                // }
                // ---> CI-DESSOUS ==> A réfléchir
                if (previousStep?.type === 'variation') {
                    // console.log("PreviousStep is variation, next steps depends on previous steps ==> Let's do the necessary")
                    // console.log('VARIATION >>> SET TO NULL product', previousStep.product)
                    // console.log('VARIATION >>> SET TO [] steps')
                    // previousStep.selectedVariation = null
                }
            }
        }
        // console.log('getPreviousStep >>> previousStep', previousStep)
        return {
            level,
            currentFlow,
            currentStepIndex,
            previousStep,
            isFirstStep: this.isFirstStep(currentStepIndex),
            isLastStep: currentStepIndex.equals(this.getLastStepIndex())
        }
    }

    getParentPreviousStep (stepIndex, goTo) {
        if (typeof stepIndex === 'undefined') {
            stepIndex = this.currentStepIndex
        }
        // console.log('getParentPreviousStep')
        // console.log('goToParentPreviousStep')
        // console.log('REMOVE currentStepIndex LVL ' + (stepIndex.length - 1))
        // stepIndex.pop()
        const parent = this.getParentStep({ childIndex: stepIndex }, goTo)
        if (parent !== null) {
            if (stepIndex[parent.stepsLevel] > 0) {
                // if (parent.stepsLevel < stepIndex.length - 1) {
                //     stepIndex.pop()
                // }
                // stepIndex.pop()
                // console.log('Update currentStepIndex LVL ' + parent.stepsLevel + ' TO ' + (stepIndex[parent.stepsLevel] - 1))
                stepIndex[parent.stepsLevel]--
                // console.log('getParentPreviousStep >>> on passe au parent précédent', parent.steps[stepIndex[parent.stepsLevel]])
                // if (parent.type === 'dynamic') {
                //     debugger
                // }
                // stepIndex.pop()
                return parent.steps[stepIndex[parent.stepsLevel]]
            } else {
                // C'était la première étape de ce parent également ==> On remonte encore d'un cran
                // stepIndex.pop()
                // console.log("getParentPreviousStep >>> On remonte encore d'un cran")
                // console.log('REMOVE currentStepIndex LVL ' + (stepIndex.length - 1))
                if (parent.type === 'dynamic') {
                    parent.product = null
                    parent.steps = []
                }
                stepIndex.pop()
                return this.getParentPreviousStep(stepIndex, goTo)
            }
        }
        // console.log("C'était le plus bas niveau et on a pas d'étape suivante... On retourne null")
        return parent
    }

    goToNextStep () {
        // console.log('goToNextStep', this.currentStepIndex, this.addToCartFlowSteps)
        // console.log('goToNextStep', this.getNextStep())
        const nextStepInfos = this.getNextStep({}, true)

        if (nextStepInfos.nextStep === null) {
            // console.log('On a terminé les étapes du processus, on peut ajouter au panier')
            this.currentStep = {
                type: 'end'
            }
            // this.store.commit(mainMutationTypes.SET_SHOW_MODAL, false)
            // this.store.dispatch(cartActionTypes.ADD_TO_CART, {
            //     data: this._addToCartFlowSteps
            // })
        } else {
            this.currentStep = nextStepInfos.nextStep
            this.currentStepIndex = nextStepInfos.currentStepIndex
            this.store.commit(cartMutationTypes.SET_IS_ADD_TO_CART_FLOW_FIRST_STEP, nextStepInfos.isFirstStep)
            this.store.commit(cartMutationTypes.SET_IS_ADD_TO_CART_FLOW_LAST_STEP, nextStepInfos.isLastStep)
        }
    }

    getNextStep ({ level, currentFlow, currentStepIndex }, goTo = false) {
        if (typeof level === 'undefined') {
            level = 0
        }
        if (typeof currentFlow === 'undefined') {
            currentFlow = this.addToCartFlowSteps
        }
        if (typeof currentStepIndex === 'undefined') {
            currentStepIndex = this.currentStepIndex
        }
        // console.log('getNextStep', level, currentFlow)
        // console.log('getNextStep >>> currentStepIndex', currentStepIndex)
        // if (currentStepIndex === [1, 0, 0]) {
        //     debugger
        // }
        let nextStep = null
        if (level < currentStepIndex.length - 1) {
            // On peut incrémenter le niveau enfant
            return this.getNextStep({
                level: level + 1,
                currentFlow: currentFlow[currentStepIndex[level]].steps,
                currentStepIndex
            }, goTo)
        } else {
            if (typeof currentFlow[currentStepIndex[level] + 1] !== 'undefined') {
                const currentStep = currentFlow[currentStepIndex[level]]
                // console.log('Update currentStepIndex LVL ' + level + ' TO ' + (currentStepIndex[level] + 1))
                currentStepIndex[level]++
                // this.store.commit(cartMutationTypes.SET_ADD_TO_CART_FLOW_INDEX, currentStepIndex)
                nextStep = currentFlow[currentStepIndex[level]]
                if (nextStep.type === 'dynamic') {
                    // console.log("NextStep is dynamic, it depends on currentStep ==> Let's do the necessary")
                    const selectedVariation = currentStep.selectedVariation
                    // console.log(selectedVariation.getAddToCartFlow(this.getFlowBuildingParams()))
                    // console.log('getNextStep >>> currentFlow', currentFlow[currentStepIndex[level]])
                    // console.log('getNextStep >>> selectedVariation', selectedVariation)
                    nextStep.product = selectedVariation
                    nextStep.steps = selectedVariation.getAddToCartFlow(this.getFlowBuildingParams())
                    if (nextStep.steps.length > 0) {
                        nextStep = nextStep.steps[0]
                        // console.log('Update currentStepIndex LVL ' + (level + 1) + ' TO 0')
                        currentStepIndex[level + 1] = 0
                        // console.log('getNextStep >>> addToCartFlowSteps', this.addToCartFlowSteps)
                        // console.log('getNextStep >>> currentStepIndex', currentStepIndex)
                    }
                } else if (nextStep.type === 'subProductFlow') {
                    const firstFinalChild = this.getFirstFinalChild({
                        flow: nextStep,
                        currentStepIndex
                    })
                    nextStep = firstFinalChild.flow
                    currentStepIndex = firstFinalChild.currentStepIndex
                    // console.log("NextStep is subProductFlow")
                    // console.log(selectedVariation.getAddToCartFlow(this.getFlowBuildingParams()))
                    // console.log('getNextStep >>> currentFlow', currentFlow[currentStepIndex[level]])
                    // console.log('getNextStep >>> selectedVariation', selectedVariation)
                }
            } else {
                // Soit c'était la dernière étape, soit il faut remonter au parent
                // console.log("Soit c'était la dernière étape, soit il faut remonter au parent")
                if (this.isLastLevelStep({
                    level,
                    currentFlow,
                    stepIndex: currentStepIndex
                })) {
                    // console.log("C'était la dernière étape ==> On remonte au parent")
                    if (level >= 2) {
                        // console.log("A priori, ce n'était la dernière étape du process !!!")
                        // nextStep = this.getParentNextStep(level)
                        nextStep = this.getParentNextStep(currentStepIndex, goTo)
                        if (nextStep !== null && !isEmpty(nextStep.steps)) {
                            const firstFinalChild = this.getFirstFinalChild({
                                flow: nextStep,
                                currentStepIndex
                            })
                            nextStep = firstFinalChild.flow
                            currentStepIndex = firstFinalChild.currentStepIndex
                        }
                    }
                } else {
                    // console.log("Ce n'était la dernière étape ==> On peut incrémenter")
                    // console.log('Update currentStepIndex LVL ' + level + ' TO ' + (currentStepIndex[level] + 1))
                    currentStepIndex[level]++
                    nextStep = currentFlow[currentStepIndex[level]]
                }
            }
        }
        // console.log('getNextStep >>> nextStep', nextStep)
        return {
            level,
            currentFlow,
            currentStepIndex,
            nextStep,
            isFirstStep: this.isFirstStep(currentStepIndex),
            isLastStep: currentStepIndex.equals(this.getLastStepIndex())
        }
    }

    /**
     * Return the first top level child (
     * @param flow
     * @param currentStepIndex
     * @returns {{currentStepIndex: (number[]|*), flow}|*}
     */
    getFirstFinalChild ({ flow, currentStepIndex }) {
        // console.log('getFirstFinalChild', flow, currentStepIndex)
        if (typeof currentStepIndex === 'undefined') {
            currentStepIndex = this.currentStepIndex
        }
        if (!isEmpty(flow.steps)) {
            // console.log('Update currentStepIndex LVL ' + this.currentStepIndex.length + ' TO 0')
            currentStepIndex.push(0)
            // console.log('getFirstFinalChild >>> getFirstFinalChild OF ', flow.steps[0])
            return this.getFirstFinalChild({
                flow: flow.steps[0],
                currentStepIndex
            })
        }
        // console.log('getFirstFinalChild >>> RETURN FLOW', flow)
        return {
            currentStepIndex,
            flow
        }
    }

    /**
     * Return the last top level child
     * @param flow
     * @param currentStepIndex
     * @returns {{currentStepIndex: (number[]|*), flow}|*}
     */
    getLastFinalChild ({ flow, currentStepIndex }) {
        // console.log('getLastFinalChild', flow, currentStepIndex)
        if (typeof currentStepIndex === 'undefined') {
            currentStepIndex = this.currentStepIndex
        }
        if (!isEmpty(flow.steps)) {
            // console.log('Update currentStepIndex LVL ' + this.currentStepIndex.length + ' TO 0')
            const lastStepIndex = flow.steps.length - 1
            currentStepIndex.push(lastStepIndex)
            // console.log('getFirstFinalChild >>> getFirstFinalChild OF ', flow.steps[0])
            // return this.getFirstFinalChild({
            return this.getLastFinalChild({
                currentStepIndex,
                flow: flow.steps[lastStepIndex]
            })
        }
        // console.log('getFirstFinalChild >>> RETURN FLOW', flow)
        return {
            currentStepIndex,
            flow
        }
    }

    getParentStep ({ childIndex, flow }, goTo = true) {
        if (typeof flow === 'undefined' && !goTo) {
            flow = cloneDeep(this.addToCartFlowSteps[0])
        } else if (typeof flow === 'undefined') {
            flow = this.addToCartFlowSteps[0]
        }
        // console.log('getParentStep >>> childIndex', childIndex)
        // console.log('getParentStep >>> flow.stepsLevel', flow.stepsLevel)
        if (childIndex.length < 2) {
            // console.log("On est sur le plus bas niveau d'étapes")
            return null
        }
        if (flow.stepsLevel === childIndex.length - 1) {
            return flow
        }
        // if (typeof flow.stepsLevel === 'undefined') {
        //     debugger
        // }
        // if (typeof childIndex[flow.stepsLevel] === 'undefined') {
        //     debugger
        // }
        // console.log('getParentStep >>> childIndex[flow.stepsLevel]', childIndex[flow.stepsLevel])
        // console.log('getParentStep >>> flow.steps[childIndex[flow.stepsLevel]]', flow.steps[childIndex[flow.stepsLevel]])
        return this.getParentStep({ childIndex, flow: flow.steps[childIndex[flow.stepsLevel]] }, goTo)
    }

    getParentNextStep (stepIndex, goTo) {
        if (typeof stepIndex === 'undefined') {
            stepIndex = this.currentStepIndex
        }
        // console.log('goToParentNextStep')
        // console.log('REMOVE currentStepIndex LVL ' + (this.currentStepIndex.length - 1))
        // this.currentStepIndex.pop()
        const parent = this.getParentStep({ childIndex: stepIndex }, goTo)
        if (parent !== null) {
            if (typeof parent.steps[stepIndex[parent.stepsLevel] + 1] !== 'undefined') {
                // if (parent.stepsLevel < stepIndex.length - 1) {
                //     stepIndex.pop()
                // }
                // stepIndex.pop()
                // console.log('Update currentStepIndex LVL ' + parent.stepsLevel + ' TO ' + (stepIndex[parent.stepsLevel] + 1))
                stepIndex[parent.stepsLevel]++
                // console.log('getParentNextStep >>> on passe au parent suivant', parent.steps[stepIndex[parent.stepsLevel]])
                // stepIndex.pop()
                return parent.steps[stepIndex[parent.stepsLevel]]
            } else {
                // C'était la dernière étape de ce parent également ==> On remonte encore d'un cran
                // stepIndex.pop()
                // console.log("getParentNextStep >>> On remonte encore d'un cran")
                // console.log('REMOVE currentStepIndex LVL ' + (stepIndex.length - 1))
                stepIndex.pop()
                return this.getParentNextStep(stepIndex, goTo)
            }
        }
        // console.log("C'était le plus bas niveau et on a pas d'étape suivante... On retourne null")
        return parent
        // if (typeof currentLevel === 'undefined') {
        //     currentLevel = 0
        // }
        // if (currentLevel === childLevel - 1) {
        //     // if ()
        //     // return this.getParentNextStep(childLevel, currentLevel)
        // }
    }

    findInPreviousSteps (params) {
        const parentStep = this.getParentStep({ childIndex: this.currentStepIndex })

        if (!isEmpty(params) && !isEmpty(parentStep.steps)) {
            const foundSteps = parentStep.steps.filter((step, index) => step && index < this.currentStepIndex[parentStep.stepsLevel] && step.type === params.type)

            if (!isEmpty(foundSteps) && params?.filterFunction) {
                return foundSteps.filter(item => params.filterFunction(item))
            }

            return foundSteps
        }
        return []
    }

    // getStepLevelFlow (level, product) {
    //
    // }

    isFirstStep (stepIndex) {
        if (typeof stepIndex === 'undefined') {
            stepIndex = this.currentStepIndex
        }
        if (stepIndex) {
            const count = stepIndex.length
            for (let i = count - 1; i >= 0; i--) {
                if (stepIndex[i] > 0) {
                    // console.log('isFirstStep >>> IS NOT FIRST STEP')
                    return false
                }
            }
        }
        // console.log('isFirstStep >>> IS FIRST STEP')
        return true
    }

    isFirstLevelStep (stepIndex) {
        if (typeof stepIndex === 'undefined') {
            stepIndex = this.currentStepIndex
        }
        if (stepIndex[stepIndex.length - 1] === 0) {
            // console.log('isFirstLevelStep >>> IS FIRST LEVEL STEP')
            return true
        }
        // console.log('isFirstLevelStep >>> IS NOT FIRST LEVEL STEP')
        return false
    }

    getLastStepIndex (lastStepIndex, currentFlow) {
        if (typeof currentFlow === 'undefined') {
            currentFlow = this.addToCartFlowSteps[0]
        }
        if (typeof lastStepIndex === 'undefined') {
            lastStepIndex = [0]
        }
        if (!isEmpty(currentFlow.steps)) {
            lastStepIndex.push(currentFlow.steps.length - 1)
            return this.getLastStepIndex(lastStepIndex, currentFlow.steps[currentFlow.steps.length - 1])
        }
        // console.log('getLastStepIndex', lastStepIndex)
        return lastStepIndex
    }

    isLastStep (stepIndex) {
        if (typeof stepIndex === 'undefined') {
            stepIndex = this.currentStepIndex
        }
        const lastStepIndex = this.getLastStepIndex()
        const count = lastStepIndex.length
        for (let i = 0; i < count; i++) {
            if (typeof stepIndex[i] === 'undefined' || stepIndex[i] !== lastStepIndex[i]) {
                return false
            }
        }
        return true
    }

    isLastLevelStep (params) {
        if (typeof params.level === 'undefined') {
            params.level = 0
        }
        if (typeof params.currentFlow === 'undefined') {
            params.currentFlow = this.addToCartFlowSteps
        }
        if (typeof params.currentStepIndex === 'undefined') {
            params.currentStepIndex = this.currentStepIndex
        }
        if (typeof params.currentFlow[params.currentStepIndex[params.level + 1]] !== 'undefined') {
            // S'il y a encore au moins un étage
            // return this.isLastLevelStep(level + 1, currentFlow[params.currentStepIndex[level + 1]].steps)
            return this.isLastLevelStep({
                level: params.level + 1,
                currentFlow: params.currentFlow[params.currentStepIndex[params.level + 1]].steps,
                stepIndex: params.currentStepIndex
            })
        } else if (typeof params.currentFlow[params.currentStepIndex[params.level] + 1] !== 'undefined') {
            // Sinon si on peut avancer
            // console.log('isLastLevelStep >>> FALSE')
            return false
        }
        // console.log('isLastLevelStep >>> TRUE')
        return true
    }

    getCurrentStep (level, steps) {
        let currentStep = null
        if (typeof level === 'undefined') {
            level = 0
        }
        if (typeof steps === 'undefined') {
            currentStep = !isEmpty(this._addToCartFlowSteps[this._currentStepIndex[0]].steps[this._currentStepIndex[1]]) ? this._addToCartFlowSteps[this._currentStepIndex[0]].steps[this._currentStepIndex[1]] : null
        } else if (level > 0) {
            currentStep = !isEmpty(steps[this._currentStepIndex[level]]) ? steps[this._currentStepIndex[level]] : null
        }

        if (!isEmpty(currentStep?.steps)) {
            return this.getCurrentStep(level + 1, currentStep.steps)
        }

        return currentStep
    }

    getFlowBuildingParams () {
        return {
            supports: this.store.getters.getWalletSupports
        }
    }

    initAddCartFlow (product, context) {
        const params = this.getFlowBuildingParams()
        this.addToCartFlowConfig = product.getAddToCartFlowConfig(params)
        this.addToCartFlowSteps = [{
            steps: product.getAddToCartFlow(params),
            stepsLevel: 1
        }]
        this.addToCartFlowContext = !isEmpty(context) ? context : null
        this.product = product
        if (this.addToCartFlowSteps[0].steps.length > 0) {
            this.currentStepIndex = [0, 0]
            this.currentStepProduct = this.addToCartFlowSteps[0].steps[this.currentStepIndex[1]].product
            if (this.addToCartFlowSteps[0].steps[this.currentStepIndex[1]].type === 'subProductFlow') {
                this.currentStepIndex.push(0)
                this.currentStepProduct = this.addToCartFlowSteps[0].steps[this.currentStepIndex[1]].steps[this.currentStepIndex[2]].product
                this.currentStep = this.getCurrentStep()
            } else {
                this.currentStepProduct = this.addToCartFlowSteps[0].steps[this.currentStepIndex[1]].product
                this.currentStep = this.getCurrentStep()
            }
            this.store.commit(cartMutationTypes.SET_IS_ADD_TO_CART_FLOW_FIRST_STEP, true)
            this.store.commit(cartMutationTypes.SET_IS_ADD_TO_CART_FLOW_LAST_STEP, this.currentStepIndex.equals(this.getLastStepIndex()))
            // console.log('initAddCartFlow >>> _currentStepProduct', this.currentStepProduct)
        }
    }

    updateAddCartFlow (product) {
        const pendingAddToCartFlow = this.getPendingAddToCartFlow()
        let steps

        if (isEmpty(pendingAddToCartFlow)) {
            const params = this.getFlowBuildingParams()
            steps = product.getAddToCartFlow(params)
        } else {
            steps = pendingAddToCartFlow.addToCartFlowSteps[0].steps
        }

        const authenticationModeStepIndex = steps.findIndex((step) => step.type === 'authenticationMode')
        if (authenticationModeStepIndex !== -1) {
            const [previousAuthenticationModeStep] = this.findInPreviousSteps({
                type: 'authenticationMode'
            })
            steps[authenticationModeStepIndex] = previousAuthenticationModeStep
        }

        const saleOptionStepIndex = steps.findIndex((step) => step.type === 'saleOption')
        if (saleOptionStepIndex !== -1) {
            const [previousSaleOptionStep] = this.findInPreviousSteps({
                type: 'saleOption'
            })
            if (previousSaleOptionStep) {
                steps[saleOptionStepIndex] = previousSaleOptionStep
            }
        }
        this.addToCartFlowSteps[0].steps = steps
        this.product = product
    }

    async resetAddCartFlow () {
        this.addToCartFlowConfig = null
        this.addToCartFlowSteps = null
        this.currentStep = null
        this.currentStepIndex = null
        this.currentStepProduct = null
        this.product = null
        this.store.commit(cartMutationTypes.SET_IS_ADD_TO_CART_FLOW_FIRST_STEP, true)
        this.store.commit(cartMutationTypes.SET_IS_ADD_TO_CART_FLOW_LAST_STEP, false)
        this.store.commit(cartMutationTypes.SET_IS_GO_TO_NEXT_STEP_DISABLED, false)
        this.store.commit(cartMutationTypes.SET_TEMP_ADD_CART_FLOW, null)
        localStorage.removeItem('addToCartFlow')

        if (
            this.store.state.applicationSessionModule.applicationSession?.id &&
            this.store.state.applicationSessionModule.applicationSession?.data?.addToCartFlow
        ) {
            const applicationSession = this.store.state.applicationSessionModule.applicationSession

            delete applicationSession.data.addToCartFlow

            this.store.commit(applicationSessionMutationTypes.SET_APPLICATION_SESSION, applicationSession)

            await this.store.dispatch(applicationSessionActionTypes.UPDATE_APPLICATION_SESSION)
        }
    }

    getAddCartFlowState () {
        return {
            productId: this.product.id,
            productProvider: this.product.getProvider(),
            productVersion: this.product.version,
            catalogContext: this.store.state.catalogModule.currentCatalogParams,
            addToCartFlowConfig: this.addToCartFlowConfig,
            addToCartFlowSteps: this.addToCartFlowSteps,
            currentStepIndex: this.currentStepIndex
        }
    }

    setAddToCartFlowStateToLocalStorage () {
        let addToCartFlow = this.getAddCartFlowState()
        // console.log('setAddToCartFlowStateToLocalStorage', this.addToCartFlowSteps)
        // console.log('setAddToCartFlowStateToLocalStorage', addToCartFlow)
        addToCartFlow = JSON.stringify(addToCartFlow)
        // console.log('setAddToCartFlowStateToLocalStorage', addToCartFlow)
        localStorage.setItem('addToCartFlow', addToCartFlow)
    }

    generateFlowChart (steps = []) {
        // let columns = 0
        // let rows = 0
        const items = []
        if (!isEmpty(steps)) {
            let stepLvl1, stepLvl2, stepLvl3, stepLvl4
            let column = 0
            let row = 0
            let item
            row++
            for (stepLvl1 of steps) {
                // console.log('stepLvl1', stepLvl1)
                column++
                item = {
                    column,
                    isCurrent: this.isCurrentStep(stepLvl1),
                    isVisual: this.isVisualStep(stepLvl1),
                    row,
                    step: stepLvl1
                }
                items.push(item)
                if (!isEmpty(stepLvl1.steps)) {
                    row++
                    for (stepLvl2 of stepLvl1.steps) {
                        // console.log('stepLvl2', stepLvl2)
                        column++
                        item = {
                            column,
                            isCurrent: this.isCurrentStep(stepLvl2),
                            isVisual: this.isVisualStep(stepLvl2),
                            row,
                            step: stepLvl2
                        }
                        items.push(item)
                        if (!isEmpty(stepLvl2.steps)) {
                            row++
                            for (stepLvl3 of stepLvl2.steps) {
                                // console.log('stepLvl3', stepLvl3)
                                if (!isEmpty(stepLvl3.steps)) {
                                    row++
                                    for (stepLvl4 of stepLvl3.steps) {
                                        // eslint-disable-next-line
                                        console.log('stepLvl4', stepLvl4)
                                    }
                                    row--
                                }
                            }
                            row--
                        }
                    }
                    row--
                }
            }
            row--
        }
        // let test = items.flatMap(item => item.column)
        // console.log(test)
        return {
            columns: Math.max(...items.flatMap(item => item.column)),
            rows: Math.max(...items.flatMap(item => item.row)),
            items
        }
    }

    isCurrentStep (step) {
        // console.log('isCurrentStep >>> this.currentStepIndex', this.currentStepIndex)
        // console.log('isCurrentStep >>> this.currentStep', this.currentStep)
        // console.log('isCurrentStep >>> step', step)
        const isCurrentStep = Object.is(step, this.currentStep)
        // console.log('isCurrentStep', isCurrentStep)

        return isCurrentStep
    }

    isVisualStep (step) {
        return step.type !== 'subProductFlow'
    }
}
