<template>
    <div class="DebugPanel">
        <ul class="DebugPanel-list">
            <li class="DebugPanel-item">
                <a
                    :to="{ name: 'supportContent', params: { providerId: 'conduent' } }"
                    class="DebugPanel-itemLink"
                    @click="goToSupportContent('conduent')"
                >
                    {{ $t('support_content:title') }}
                </a>
            </li>
            <li class="DebugPanel-item">
                <a
                    :to="{ name: 'fineRegularization', params: { provider: 'conduent', fineId: 'conduent:fine' } }"
                    class="DebugPanel-itemLink"
                    @click="goToFineRegularization('conduent', 'conduent:fine')"
                >
                    {{ $t('fine:regularization:title') }} Conduent
                </a>
            </li>
            <li
                v-if="debugMode && getCart.id !== null"
                class="DebugPanel-item"
            >
                <a
                    class="DebugPanel-itemLink"
                    @click="clearBasket"
                >
                    /!\ Vider le panier #{{ getCart.id }}
                </a>
            </li>
        </ul>
        <div class="mb-5">
            <v-switch
                v-model="checkAccountData"
                color="primary"
                label="Check Account"
                @update:model-value="updateCheckAccount"
            />
            <v-switch
                v-model="forceUseWalletCacheData"
                color="primary"
                label="Utiliser le cache pour la récupération du Wallet"
                @update:model-value="updateForceUseWalletCache"
            />
            <v-switch
                v-model="debugModeData"
                color="primary"
                label="DEBUG_MODE"
                @update:model-value="updateDebugMode"
            />
            <v-switch
                v-model="fileCompressionConf"
                color="primary"
                label="Forcer l'activation de la compression d'upload d'img "
                @update:model-value="updateCompressionConf"
            />
            <v-btn
                v-if="debugMode"
                color="primary"
                variant="flat"
                @click="loadCart"
            >
                Appeler GET CART
            </v-btn>
        </div>
        <div
            v-if="debugMode && getShowMocksConfigLoader"
            class="DebugPanel-debugConfig"
        >
            <p>Affichage des informations et outils de debugging</p>
            <!-- eslint-disable vuetify/no-deprecated-props -->
            <v-slider
                v-model="debugInformationsDisplayData"
                :ticks="tickLabels"
                class="DebugPanel-debugInformationsDisplay"
                min="0"
                max="3"
                show-ticks="always"
                step="1"
                track-color="gray"
                tick-size="5"
                @update:model-value="updateDebugInformationsDisplay"
            />
            <!-- eslint-enable -->
            <div class="py-5">
                <v-switch
                    v-model="showStates"
                    class="DebugPanel-toggleShowStates"
                    color="primary"
                    label="Afficher les états des différentes variables clés"
                />
                <v-list v-if="showStates">
                    <v-list-item>
                        <template #title>
                            <strong>addToCartFlow :</strong>
                        </template>
                        <pre
                            class="DebugPanel-dump"
                            v-html="JSON.stringify(addToCartFlow, null, 4)"
                        />
                    </v-list-item>
                    <v-list-item>
                        <template #title>
                            <strong>cart :</strong>
                        </template>
                        <pre
                            class="DebugPanel-dump"
                            v-html="JSON.stringify(getCart, null, 4)"
                        />
                    </v-list-item>
                    <v-list-item>
                        <template #title>
                            <strong>selectedFareMedia :</strong>
                        </template>
                        <pre
                            class="DebugPanel-dump"
                            v-html="JSON.stringify(selectedFareMedia, null, 4)"
                        />
                    </v-list-item>
                    <v-list-item>
                        <template #title>
                            <strong>wallet :</strong>
                        </template>
                        <pre
                            class="DebugPanel-dump"
                            v-html="JSON.stringify(wallet, null, 4)"
                        />
                    </v-list-item>
                </v-list>
            </div>
            <div>
                <p><strong>Mocks</strong></p>
                <mock-switch-item
                    v-for="mockAction in getMockActions"
                    :key="mockAction"
                    :mockAction="mockAction"
                    class="DebugPanel-mockSwitchItem"
                />
            </div>
        </div>
        <block-loader v-else-if="debugMode" />
    </div>
</template>

<script setup>
import {
    computed,
    onMounted, onUnmounted,
    ref
} from 'vue'
import { isEmpty } from 'global-utils'
import * as cartActionTypes from '@/StoreWeb/store/modules/cart/action-types'
import * as debuggingMutationTypes from '@/StoreWeb/store/modules/debugging/mutation-types'
import BlockLoader from '@/StoreWeb/components/common/BlockLoader'
import DebuggingManager from '@/StoreWeb/managers/DebuggingManagerSingleton'
import MockSwitchItem from '@/StoreWeb/components/debugging/MockSwitchItem'
import { useStore } from 'vuex'
import { loadCart } from '@/StoreWeb/js/composables/cart-utils'
import { useRouter } from 'vue-router'

const store = useStore()
const router = useRouter()

const emit = defineEmits(['close-panel'])

const checkAccountData = ref(false)
const debuggingManager = ref(DebuggingManager.getInstance())
const debugInformationsDisplayData = ref(0)
const debugModeData = ref(false)
const forceUseWalletCacheData = ref(false)
const showStates = ref(false)
const tickLabels = ref({
    0: 'Aucun',
    1: 'Démo',
    2: 'Avancé',
    3: 'Tous'
})

const applicationSession = computed(() => store.state.applicationSessionModule.applicationSession)
const addToCartFlow = computed(() => {
    const localStorageAddToCartFlow = localStorage.getItem('addToCartFlow')

    if (localStorageAddToCartFlow) {
        return JSON.parse(localStorageAddToCartFlow)
    } else if (!isEmpty(applicationSession.value?.data?.addToCartFlow)) {
        return JSON.parse(applicationSession.value.data.addToCartFlow)
    }

    return null
})
const getCart = computed(() => store.getters.getCart)
const isCheckAccountEnabled = computed(() => store.state.isCheckAccountEnabled)
const debugMode = computed(() => store.state.debuggingModule.debugMode)
const debugPendingRequests = computed(() => store.state.debuggingModule.debugPendingRequests)
const forceUseWalletCache = computed(() => store.state.debuggingModule.forceUseWalletCache)
const fileCompressionConf = computed(() => store.state.debuggingModule.fileCompressionConf)
const getMockActions = computed(() => {
    const mockActions = debuggingManager.value.getAvailableMockedActions()
    return mockActions
})
const getShowMocksConfigLoader = computed(() => {
    return !debugPendingRequests.value.getConfig
})
const selectedFareMedia = computed(() => store.state.userModule.selectedFareMedia)
const wallet = computed(() => store.state.userModule.wallet)

async function clearBasket () {
    if (getCart.value?.id) {
        store.dispatch(cartActionTypes.CLEAR_CART, { id: getCart.value.id })
    }
}

function goToSupportContent (providerId) {
    router.push({ name: 'supportContent', params: { providerId } })
    emit('close-panel')
}

function goToFineRegularization (providerId, productId) {
    router.push({ name: 'fineRegularization', params: { provider: providerId, fineId: productId } })
    emit('close-panel')
}

function updateCheckAccount (value) {
    localStorage.setItem('isCheckAccountEnabled', value ? 1 : 0)
}

function updateCompressionConf (value) {
    store.commit(debuggingMutationTypes.SET_COMPRESSION_FILE_COMPRESSION_CONF, value)
}

function updateDebugInformationsDisplay (value) {
    debuggingManager.value.debugInformationsDisplay = value
}

function updateDebugMode (value) {
    debuggingManager.value.debugMode = value
}

function updateForceUseWalletCache (value) {
    store.commit(debuggingMutationTypes.SET_FORCE_USE_WALLET_CACHE, value)
    if (value) {
        localStorage.setItem('isForceUseWalletCacheEnabled', 1)
    } else {
        localStorage.removeItem('isForceUseWalletCacheEnabled')
    }
}

onMounted(() => {
    checkAccountData.value = isCheckAccountEnabled.value
    debugInformationsDisplayData.value = debuggingManager.value.debugInformationsDisplay
    debugModeData.value = debuggingManager.value.debugMode
    forceUseWalletCacheData.value = forceUseWalletCache.value
    document.body.classList.add('js-debugPanel--opened')
})

onUnmounted(() => {
    document.body.classList.remove('js-debugPanel--opened')
})
</script>

<style lang="scss" scoped>
@import 'globalScss';

.DebugPanel {
    &-list {
        margin: 0;
        padding: 0;
        list-style: none;
    }

    &-item {
        margin-bottom: 20px;

        &Link {
            cursor: pointer;
        }
    }

    &-debugModeToggleSwitch {
        background: none !important;
    }

    &-mockSwitchItem {
        margin-bottom: 10px;
    }

    &-button {
        width: 100%;
    }

    &-dump {
        overflow: auto;
        padding: $s2;
        background-color: #f0f0f0;
        font-size: 12px;
    }
}
</style>
