<template lang="html">
    <div class="SaleOptionNoProviderAccountAvailable">
        <p
            class="SaleOptionNoProviderAccountAvailable-description text-center"
            v-html="$sanitize($t('sale_option:basket_info:account:no_available'))"
        />
        <div class="SaleOptionNoProviderAccountAvailable-actions d-flex flex-column align-center">
            <v-btn
                v-if="showWalletAddAccountAction"
                class="mt-8 pa-3"
                color="primary"
                size="large"
                type="submit"
                @click.prevent="redirectToCard()"
            >
                {{ getProvider.walletCreateAccount.label }}
            </v-btn>
            <v-btn
                v-if="showWalletCreateAccountAction"
                class="ma-auto mt-4"
                color="primary"
                variant="text"
                size="large"
                @click.prevent="showAddAccountModal()"
            >
                {{ getProvider.walletAddAccount.label }}
            </v-btn>
        </div>
    </div>
</template>

<script setup>
import { computed, onBeforeMount } from 'vue'
import { isEmpty } from 'global-utils'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import { useI18n } from 'vue-i18n'
import emitter from 'global-emitter'

const router = useRouter()
const store = useStore()
const { t } = useI18n()

const emit = defineEmits(['providerAccountsAvailable'])

const props = defineProps({
    provider: {
        type: String,
        required: true
    }
})

onBeforeMount(() => {
    emit('providerAccountsAvailable', getProvider.value.accounts.length > 0)
})

const getWalletProviders = computed(() => store.getters.getWalletProviders)

const getProvider = computed(() => {
    return getWalletProviders.value.find(provider => provider.id === props.provider)
})

const showWalletAddAccountAction = computed(() => {
    return !isEmpty(getProvider.value.walletAddAccount) && !isEmpty(getProvider.value.walletAddAccount.label)
})

const showWalletCreateAccountAction = computed(() => {
    return !isEmpty(getProvider.value.walletCreateAccount) && !isEmpty(getProvider.value.walletCreateAccount.label)
})

const showAddAccountModal = () => {
    // emitter.emit('saveProductToPutOnCard')
    emitter.emit('hideProductModal')
    emitter.emit('showAddFareMediaModal', { walletId: getProvider.value.id, formType: getProvider.value.walletAddAccount.mode })
}

function redirectToCard () {
    // emitter.emit('saveProductToPutOnCard')
    emitter.emit('hideProductModal')
    router.push({ name: 'product', params: { id: t('link_to_card:id'), slug: t('link_to_card:slug') } })
}
</script>

<style lang="scss" scoped>
@import 'globalScss';

.SaleOptionNoProviderAccountAvailable {
    &-description {
        line-height: $font-xlarge;
    }
}
</style>
