<template>
    <div class="AuthenticationModeAnonymous">
        <p class="AuthenticationModeAnonymous-explanation">
            {{ $t('anonymous_authentication:explanation') }}
        </p>
        <div class="AuthenticationModeAnonymous-form">
            <v-text-field
                :label="$t('form:user_infos:email:label')"
                :rules="emailRules"
                :model-value="getDefaultValue"
                autocomplete="email"
                class="AuthenticationModeAnonymous-field"
                clearable
                required
                type="email"
                variant="underlined"
                @update:model-value="emailUpdated"
            >
                <template #append>
                    <v-btn
                        :disabled="getIsEmailValid ? false : 'disabled'"
                        class="AuthenticationModeAnonymous-button v-btn__iconStyle"
                        color="primary"
                        elevation="0"
                        @click.stop="updateEmail"
                    >
                        <icomoon-icon
                            name="Check"
                            class="AuthenticationModeAnonymous-buttonIcon"
                        />
                        {{ $t('button:validate') }}
                    </v-btn>
                </template>
            </v-text-field>
        </div>
    </div>
</template>

<script>
import { isEmpty } from 'global-utils'
import AddToCartFlowManager from '@/StoreWeb/managers/AddToCartFlowManagerSingleton'
import { mapState } from 'vuex'
import IcomoonIcon from '@/StoreWeb/components/common/IcomoonIcon'
import i18n from 'i18n'
import config from 'config'

export default {
    components: {
        IcomoonIcon
    },
    data () {
        return {
            addToCartFlowManager: AddToCartFlowManager.getInstance(),
            emailRules: [
                v => !!v || i18n.global.t('validations.required'),
                v => new RegExp(config.patterns.email, 'i').test(v) || i18n.global.t('validations.email').replace('{model}', v)
            ],
            selectedEmail: null
        }
    },
    computed: {
        ...mapState({
            addToCartEmail: state => state.cartModule.addToCartEmail
        }),
        getDefaultValue () {
            if (!isEmpty(this.selectedEmail)) {
                return this.selectedEmail
            }
            return ''
        },
        getIsEmailValid () {
            if (!isEmpty(this.selectedEmail)) {
                return !isEmpty(this.selectedEmail.match(this.$config.patterns.email))
            }
            return false
        }
    },
    mounted () {
        if (
            !isEmpty(this.addToCartFlowManager.currentStep.selectedAuthenticationMode) &&
            this.addToCartFlowManager.currentStep.selectedAuthenticationMode === 'EMAILMANDATORY' &&
            !isEmpty(this.addToCartFlowManager.currentStep.selectedAuthenticationModeParameters) &&
            !isEmpty(this.addToCartFlowManager.currentStep.selectedAuthenticationModeParameters.email)
        ) {
            this.selectedEmail = this.addToCartFlowManager.currentStep.selectedAuthenticationModeParameters.email
        } else {
            this.selectedEmail = this.addToCartEmail
        }
    },
    methods: {
        emailUpdated (email) {
            this.selectedEmail = email
        },
        updateEmail () {
            this.$emit('updateEmail', this.selectedEmail)
        }
    }
}
</script>

<style lang="scss" scoped>
@import 'globalScss';

.AuthenticationModeAnonymous {
    &-buttonIcon {
        font-size: 24px;
    }

    &-explanation {
        text-align: left;
    }
}
</style>
