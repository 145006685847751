<template>
    <form class="DynamicForm">
        <p
            v-if="showFormTitle && getFormTitle !== ''"
            class="DynamicForm-title"
        >
            {{ getFormTitle }}
        </p>
        <component
            :is="config.componentName"
            v-for="config in getFields"
            :id="config.id"
            :key="config.id"
            :autocomplete="config.autocomplete"
            :defaultValue="config.value"
            :description="config.description"
            :inputType="config.inputType"
            :label="config.label"
            :labelInside="config.labelInside"
            :max="config.max"
            :maxLength="config.maxLength"
            :min="config.min"
            :minLength="config.minLength"
            :options="config.options"
            :readonly="config.readonly"
            :required="config.required"
            :immutable="config.immutable"
            class="DynamicForm-field"
            @field-value-changed="textComponentChanged"
            @select-changed="selectComponentChanged"
            @toggle-switch-changed="toggleSwitchChanged"
        />
        <div class="DynamicForm-buttonsArea">
            <v-btn
                class="bg-primary"
                type="submit"
                elevation="0"
                @click.prevent="submit"
            >
                {{ $t('button:validate') }}
            </v-btn>
        </div>
    </form>
</template>

<script>
import { isEmpty } from 'global-utils'
import emitter from 'global-emitter'
import CustomSelect from '@/StoreWeb/components/common/CustomSelect'
import DateInputFieldWithLabel from '@/StoreWeb/components/common/DateInputFieldWithLabel'
import LabelledInput from '@/StoreWeb/components/common/LabelledInput'
import ToggleSwitchWithLabel from '@/StoreWeb/components/common/ToggleSwitchWithLabel'
import formMixins from '@/StoreWeb/js/mixins/form-mixins'
import moment from 'moment'
import i18n from 'i18n'

export default {
    components: {
        CustomSelect,
        DateInputFieldWithLabel,
        LabelledInput,
        ToggleSwitchWithLabel
    },
    mixins: [formMixins],
    props: {
        buttonLabel: {
            type: String,
            default: ''
        },
        formId: {
            type: String,
            default: ''
        },
        params: {
            type: Object,
            default: null
        },
        showFormTitle: {
            type: Boolean,
            default: true
        },
        template: {
            type: Object,
            required: true
        }
    },
    data () {
        return {
            currentTemplate: this.template,
            values: {}
        }
    },
    computed: {
        getButtonLabel () {
            return this.buttonLabel !== '' ? this.buttonLabel : i18n.global.t('button:validate')
        },
        getCurrentTemplate () {
            return this.currentTemplate
        },
        getFormTitle () {
            return !isEmpty(this.getCurrentTemplate.label) ? this.getCurrentTemplate.label : ''
        },
        getFields () {
            const fields = []
            let fieldConfig

            this.getCurrentTemplate.fields.forEach(field => {
                fieldConfig = field.getConfig()
                if (!isEmpty(fieldConfig) && !isEmpty(fieldConfig.componentName)) {
                    fields.push(fieldConfig)
                }
            })
            return fields
        },
        getValues () {
            return this.values
        }
    },
    watch: {
        template (value) {
            this.currentTemplate = value

            if (!isEmpty(value.fields)) {
                value.fields.forEach(field => {
                    if (!isEmpty(field.value)) {
                        this.values[field.id] = field.value
                    }
                })
            }
        }
    },
    mounted () {
        this.$nextTick(() => {
            this.initValues()
        })

        if (!isEmpty(this.formId)) {
            emitter.on(this.formId + '.externalUpdate', this.externalUpdateHandler)
        }
    },
    unmounted () {
        if (!isEmpty(this.formId)) {
            emitter.off(this.formId + '.externalUpdate', this.externalUpdateHandler)
        }
    },
    methods: {
        checkFormError () {
            let isValid = true
            const report = []
            this.getCurrentTemplate.fields.forEach(field => {
                report[field.id] = {
                    errors: []
                }
                if (field.required && isEmpty(this.values[field.id]) && this.values[field.id] !== '-') {
                    report[field.id].errors.push(i18n.global.t('form:error:required_field'))
                    isValid = false
                }
                if (!isEmpty(field.params) && !isEmpty(field.params.pattern)) {
                    if (!this.isMatchingPattern(field.params.pattern, this.values[field.id])) {
                        report[field.id].errors.push(i18n.global.t('form:error:incorrect_value'))
                        isValid = false
                    }
                }
                if (
                    !isEmpty(field.params) &&
                    !isEmpty(field.params.maxDate) &&
                    !isEmpty(this.values[field.id]) &&
                    moment(this.values[field.id]) > moment(field.params.maxDate)
                ) {
                    report[field.id].errors.push(i18n.global.t('form:error:incorrect_value'))
                    isValid = false
                }
            })
            return {
                isValid,
                report
            }
        },
        externalUpdateHandler (data) {
            this.getCurrentTemplate = data
        },
        initValues () {
            this.getCurrentTemplate.fields.forEach(field => {
                if (field.checkIsCompatibleValue(field.value)) {
                    this.values[field.id] = field.value
                }
            })
        },
        selectComponentChanged (data) {
            this.updateValue(data.dropdownId, data.selected.id)
        },
        submit () {
            const formValidityReport = this.checkFormError()
            this.$emit('formSubmitted', {
                isValid: formValidityReport.isValid,
                values: this.values,
                params: this.params
            })
        },
        textComponentChanged (data) {
            this.updateValue(data.id, data.value)
        },
        toggleSwitchChanged (data) {
            this.updateValue(data.id, data.value)
        },
        updateValue (id, value) {
            this.values[id] = value
        }
    }
}
</script>

<style lang='scss' scoped>
@import 'globalScss';

.DynamicForm {
    display: flex;
    flex-direction: column;
    gap: $s4;

    &-title {
        font-family: $fontFamily-title;
        font-size: 22px;
        font-weight: $fontWeight-titleBold;
    }

    &-field {
        width: 100%;
    }

    &-buttonsArea {
        display: flex;
        justify-content: flex-end;
    }
}
</style>
