import * as mutationTypes from './mutation-types'
import emitter from 'global-emitter'

export default {
    [mutationTypes.SET_DEBUG_CONFIG] (state, debugConfig) {
        state.debugConfig = debugConfig
    },
    [mutationTypes.SET_DEBUG_INFORMATIONS_DISPLAY] (state, debugInformationsDisplay) {
        state.debugInformationsDisplay = debugInformationsDisplay
    },
    [mutationTypes.SET_DEBUG_MODE] (state, debugMode) {
        state.debugMode = debugMode
    },
    [mutationTypes.SET_DEBUG_PENDING_REQUEST] (state, pendingRequest) {
        if (state.debugPendingRequests[pendingRequest.key] !== undefined) {
            state.debugPendingRequests[pendingRequest.key] = pendingRequest.value
        }
    },
    [mutationTypes.SET_FORCE_USE_WALLET_CACHE] (state, forceUseWalletCache) {
        state.forceUseWalletCache = forceUseWalletCache
    },
    [mutationTypes.SET_COMPRESSION_FILE_COMPRESSION_CONF] (state, fileCompressionConf) {
        state.fileCompressionConf = fileCompressionConf
    },
    [mutationTypes.SET_MOCK_STATE] (state, data) {
        state.mockStates[data.action].state = data.state
        state.mockStates[data.action].version = data.version
        emitter.emit(`updateMockState_${data.action}`, data)
    }
}
