import { computed, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import cachios from 'cachios'

export const useMenu = () => {
    const headerMenuRaw = ref(null)
    const footerMenuRaw = ref(null)
    const isLoaded = ref(false)
    const { locale } = useI18n()

    const headerMenuLinks = computed(() => {
        return headerMenuRaw.value ? headerMenuRaw.value[locale.value] : null
    })

    const footerMenuLinks = computed(() => {
        return footerMenuRaw.value && footerMenuRaw.value[locale.value] ? footerMenuRaw.value[locale.value].links : null
    })

    const footerSocialNetworks = computed(() => {
        return footerMenuRaw.value && footerMenuRaw.value[locale.value] ? footerMenuRaw.value[locale.value].socialNetworks : null
    })

    const loadRemoteMenus = async () => {
        if (isLoaded.value) {
            return
        }

        const cdnUrl = `${process.env.VUE_APP_CDN_URL}57/menu.json`

        try {
            const { data: { menus, footer_menu: footerMenu } } = await cachios.get(cdnUrl, {
                headers: null
            })

            headerMenuRaw.value = menus
            footerMenuRaw.value = footerMenu
            isLoaded.value = true
        } catch (e) {
            console.error('Could not load remote menus file', e)
        }
    }

    return {
        headerMenuLinks,
        footerMenuLinks,
        footerSocialNetworks,
        loadRemoteMenus
    }
}
