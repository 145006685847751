import { defineAsyncComponent } from 'vue'

export const importExternalComponents = (app) => {
    app.component('AccountAssociationDescription', AccountAssociationDescription)
    app.component('AlertingPreferences', AlertingPreferences)
    app.component('HomePageComponent', HomePageComponent)
    app.component('CatalogTopBanner', CatalogTopBanner)
    app.component('CatalogTopEmptyProviderAccountBanner', CatalogTopEmptyProviderAccountBanner)
    app.component('ConduentCardReaderInstallation', ConduentCardReaderInstallation)
    app.component('ConfirmedBasket', ConfirmedBasket)
    app.component('EmptyAccount', EmptyAccount)
    app.component('EmptyCards', EmptyCards)
    app.component('FareMediaLinking', FareMediaLinking)
    app.component('Favorites', Favorites)
    app.component('OrderSectionActions', OrderSectionActions)
    app.component('OrderValidationError', OrderValidationError)
    app.component('ReducedMobility', ReducedMobility)
    app.component('WalletAddAccount', WalletAddAccount)
    app.component('CatalogCardPageBanner', CatalogCardPageBanner)
    app.component('TextBlockComponent', TextBlockComponent)
    app.component('ModalConnection', ModalConnection)
    app.component('Modal', Modal)
}

const AccountAssociationDescription = defineAsyncComponent({
    loader: () => import(`storeWebAssets/${process.env.VUE_APP_NETWORK_ID}/AccountAssociationDescription`),
    delay: 200,
    onError (retry, fail, attempts) {
        if (attempts < 3) {
            setTimeout(retry, 1000)
        } else {
            fail()
        }
    }
})

const AlertingPreferences = defineAsyncComponent({
    loader: () => import(`storeWebAssets/${process.env.VUE_APP_NETWORK_ID}/AlertingPreferences`),
    delay: 200,
    onError (retry, fail, attempts) {
        if (attempts < 3) {
            setTimeout(retry, 1000)
        } else {
            fail()
        }
    }
})

const HomePageComponent = defineAsyncComponent({
    loader: () => import(`storeWebAssets/${process.env.VUE_APP_NETWORK_ID}/HomePageComponent`),
    delay: 200,
    onError (retry, fail, attempts) {
        if (attempts < 3) {
            setTimeout(retry, 1000)
        } else {
            fail()
        }
    }
})

const CatalogTopBanner = defineAsyncComponent({
    loader: () => import(`storeWebAssets/${process.env.VUE_APP_NETWORK_ID}/CatalogTopBanner`),
    delay: 200,
    onError (retry, fail, attempts) {
        if (attempts < 3) {
            setTimeout(retry, 1000)
        } else {
            fail()
        }
    }
})

const CatalogTopEmptyProviderAccountBanner = defineAsyncComponent({
    loader: () => import(`storeWebAssets/${process.env.VUE_APP_NETWORK_ID}/CatalogTopEmptyProviderAccountBanner`),
    delay: 200,
    onError (retry, fail, attempts) {
        if (attempts < 3) {
            setTimeout(retry, 1000)
        } else {
            fail()
        }
    }
})

const ConduentCardReaderInstallation = defineAsyncComponent({
    loader: () => import(`storeWebAssets/${process.env.VUE_APP_NETWORK_ID}/ConduentCardReaderInstallation`),
    delay: 200,
    onError (retry, fail, attempts) {
        if (attempts < 3) {
            setTimeout(retry, 1000)
        } else {
            fail()
        }
    }
})

const ConfirmedBasket = defineAsyncComponent({
    loader: () => import(`storeWebAssets/${process.env.VUE_APP_NETWORK_ID}/ConfirmedBasket`),
    delay: 200,
    onError (retry, fail, attempts) {
        if (attempts < 3) {
            setTimeout(retry, 1000)
        } else {
            fail()
        }
    }
})

const EmptyAccount = defineAsyncComponent({
    loader: () => import(`storeWebAssets/${process.env.VUE_APP_NETWORK_ID}/EmptyAccount`),
    delay: 200,
    onError (retry, fail, attempts) {
        if (attempts < 3) {
            setTimeout(retry, 1000)
        } else {
            fail()
        }
    }
})

const EmptyCards = defineAsyncComponent({
    loader: () => import(`storeWebAssets/${process.env.VUE_APP_NETWORK_ID}/EmptyCards`),
    delay: 200,
    onError (retry, fail, attempts) {
        if (attempts < 3) {
            setTimeout(retry, 1000)
        } else {
            fail()
        }
    }
})

const FareMediaLinking = defineAsyncComponent({
    loader: () => import(`storeWebAssets/${process.env.VUE_APP_NETWORK_ID}/FareMediaLinking`),
    delay: 200,
    onError (retry, fail, attempts) {
        if (attempts < 3) {
            setTimeout(retry, 1000)
        } else {
            fail()
        }
    }
})

const Favorites = defineAsyncComponent({
    loader: () => import(`storeWebAssets/${process.env.VUE_APP_NETWORK_ID}/Favorites`),
    delay: 200,
    onError (retry, fail, attempts) {
        if (attempts < 3) {
            setTimeout(retry, 1000)
        } else {
            fail()
        }
    }
})

const OrderSectionActions = defineAsyncComponent({
    loader: () => import('storeWebAssets/default/OrderSectionActions'),
    delay: 200,
    onError (retry, fail, attempts) {
        if (attempts < 3) {
            setTimeout(retry, 1000)
        } else {
            fail()
        }
    }
})

const OrderValidationError = defineAsyncComponent({
    loader: () => import('storeWebAssets/default/OrderValidationError'),
    delay: 200,
    onError (retry, fail, attempts) {
        if (attempts < 3) {
            setTimeout(retry, 1000)
        } else {
            fail()
        }
    }
})

const ReducedMobility = defineAsyncComponent({
    loader: () => import(`storeWebAssets/${process.env.VUE_APP_NETWORK_ID}/ReducedMobility`),
    delay: 200,
    onError (retry, fail, attempts) {
        if (attempts < 3) {
            setTimeout(retry, 1000)
        } else {
            fail()
        }
    }
})

const WalletAddAccount = defineAsyncComponent({
    loader: () => import(`storeWebAssets/${process.env.VUE_APP_NETWORK_ID}/WalletAddAccount`),
    delay: 200,
    onError (retry, fail, attempts) {
        if (attempts < 3) {
            setTimeout(retry, 1000)
        } else {
            fail()
        }
    }
})

const CatalogCardPageBanner = defineAsyncComponent({
    loader: () => import(`storeWebAssets/${process.env.VUE_APP_NETWORK_ID}/CatalogCardPageBanner`),
    delay: 200,
    onError (retry, fail, attempts) {
        if (attempts < 3) {
            setTimeout(retry, 1000)
        } else {
            fail()
        }
    }
})

const TextBlockComponent = defineAsyncComponent({
    loader: () => import(`storeWebAssets/${process.env.VUE_APP_NETWORK_ID}/TextBlockComponent`),
    delay: 200,
    onError (retry, fail, attempts) {
        if (attempts < 3) {
            setTimeout(retry, 1000)
        } else {
            fail()
        }
    }
})

const ModalConnection = defineAsyncComponent({
    loader: () => import(`storeWebAssets/${process.env.VUE_APP_NETWORK_ID}/ModalConnection`),
    delay: 200,
    onError (retry, fail, attempts) {
        if (attempts < 3) {
            setTimeout(retry, 1000)
        } else {
            fail()
        }
    }
})

const Modal = defineAsyncComponent({
    loader: () => import('storeWebAssets/default/Modal'),
    delay: 200,
    onError (retry, fail, attempts) {
        if (attempts < 3) {
            setTimeout(retry, 1000)
        } else {
            fail()
        }
    }
})
