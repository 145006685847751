<template>
    <div
        ref="referenceParent"
        :class="{ 'PhotoFileInputFieldWithLabel--format4-3' : formatRatio === '4:3', 'PhotoFileInputFieldWithLabel--filled' : glimpseBase64String !== '' }"
        class="PhotoFileInputFieldWithLabel"
    >
        <div
            v-show="!showCrop"
            class="PhotoFileInputFieldWithLabel-inputWrapper"
        >
            <label
                :for="`js-file-${fileId}`"
                class="PhotoFileInputFieldWithLabel-label"
            >
                <span class="PhotoFileInputFieldWithLabel-glimpseWrapper">
                    <img
                        v-if="glimpseBase64String !== '' && errorLabel === ''"
                        :src="glimpseBase64String"
                        class="PhotoFileInputFieldWithLabel-glimpse"
                        alt=""
                    >
                    <span class="PhotoFileInputFieldWithLabel-glimpseIconWrapper">
                        <icomoon-icon
                            name="Camera--filled"
                            class="PhotoFileInputFieldWithLabel-glimpseIcon"
                        />
                    </span>
                </span>
                <div>
                    <span
                        v-if="getFileName === ''"
                        class="PhotoFileInputFieldWithLabel-text"
                    >
                        {{ label }}
                    </span>
                    <span
                        v-if="getFileName === '' && description !== ''"
                        class="PhotoFileInputFieldWithLabel-description"
                    >
                        ({{ description }})
                    </span>
                    <span
                        v-if="isFileInstructionsDisplayed && getFileName === '' && getFileInstructions !== ''"
                        class="PhotoFileInputFieldWithLabel-fileFormat"
                        v-html="$sanitize(getFileInstructions)"
                    />
                    <em
                        v-if="getFileName !== ''"
                        class="PhotoFileInputFieldWithLabel-fileName"
                    >
                        {{ getFileName }}
                        <icomoon-icon
                            name="Edit"
                            class="PhotoFileInputFieldWithLabel-icon"
                        />
                    </em>
                </div>
            </label>
            <input
                :id="`js-file-${fileId}`"
                ref="referenceFile"
                :aria-label="label"
                :aria-required="isRequired"
                :required="isRequired"
                class="PhotoFileInputFieldWithLabel-input"
                type="file"
                tabindex="0"
                @focus="focused=true"
                @blur="focused=false"
                @change="fieldValueChanged"
            >
            <div
                v-if="errorLabel !== ''"
                :aria-label="errorLabel"
                class="PhotoFileInputFieldWithLabel-error"
            >
                {{ errorLabel }}
            </div>
        </div>
        <div
            v-show="getImgSrc && !isPdfFile"
            class="content"
        >
            <a
                v-show="!showCrop && errorLabel === ''"
                class="PhotoFileInputFieldWithLabel-showCropAreaButton"
                @click="toggleShowCrop"
            >
                <icomoon-icon
                    name="Crop"
                    class="PhotoFileInputFieldWithLabel-showCropAreaButtonIcon"
                />
                {{ $t('button:crop') }}
            </a>
            <section
                v-show="showCrop"
                class="cropper-area"
            >
                <div
                    class="img-cropper"
                >
                    <vue-cropper
                        ref="referenceCropper"
                        :minContainerHeight="250"
                        :src="getImgSrc"
                        :aspectRatio="getAspectRatio"
                        preview=".preview"
                    />
                </div>
                <div class="PhotoFileInputFieldWithLabel-cropActions">
                    <a
                        class="PhotoFileInputFieldWithLabel-cropActionButton"
                        @click.prevent="rotate(90)"
                    >
                        {{ $t('button:rotate') }}
                    </a>
                    <a
                        class="PhotoFileInputFieldWithLabel-cropActionButton"
                        @click.prevent="cropImage"
                    >
                        {{ $t('button:validate') }}
                    </a>
                </div>
            </section>
        </div>
    </div>
</template>

<script setup>
import { ref, computed } from 'vue'
import IcomoonIcon from '@/StoreWeb/components/common/IcomoonIcon'
import FileManager from '@/StoreWeb/managers/FileManager'
import VueCropper from 'vue-cropperjs'
import i18n from 'i18n'
import 'cropperjs/dist/cropper.css'

const emit = defineEmits(['imageCropped', 'fieldValueChanged', 'fileInputError'])

const props = defineProps({
    isRequired: {
        type: Boolean,
        default: true
    },
    description: {
        type: String,
        default: ''
    },
    isFileInstructionsDisplayed: {
        type: Boolean,
        default: true
    },
    fileName: {
        type: String,
        default: ''
    },
    format: {
        type: String,
        default: 'image/jpeg'
    },
    mimeTypes: {
        type: Array,
        default: () => ['image/jpeg', 'image/png', 'application/pdf']
    },
    label: {
        type: String,
        default: 'forms:fileinput:default_label'
    },
    maxSize: {
        type: Number,
        default: 5100
    },
    fileId: {
        type: [Number, String],
        required: true
    },
    base64String: {
        type: String,
        default: ''
    },
    formatRatio: {
        type: String,
        default: '1:1',
        validator: val => ['1:1', '3:4', '4:3'].includes(val)
    }
})

const errorLabel = ref('')
const file = ref(null)
const glimpseBase64String = ref(props.base64String)
const showCrop = ref(false)
const referenceParent = ref(null)
const referenceFile = ref(null)
const referenceCropper = ref(null)

const getAspectRatio = computed(() => {
    const splittedRatio = props.formatRatio.split(':')
    if (splittedRatio.length > 1) {
        return parseInt(splittedRatio[0]) / parseInt(splittedRatio[1])
    }
    return 1
})

const getImgSrc = computed(() => glimpseBase64String.value)
const getFileName = computed(() => file.value ? file.value.name : (props.fileName !== '' ? props.fileName : ''))
const getFileInstructions = computed(() => FileManager.getFileInstructions(props.mimeTypes, props.maxSize))

function setImgSrc (src) {
    glimpseBase64String.value = src
}

const isPdfFile = computed(() => /\.pdf$/i.test(props.fileName))
function cropImage () {
    const base64String = referenceCropper.value.getCroppedCanvas().toDataURL(props.format)
    setImgSrc(base64String)
    toggleShowCrop()

    emit('imageCropped', {
        id: props.fileId,
        base64String
    })
}

function rotate (deg) {
    referenceCropper.value.rotate(deg)
}

function fieldValueChanged (event) {
    // If we cancel and upload nothing we do nothing and keep the last document
    if (!event.target.files || event.target.files.length === 0) {
        return
    }
    check()
    updateGlimpse(event)
    emit('fieldValueChanged', event)
}

function check () {
    file.value = referenceFile.value.files[0]
    errorLabel.value = ''

    if (typeof file.value === 'undefined') {
        file.value = null
    } else {
        checkFileValid()
    }
}

function checkFileValid () {
    if (!isAuthorizedExtension()) {
        const translation = i18n.global.t('forms:fileinput:wrong_file_type')

        errorLabel.value = translation
        emit('fileInputError', translation)
    } else if (!FileManager.isAuthorizedSize(file.value.size, props.maxSize)) {
        const translation = FileManager.getFileSizeExceded(props.maxSize)

        errorLabel.value = translation
        emit('fileInputError', translation)
    }

    if (errorLabel.value !== '') {
        emit('fileInputError', 'forms:fileinput:unknown_error')
        referenceParent.value.classList.add(`${name}--error`)
        file.value = null
    } else {
        emit('fileInputError', false)
        referenceParent.value.classList.remove(`${name}--error`)
    }
}

function isAuthorizedExtension () {
    return props.mimeTypes.indexOf(file.value.type) !== -1
}

function updateGlimpse (e) {
    const selectedFile = e.target.files[0]

    if (typeof selectedFile !== 'undefined' && typeof FileReader === 'function') {
        const reader = new FileReader()
        reader.onload = (event) => {
            glimpseBase64String.value = event.target.result
            referenceCropper.value.replace(event.target.result)
        }
        reader.readAsDataURL(selectedFile)
    }

    if (errorLabel.value === '') {
        if (file.value !== null) {
            const reader = new FileReader()
            reader.onload = () => {
                glimpseBase64String.value = reader.result
            }
            reader.readAsDataURL(file.value)
        }
    }

    glimpseBase64String.value = ''
}

function toggleShowCrop () {
    showCrop.value = !showCrop.value
}
</script>

<style lang='scss' scoped>
@import 'globalScss';

.PhotoFileInputFieldWithLabel {
    position: relative;
    text-align: center;

    &-label {
        display: block;
        cursor: pointer;
    }

    &-glimpseWrapper {
        display: inline-flex;
        justify-content: center;
        align-items: center;
        position: relative;
        overflow: hidden;
        width: 125px;
        height: 125px;
        border: 1px solid $color-lightgray2;
        border-radius: 125px;
    }

    &-glimpse {
        max-width: 125px;
        max-height: 125px;
    }

    &-glimpseIcon {
        font-size: 64px;
        color: $color-lighterText;

        &Wrapper {
            display: flex;
            justify-content: center;
            align-items: center;
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
        }
    }

    &-text {
        display: block;
        padding-top: 10px;
        font-size: 14px;
        font-weight: $fontWeight-defaultBold;
        color: $color-lightText;
    }

    &-description {
        display: block;
        font-size: 12px;
        color: $color-lightText;
    }

    &-fileFormat {
        display: block;
        font-size: 12px;
        color: $color-lighterText;
    }

    &-fileName {
        display: inline-flex;
        align-items: center;
        font-size: 14px;
        color: $color-brandPrimary;
    }

    &-icon {
        font-size: 20px;
    }

    &-input {
        position: absolute;
        top: -9999px;
        left: -9999px;
    }

    &-error {
        padding-top: 6px;
        font-size: 14px;
        color: $color-danger;
    }

    &-cropActionButton,
    &-showCropAreaButton {
        display: inline-flex;
        justify-content: flex-start;
        align-items: center;
        height: 32px;
        margin: 0 15px;
        padding: 0 15px;
        border-radius: $border-radius-small;
        background: $color-brandSecondary;
        cursor: pointer;
        font-size: 14px;
        text-decoration: none;
        color: $color-lightest;
        transition: background-color .5s;
    }

    &-cropActionButton {
        &:hover,
        &:focus {
            background: darken($color-brandSecondary, 10%);
        }

        &:last-child {
            margin-left: 0;
        }
    }

    &-showCropAreaButton {
        margin-top: 15px;

        &Icon {
            margin-right: 8px;
        }

        &:hover,
        &:focus {
            background: darken($color-brandSecondary, 10%);
        }

        &:last-child {
            margin-left: 0;
        }
    }

    &-cropActions {
        display: flex;
        justify-content: center;
        align-items: center;
        padding-top: 15px;
    }

    &--format4-3 {
        .PhotoFileInputFieldWithLabel {
            &-glimpseWrapper {
                width: 123px;
                height: 164px;
                border-radius: 6px;
            }

            &-glimpse {
                max-width: 123px;
                max-height: 164px;
            }
        }
    }
}

.PhotoFileInputFieldWithLabel--filled {
    .PhotoFileInputFieldWithLabel-glimpseIconWrapper {
        background: rgba(0, 0, 0, .2);
    }

    .PhotoFileInputFieldWithLabel-glimpseIcon {
        color: $color-lightest;
        opacity: .4;
        transition: all .5s;
    }

    .PhotoFileInputFieldWithLabel-glimpseWrapper {
        &:active,
        &:hover {
            .PhotoFileInputFieldWithLabel-glimpseIcon {
                opacity: 1;
            }
        }
    }
}

.cropper-area {
    width: 80%;
    text-align: center;
}

.content {
    display: flex;
    justify-content: center;
}
</style>
