<template lang="html">
    <div
        ref="defaultLayoutReference"
        class="DefaultLayout"
    >
        <global-loader
            :showGlobalLoader="showGlobalLoader.value"
            :subtitle="showGlobalLoader.subtitle"
            :title="showGlobalLoader.title"
        />

        <mobile-header-bar
            v-if="isMobileApp"
            :style="{ zIndex : headerBarZIndex }"
            class="DefaultLayout-header"
        />
        <header-bar-sytral
            v-else-if="getHeaderTemplate === 'sytral' && !isMobileApp"
            :style="{ zIndex : headerBarZIndex }"
            class="DefaultLayout-header"
        />
        <header-bar
            v-else
            :style="{ zIndex : headerBarZIndex }"
            class="DefaultLayout-header"
        />
        <the-sidebar
            v-if="getHeaderTemplate !== 'sytral'"
            :configuration="configuration"
            :use-router-link="true"
        />
        <router-view
            :class="{
                'DefaultLayout-body--sytral': getHeaderTemplate === 'sytral',
                'DefaultLayout-body--mobileApp': isMobileApp
            }"
            class="DefaultLayout-body"
        />
        <footer-bar-sytral
            v-if="getHeaderTemplate === 'sytral' && !isMobileApp"
        />
        <footer-bar v-else-if="!isMobileApp" />
        <main-nav
            v-if="!isMobileApp"
            v-click-outside="clickOutside"
        />
        <alert-modal />
        <account-fare-media-add-modal />
        <debug-config-modal />
        <debug-modal v-if="debugMode" />
        <button
            v-if="config?.debug_mode"
            class="DefaultLayout-debugButton"
            @click="showDebugConfigModal"
        >
            DEBUG
        </button>
        <v-snackbar
            v-model="successToastVisibility"
            class="DefaultLayout-alert"
            color="success"
            location="top"
            :timeout="getToastTimeoutConfig"
        >
            <div v-html="$sanitize($t(feedbackSuccess))" />
            <template #actions>
                <v-btn
                    elevation="0"
                    icon="mdi-close"
                    size="small"
                    :aria-label="$t('button:close')"
                    @click="successToastVisibility = false"
                />
            </template>
        </v-snackbar>
        <v-snackbar
            v-model="errorToastVisibility"
            class="DefaultLayout-alert"
            color="error"
            location="top"
            :timeout="getToastTimeoutConfig"
        >
            <div v-html="$sanitize($t(feedbackError))" />
            <template #actions>
                <v-btn
                    elevation="0"
                    icon="mdi-close"
                    size="small"
                    :aria-label="$t('button:close')"
                    @click="errorToastVisibility = false"
                />
            </template>
        </v-snackbar>
    </div>
</template>

<script setup>
import { computed, ref } from 'vue'
import { useStore } from 'vuex'
import FooterBarSytral from '@/StoreWeb/components/footer/FooterBarSytral.vue'
import HeaderBarSytral from '@/StoreWeb/components/header/HeaderBarSytral.vue'
import AccountFareMediaAddModal from '@/StoreWeb/components/account/association/AccountFareMediaAddModal.vue'
import AlertModal from '@/StoreWeb/components/common/AlertModal.vue'
import DebugModal from '@/StoreWeb/components/common/debug/DebugModal.vue'
import DebugConfigModal from '@/StoreWeb/components/common/debug/DebugConfigModal.vue'
import GlobalLoader from '@/StoreWeb/components/common/GlobalLoader.vue'
import FooterBar from '@/StoreWeb/components/footer/FooterBar.vue'
import HeaderBar from '@/StoreWeb/components/header/HeaderBar.vue'
import MainNav from '@/StoreWeb/components/common/nav/MainNav.vue'
import MobileHeaderBar from '@/StoreWeb/components/header/MobileHeaderBar.vue'
import GlobalManager from '@/StoreWeb/managers/GlobalManagerSingleton'
import * as mainMutationTypes from '@/StoreWeb/store/modules/main/mutation-types'
import config from 'config'
import configuration from '@/../config/wh-config.json'
import { TheSidebar } from '@instant-system/web-harmony-widget'
import { showDebugConfigModal } from '@/StoreWeb/js/composables/debug-utils'

const store = useStore()

const globalManager = GlobalManager.getInstance()

const defaultLayoutReference = ref(null)

const getToastTimeoutConfig = computed(() => config.toast_timeout)
const debugMode = computed(() => store.state.debuggingModule.debugMode)
const headerBarZIndex = computed(() => store.state.headerBarZIndex)
const isMobileApp = computed(() => store.state.isMobileApp)
const showGlobalLoader = computed(() => store.state.showGlobalLoader)
const feedbackSuccess = computed(() => store.state.feedbackSuccess)
const feedbackError = computed(() => store.state.feedbackError)
const getHeaderTemplate = computed(() => config.header.template)

const successToastVisibility = computed({
    get: () => store.state.showSuccessToast,
    set: (newValue) => store.commit(mainMutationTypes.SET_SHOW_SUCCESS_TOAST, newValue)
})
const errorToastVisibility = computed({
    get: () => store.state.showErrorToast,
    set: (newValue) => store.commit(mainMutationTypes.SET_SHOW_ERROR_TOAST, newValue)
})

function clickOutside (event) {
    globalManager.onMainNavClickOutside(event, defaultLayoutReference.value)
}
</script>

<style lang='scss' scoped>
@import 'globalScss';

.loaderWithText {
    @include containerMask;

    z-index: 999;
}

.v-progress-circular {
    position: fixed;
    z-index: 9999;
    top: 50%;
    left: 50%;
    width: 100%;
    height: 100%;
}

.DefaultLayout {
    height: 100%;
    font-family: $font_family_default;

    @extend %baseLayout;

    &-body {
        @extend %baseLayoutBody;

        padding-top: 65px;
        padding-bottom: 65px;

        @media #{map-get($display-breakpoints, 'md-and-up')} {
            padding-bottom: 40px;
            padding-left: 80px;
        }

        &--sytral {
            padding-top: 68px;
            padding-bottom: 68px;
            padding-left: 0;

            @media (min-width: $desktop-breakpoint) {
                padding-top: 86px;
            }
        }

        &--mobileApp {
            padding-top: 0;
        }
    }

    &-labelledLoader {
        @include containerMask;

        z-index: 999;
    }

    &-labelledLoaderLabel {
        z-index: 999;
    }

    &-alert {
        position: fixed;
        z-index: 1000;
        top: 10px;
        left: 50%;
        width: calc(100% - #{$padding-medium});
        font-family: $fontLato;
        transform: translateX(-50%);

        @media screen and (min-width: $tablet-breakpoint) {
            width: auto;
        }
    }

    &-debugButton {
        position: fixed;
        z-index: 10002;
        right: 0;
        bottom: 0;
        padding: 10px;
        border: none;
        border-radius: $border-radius_medium 0 0 0;
        background: $color-brandPrimary;
        cursor: pointer;
        font-weight: bold;
        color: $color-lightest;
        transition: background-color .3s;

        &:hover,
        &:active,
        &:focus {
            background: $color-brandPrimaryDark;
        }
    }
}
</style>
