import { ref } from 'vue'

export function useCardReader () {
    const showSupportReaderModal = ref(false)

    function openSupportReaderModal () {
        showSupportReaderModal.value = true
    }

    function onSupportReaderOperationFinished () {
        showSupportReaderModal.value = false
    }

    return {
        showSupportReaderModal,
        openSupportReaderModal,
        onSupportReaderOperationFinished
    }
}
