import StoreWeb from '@/StoreWeb/StoreWeb'
import ExternalLinks from '@/StoreWeb/views/ExternalLinks'
import InternalLinks from '@/StoreWeb/views/InternalLinks'
import Catalog from '@/StoreWeb/views/Home'
import LoggingIn from '@/StoreWeb/views/LoggingIn'
import Page from '@/StoreWeb/views/Page'
import ProductDetails from '@/StoreWeb/views/product/ProductDetails'
import Cart from '@/StoreWeb/views/cart/Cart'
import CartConfirmation from '@/StoreWeb/views/cart/CartConfirmation'
import PaymentForm from '@/StoreWeb/views/payment/PaymentForm'
import PaymentCancelled from '@/StoreWeb/views/payment/PaymentCancelled'
import PaymentError from '@/StoreWeb/views/payment/PaymentError'
import PaymentSuccess from '@/StoreWeb/views/payment/PaymentSuccess'
import OrderSuccess from '@/StoreWeb/views/payment/OrderSuccess'
import SignedIn from '@/StoreWeb/views/SignedIn'
import Contact from '@/StoreWeb/views/Contact'
import FareMediaLoading from '@/StoreWeb/views/product/FareMediaLoading'
import FareMediaReloading from '@/StoreWeb/views/product/FareMediaReloading'
import Account from '@/StoreWeb/views/account/Account'
import AccountAssociations from '@/StoreWeb/views/account/AccountAssociations'
import Addresses from '@/StoreWeb/views/account/Addresses'
import Alerts from '@/StoreWeb/views/account/Alerts'
import DirectDebits from '@/StoreWeb/views/account/DirectDebits'
import Favourites from '@/StoreWeb/views/account/Favourites'
import ReducedMobilityProfile from '@/StoreWeb/views/account/ReducedMobilityProfile'
import AlertingPreferences from '@/StoreWeb/views/account/AlertingPreferences'
import Informations from '@/StoreWeb/views/account/Informations'
import Notifications from '@/StoreWeb/views/account/Notifications'
import OpenPaymentTrips from '@/StoreWeb/views/account/OpenPaymentTrips'
import OrderDetails from '@/StoreWeb/views/account/OrderDetails'
import Password from '@/StoreWeb/views/account/Password'
import PersonalData from '@/StoreWeb/views/account/PersonalData'
import Purchases from '@/StoreWeb/views/account/Purchases'
import Tickets from '@/StoreWeb/views/account/Tickets'
import FineSelection from '@/StoreWeb/views/fine/FineSelection'
import SupportContent from '@/StoreWeb/views/miscellaneous/SupportContent'
import WalletLight from '@/StoreWeb/views/miscellaneous/WalletLight'
import MandateSignatureSuccess from '@/StoreWeb/views/payment/MandateSignatureSuccess'
import MandateSignatureError from '@/StoreWeb/views/payment/MandateSignatureError'
import MandateSignatureCancelled from '@/StoreWeb/views/payment/MandateSignatureCancelled'
import RenewalProducts from '@/StoreWeb/views/product/RenewalProducts'
import HomePage from '@/StoreWeb/views/HomePage'
import CardCatalog from '@/StoreWeb/views/product/CardCatalog'
import PaymentMeans from '@/StoreWeb/views/account/PaymentMeans'
import Error404 from '@/StoreWeb/views/error/Error404'
import config from 'config'

const routes = [
    {
        path: '/',
        component: StoreWeb,
        children: [
            { path: 'externalLinks/:action', component: ExternalLinks, name: 'externalLinks' },
            { path: 'internalLinks/:action', component: InternalLinks, name: 'internalLinks' },
            { path: '/not-found', component: Error404, name: 'error404', meta: { layout: 'default' } }
        ]
    },
    {
        path: '/:lang',
        component: StoreWeb,
        children: [
            { path: 'home', component: config.landing_page === 'catalog' ? Catalog : HomePage, name: 'home', meta: { layout: 'default' } }
        ]
    },
    {
        path: '/:lang?',
        component: StoreWeb,
        children: [
            { path: '/', component: HomePage, name: 'home', meta: { layout: 'default' } },
            { path: 'logging-in', component: LoggingIn, name: 'loggingIn', meta: { layout: 'default' } },
            { path: 'catalog/:providerId?/:providerUserId?/:providerUserExternalId?/:fareMediaId?', component: Catalog, name: 'catalog', meta: { layout: 'default' } },
            { path: 'home/:providerId?/:providerUserId?/:providerUserExternalId?/:fareMediaId?', component: config.landing_page === 'catalog' ? Catalog : HomePage, name: 'home', meta: { layout: 'default' } },
            { path: 'homepage', component: Page, name: 'homepage', meta: { layout: 'default' } },
            { path: 'catalog/card', component: CardCatalog, name: 'cardOrder', meta: { layout: 'default' } },
            { path: 'product/:id/:slug?', component: ProductDetails, name: 'product', meta: { layout: 'default' } },
            { path: 'cart', component: Cart, name: 'cart', meta: { layout: 'default' } },
            { path: 'cart/confirmation', component: CartConfirmation, name: 'cartConfirmation', meta: { layout: 'default' } },
            { path: 'payment', component: PaymentForm, name: 'paymentForm', meta: { layout: 'default' } },
            { path: 'payment/cancelled', component: PaymentCancelled, name: 'paymentCancelled', meta: { layout: 'default' } },
            { path: 'payment/error', component: PaymentError, name: 'paymentError', meta: { layout: 'default' } },
            { path: 'payment/success', component: PaymentSuccess, name: 'paymentSuccess', meta: { layout: 'default' } },
            { path: 'order/success', component: OrderSuccess, name: 'orderSuccess', meta: { layout: 'default' } },
            { path: 'signedIn', component: SignedIn, name: 'signedIn', meta: { layout: 'default' } },
            { path: 'contact', component: Contact, name: 'contact', meta: { layout: 'default' } },
            { path: 'fare-media/loading/:providerId/:providerUserId/:providerUserExternalId/:fareMediaId', component: FareMediaLoading, name: 'fareMediaLoading', meta: { layout: 'default' } },
            { path: 'fare-media/reloading/:providerId/:providerUserId/:id', component: FareMediaReloading, name: 'fareMediaReloading', meta: { layout: 'default' } },
            { path: 'account', component: Account, name: 'account', meta: { layout: 'account' } },
            { path: 'account/associations', component: AccountAssociations, name: 'accountAssociations', meta: { layout: 'account' } },
            { path: 'account/addresses', component: Addresses, name: 'addresses', meta: { layout: 'account' } },
            { path: 'account/alerts', component: Alerts, name: 'alerts', meta: { layout: 'account' } },
            { path: 'account/direct-debits/:tab?/:page?', component: DirectDebits, name: 'directDebits', meta: { layout: 'account' } },
            { path: 'account/favourites', component: Favourites, name: 'favourites', meta: { layout: 'account' } },
            { path: 'account/reduced-mobility', component: ReducedMobilityProfile, name: 'reducedMobility', meta: { layout: 'account' } },
            { path: 'account/alerting-preferences', component: AlertingPreferences, name: 'alertingPreferences', meta: { layout: 'account' } },
            { path: 'account/informations', component: Informations, name: 'informations', meta: { layout: 'account' } },
            { path: 'account/notifications', component: Notifications, name: 'notifications', meta: { layout: 'account' } },
            { path: 'account/open-payment-trips', component: OpenPaymentTrips, name: 'openPaymentTrips', meta: { layout: 'account' } },
            { path: 'account/order-details/:id', component: OrderDetails, name: 'orderDetails', meta: { layout: 'default' } },
            { path: 'account/password', component: Password, name: 'password', meta: { layout: 'account' } },
            { path: 'account/payment-means', component: PaymentMeans, name: 'paymentMeans', meta: { layout: 'account' } },
            { path: 'account/personal-data', component: PersonalData, name: 'personalData', meta: { layout: 'account' } },
            { path: 'account/purchases', component: Purchases, name: 'purchases', meta: { layout: 'account' } },
            { path: 'account/wallet/:fareMediaId?/:tab?/:accountId?/:profileId?', component: Tickets, name: 'tickets', meta: { layout: 'account' } },
            { path: 'fine/regularization/:provider/:fineId', component: FineSelection, name: 'fineRegularization', meta: { isAuthorized: true, layout: 'default' } },
            { path: 'fine/selection', component: FineSelection, name: 'fineSelection', meta: { layout: 'default' } },
            { path: 'support-content/:providerId', component: SupportContent, name: 'supportContent', meta: { layout: 'default' } },
            { path: 'wallet-light/:providerId/:fareMediaId', component: WalletLight, name: 'walletLight', meta: { layout: 'default' } },
            { path: 'signature/success', component: MandateSignatureSuccess, name: 'signatureSuccess', meta: { layout: 'default' } },
            { path: 'signature/error', component: MandateSignatureError, name: 'signatureError', meta: { layout: 'default' } },
            { path: 'signature/cancelled', component: MandateSignatureCancelled, name: 'mandateSignatureCancelled' },
            { path: 'renewal-products', component: RenewalProducts, name: 'renewalProducts' },
            { path: 'not-found', component: Error404, name: 'error404', meta: { layout: 'default' } },
            { path: ':pathMatch(.*)*', component: Error404, meta: { layout: 'default' } }
        ]
    }
]

export default routes
