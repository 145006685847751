<template>
    <component
        :is="'li'"
        class="CartItem"
    >
        <div class="CartItem-labelAndQuantity">
            <div class="CartItem-label">
                <span class="CartItem-quantity">{{ item.quantity }}x </span>
                {{ item.name }}
                <v-tooltip
                    v-if="item.deliveryMode === 'CARDREADER_MANDATORY'"
                    location="bottom"
                >
                    <template #activator="{ props }">
                        <icomoon-icon
                            v-bind="props"
                            :layersCount="3"
                            name="CardReader"
                            class="CartItem-cardReaderMandatoryIcon"
                        />
                    </template>
                    <span>{{ $t('add_to_cart_flow:card_reader_mandatory') }}</span>
                </v-tooltip>
            </div>
            <p class="CartItem-providerNetworkLabel">
                {{ item.providerNetworkLabel }}
            </p>
            <template v-if="shouldShowActions">
                <router-link
                    v-for="(action, index) in actions"
                    :key="item.id + '-' + index"
                    :to="action.route"
                    class="CartItem-action mb-1"
                >
                    {{ action.label }}
                </router-link>
            </template>
            <div
                v-if="item.providerItemDescription !== ''"
                class="CartItem-providerDescription"
                v-html="$sanitize(formattedProviderItemDescription)"
            />
        </div>
        <span class="CartItem-fareAndDelete">
            <formatted-fare
                v-if="item.totalFareIncludingTaxes > 0"
                :currency="item.currency"
                :fare="item.totalFareIncludingTaxes"
                :size="'XSmall'"
                class="CartItem-fare"
            />
            <p
                v-else
                class="CartItem-free"
            >
                {{ $t('catalog:free') }}
            </p>
            <a
                v-if="isEditable"
                class="CartItem-delete"
                @click="deleteLine"
            >
                {{ $t('button:delete') }}
            </a>
        </span>
    </component>
</template>

<script setup>
import FormattedFare from '@/StoreWeb/components/common/FormattedFare'
import * as cartActionTypes from '@/StoreWeb/store/modules/cart/action-types'
import { computed } from 'vue'
import { useStore } from 'vuex'
import { cartLineDeletedHandler } from '@/StoreWeb/js/composables/cart-utils'
import IcomoonIcon from '@/StoreWeb/components/common/IcomoonIcon'

const store = useStore()

const componentProps = defineProps({
    actions: {
        type: Array,
        default () { return [] }
    },
    isEditable: {
        type: Boolean,
        required: false,
        default: false
    },
    item: {
        type: Object,
        required: true
    }
})

const cart = computed(() => store.state.cartModule.cart)

const shouldShowActions = computed(() => {
    return componentProps.isEditable && componentProps.actions.length > 0
})

const formattedProviderItemDescription = computed(() => {
    if (!componentProps.item.providerItemDescription) return ''
    return componentProps.item.providerItemDescription.replace(/\n/g, '<br>')
})

async function deleteLine () {
    const isCartLineDeleted = await store.dispatch(cartActionTypes.DELETE_CART_LINE, {
        orderId: cart.value.id,
        line: componentProps.item.line,
        type: 'MODALPRODUCT'
    })

    if (isCartLineDeleted) {
        await cartLineDeletedHandler()
    }
}
</script>

<style lang="scss" scoped>
@import 'globalScss';

.CartItem {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
    padding: 15px 0;
    border-top: 1px solid $color-lightgray2;

    &:first-child {
        padding-top: 0;
        border-top: none;
    }

    &-labelAndQuantity {
        padding-right: 5px;
    }

    &-label {
        display: flex;
        align-items: baseline;
        gap: $s1;
        font-size: $font-xsmall;
        font-weight: $fontWeight-defaultMedium;
        color: $color-lightText;
    }

    &-cardReaderMandatoryIcon {
        align-self: center;
        font-size: $font-small;
        color: $color-brandSecondary;
    }

    &-providerDescription {
        font-size: $font-xxsmall;
        color: $color-black-ultraLight;
    }

    &-providerNetworkLabel {
        font-size: $font-xsmall;
        font-weight: $fontWeight-defaultMedium;
        color: $color-lightText;
    }

    &-quantity {
        font-size: $font-xxsmall;
        font-weight: bold;
        color: $color-brandPrimary;
    }

    &-action {
        font-size: $font-xxsmall;
    }

    &-fareAndDelete {
        flex-grow: 1;
        text-align: right;
    }

    &-fare {
        display: block;
        color: $color-defaultText;
    }

    &-free {
        margin: 0;
        font-weight: bold;
    }

    &-delete {
        cursor: pointer;
        font-size: $font-xxsmall;
        color: $color-brandPrimary;
        transition: all .3s;

        &:hover,
        &:focus {
            color: $color-brandPrimaryDark;
        }
    }
}
</style>
