<template>
    <div
        v-show="shouldShowFiltersSidebar"
        class="ProductFilterList"
    >
        <div class="d-flex justify-space-between align-center mb-4">
            <p class="h2 ma-0 mr-6">
                {{ t('catalog:filter:title') }}
            </p>
            <v-btn
                color="primary"
                variant="text"
                @click="clearAllFilters"
            >
                {{ t('button:reset') }}
            </v-btn>
        </div>
        <div
            v-for="filter in searchDisplayModeFilters"
            :key="`filter-${filter.id}`"
            class="ProductFilterList-filter mb-8"
        >
            <p class="h3">
                {{ t('catalog:filter:search:title') }}
            </p>
            <v-text-field
                v-model="filter.value"
                :label="t('catalog:filter:search:label')"
                :placeholder="t('catalog:filter:search:placeholder')"
                clearable
                variant="underlined"
                @update:model-value="searchInProducts"
            />
        </div>
        <div
            v-for="filter in buttonDisplayModeFilters"
            :key="`filter-${filter.id}`"
            class="ProductFilterList-filter mb-8"
        >
            <p class="h3">
                {{ filter.name }}
            </p>
            <div
                v-if="filter.displayMode === 'BUTTON'"
                class="ProductFilterList-filterOptionList d-flex flex-wrap justify-start"
            >
                <v-checkbox
                    v-for="option in filter.filters"
                    :key="`option-${option.id}`"
                    v-model="filter.value"
                    :class="{ 'ProductFilterList-filterOptionItem--active': filter.value === option.id }"
                    :label="option.name"
                    :value="option.id"
                    :hide-details="true"
                    class="ProductFilterList-filterOptionItem--button ma-0 pt-0 rounded-xl elevation-2"
                    @update:model-value="onFilterChanged"
                >
                    <template #label>
                        <div class="py-3 px-4">
                            {{ option.name }}
                        </div>
                    </template>
                </v-checkbox>
            </div>
            <div
                v-if="filter.displayMode === 'BUTTON_WITH_ICON'"
                class="ProductFilterList-filterOptionList d-flex flex-wrap justify-start"
            >
                <v-checkbox
                    v-for="option in filter.filters"
                    :key="`option-${option.id}`"
                    v-model="filter.value"
                    :class="{ 'ProductFilterList-filterOptionItem--active': filter.value === option.id }"
                    :label="option.name"
                    :value="option.id"
                    class="ProductFilterList-filterOptionItem--buttonWithIcon ma-0 pt-0 rounded-xl elevation-2"
                    density="compact"
                    @update:model-value="onFilterChanged"
                >
                    <template #label>
                        <div class="ProductFilterList-filterOptionItemLabelContent d-flex flex-column justify-center align-center py-3 px-4 px-lg-1 px-xl-4">
                            <v-icon
                                v-if="option.icon !== null"
                                :icon="option.icon"
                            />
                            {{ option.name }}
                        </div>
                    </template>
                </v-checkbox>
            </div>
        </div>
        <div
            v-for="filter in otherDisplayModeFilters"
            :key="`filter-${filter.id}`"
            class="ProductFilterList-filter mb-8"
        >
            <p class="h3">
                {{ filter.name }}
            </p>
            <v-select
                v-if="filter.displayMode === 'SELECT'"
                v-model="filter.value"
                :items="getFormattedChoices(filter.filters)"
                item-title="text"
                item-value="value"
                :label="t('catalog:filter:choose')"
                class="ProductFilterList-filterSelect"
                clearable
                density="compact"
                :hide-details="true"
                @update:model-value="onFilterChanged"
            />
            <div
                v-else
                class="ProductFilterList-filterOptionList ProductFilterList-filterOptionList--checkbox d-flex flex-column flex-wrap justify-start"
            >
                <v-checkbox
                    v-for="option in filter.filters"
                    :key="`option-${option.id}`"
                    v-model="filter.value"
                    :label="option.name"
                    :value="option.id"
                    :hide-details="true"
                    density="compact"
                    class="ProductFilterList-filterOptionItem ma-0 pa-0"
                    @update:model-value="onFilterChanged"
                />
            </div>
        </div>
    </div>
</template>

<script setup>
import { computed, onBeforeMount, ref } from 'vue'
import { useStore } from 'vuex'
import { useCatelogUrlFilters } from '@/StoreWeb/js/composables/catalog-filters'
import { useI18n } from 'vue-i18n'
import { useDisplay } from 'vuetify'

defineProps({
    shouldShowFiltersSidebar: {
        type: Boolean,
        default: false
    }
})

const { lgAndUp } = useDisplay()
const store = useStore()
const { t } = useI18n()

let searchInProductsTimeout = null

const checkboxListFilterPanels = ref({})
const { applyFiltersFromQueryParams, resetFilters, updateFilters, appliedFilters } = useCatelogUrlFilters()

const getFilters = computed(() => store.state.catalogModule.filters)
const buttonDisplayModeFilters = computed(() => appliedFilters.value.filter(item => ['BUTTON', 'BUTTON_WITH_ICON'].includes(item.displayMode)))
const checkboxDisplayModeFilters = computed(() => appliedFilters.value.filter(item => !['BUTTON', 'BUTTON_WITH_ICON', 'SEARCH', 'SELECT'].includes(item.displayMode)))
const otherDisplayModeFilters = computed(() => appliedFilters.value.filter(item => !['BUTTON', 'BUTTON_WITH_ICON', 'SEARCH'].includes(item.displayMode)))
const searchDisplayModeFilters = computed(() => appliedFilters.value.filter(item => item.displayMode === 'SEARCH'))

function onFilterChanged () {
    if (lgAndUp.value) {
        updateFilters()
    }
}

function clearAllFilters () {
    resetFilters()
}

function getFormattedChoices (array) {
    return array.map(item => ({
        text: item.name,
        value: item.id
    }))
}

function searchInProducts () {
    clearTimeout(searchInProductsTimeout)

    searchInProductsTimeout = setTimeout(() => {
        onFilterChanged()
    }, 300)
}

onBeforeMount(() => {
    applyFiltersFromQueryParams(getFilters)
    for (const filter of checkboxDisplayModeFilters.value) {
        checkboxListFilterPanels.value[filter.id] = 0
    }
})
</script>

<style lang="scss" scoped>
@import 'globalScss';

.ProductFilterList {
    &-filterOptionList {
        gap: $s3;

        :deep(.v-messages) {
            display: none;
        }
    }

    &-expansionPanels {
        :deep(.v-expansion-panel-header) {
            min-height: 32px;
        }

        :deep(.v-expansion-panel-content__wrap) {
            padding: 0;
        }
    }

    &-filterOptionList--checkbox {
        gap: $s5;

        :deep(.v-input__slot) {
            margin: 0;
        }
    }

    &-filterOptionItem--button,
    &-filterOptionItem--buttonWithIcon {
        position: relative;
        overflow: hidden;
        font-weight: normal;

        :deep(.v-selection-control__input),
        :deep(.v-selection-control__wrapper) {
            width: 1px;
            height: 1px;

            .v-icon {
                font-size: 0;
            }
        }

        :deep(.v-input__control),
        :deep(.v-input__slot) {
            display: block;
            min-height: 0;
            margin: 0;
        }

        :deep(.v-label) {
            width: 100%;
        }

        :deep(.v-icon) {
            font-size: 48px;
        }

        &.ProductFilterList-filterOptionItem--active {
            background: $button-primary-backgroundColor;

            :deep(.v-label),
            :deep(.v-icon) {
                color: $button-primary-foregroundColor;
                opacity: 1;
            }
        }
    }

    &-filterOptionItem--buttonWithIcon {
        min-width: calc((100% - 24px) / 3);

        @media #{map-get($display-breakpoints, 'sm-and-up')} {
            min-width: 116px;
        }

        @media #{map-get($display-breakpoints, 'lg-and-up')} {
            min-width: calc((100% - 24px) / 3);
        }

        .ProductFilterList-filterOptionItemLabelContent {
            gap: $s2;
            width: 100%;
        }
    }

    :deep(.v-select__selection) {
        font-size: 14px;
        white-space: normal;
    }

    &-buttonContainer {
        width: 100%;
        text-align: right;

        @media #{map-get($display-breakpoints, 'lg-and-up')} {
            display: none;
        }
    }
}
</style>
