<template>
    <div class="CartContent">
        <cart-basket
            v-for="basket in baskets"
            :key="basket.fareMediaId"
            v-bind="basket"
            :isEditable="isEditable"
            class="CartContent-basket"
        />
        <ul
            v-if="packages.length > 0 || plans.length > 0"
            class="CartContent-list"
        >
            <cart-item
                v-for="cartItem in packages"
                v-bind="cartItem"
                :key="cartItem.id"
                :isEditable="isEditable"
                class="CartContent-item"
            />
            <cart-item
                v-for="cartItem in plans"
                v-bind="cartItem"
                :key="cartItem.id"
                :isEditable="isEditable"
                class="CartContent-item"
            />
        </ul>
        <div class="CartContent-total">
            <span class="CartContent-totalLabel">{{ $t('cart:total_to_settle') }}</span>
            <formatted-fare
                :currency="'EUR'"
                :fare="cartTotal"
                :size="'Small'"
                class="CartContent-totalFare"
            />
        </div>
        <div
            v-if="shouldDisplayBothPrices"
            class="CartContent-total"
        >
            <span class="CartContent-totalLabel">{{ $t('cart:total_to_settle_immediately') }}</span>
            <formatted-fare
                :currency="'EUR'"
                :fare="cartImmediateTotal"
                :size="'Small'"
                class="CartContent-totalFare"
            />
        </div>
    </div>
</template>

<script setup>
import { computed } from 'vue'
import CartBasket from './CartBasket'
import CartItem from './CartItem'
import FormattedFare from '@/StoreWeb/components/common/FormattedFare'
import { useCart } from '@/StoreWeb/js/composables/use-cart'

const componentProps = defineProps({
    isEditable: {
        type: Boolean,
        default: false
    },
    showBothPricesIfPossible: {
        type: Boolean,
        default: false
    }
})

const { baskets, packages, plans, cartTotal, cartImmediateTotal, hasDifferentImmediateAndTotalAmount } = useCart()

const shouldDisplayBothPrices = computed(() => {
    return componentProps.showBothPricesIfPossible && hasDifferentImmediateAndTotalAmount.value
})
</script>

<style lang='scss' scoped>
@import 'globalScss';

.CartContent {
    &-list {
        margin: 0;
        padding: 15px 0 0;
        border-top: 1px solid $color-lightgray2;
        list-style: none;
    }

    &:first-child {
        :deep(.CartBasket:first-child .CartItem:first-child) {
            border-top: none;
        }
    }

    &-total {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: $s5;
        padding-top: 10px;
        border-top: 1px solid $color-lightgray2;
    }

    &-totalLabel {
        font-size: 16px;
        font-weight: $fontWeight-defaultMedium;
    }

    &-totalFare {
        flex-grow: 1;
        text-align: right;
        color: $color-defaultText;
    }

    &-removeProducts {
        margin: 20px 0 0;
        font-size: 14px;
        text-align: center;
    }

    &-removeProductsLink {
        cursor: pointer;
        color: $color-gray;
        transition: all .3s;

        &:hover,
        &:focus {
            color: $color-brandPrimary;
        }
    }
}
</style>
