if (!String.prototype.replaceAll) {
    // eslint-disable-next-line no-extend-native
    String.prototype.replaceAll = function (search, replacement) {
        const target = this
        return target.split(search).join(replacement)
    }
}

if (!String.prototype.at) {
    // eslint-disable-next-line no-extend-native
    String.prototype.at = function (index) {
        index = Math.trunc(index) || 0
        if (index < 0) index += this.length
        if (index < 0 || index >= this.length) return undefined
        return this[index]
    }
}

if (!Array.prototype.at) {
    // eslint-disable-next-line no-extend-native
    Array.prototype.at = function (index) {
        index = Math.trunc(index) || 0
        if (index < 0) index += this.length
        if (index < 0 || index >= this.length) return undefined
        return this[index]
    }
}

if (!structuredClone) {
    // eslint-disable-next-line no-global-assign
    structuredClone = function (obj) {
        return JSON.parse(JSON.stringify(obj))
    }
}
