<template>
    <header
        id="HeaderBarSytral"
        ref="headerHtmlElement"
        :class="[(getAuthenticated ? 'HeaderBarSytral--isAuthenticated' : '')]"
        class="HeaderBarSytral"
    >
        <div
            class="HeaderBarSytral-container"
        >
            <router-link
                :title="getAppTitle"
                :to="{ name: 'home' }"
                class="HeaderBarSytral-logoLink"
            >
                <img
                    :src="logo"
                    :alt="getAppTitle"
                    class="HeaderBarSytral-logoImg"
                >
            </router-link>

            <div
                class="HeaderBarSytral-desktopNavBar"
            >
                <nav role="navigation">
                    <ul class="HeaderBarSytral-desktopNavBarNav">
                        <li
                            v-for="(item, itemindex) in headerMenuLinks"
                            :key="itemindex"
                            class="HeaderBarSytral-desktopNavBarItem"
                        >
                            <template v-if="item.subMenu">
                                <a
                                    v-if="item.subMenu"
                                    class="HeaderBarSytral-desktopNavBarItemButton"
                                    :href="item.link ? sanitizeUrl(item.link) : ''"
                                    :to="item.route ?? null"
                                >
                                    {{ item.title }}
                                </a>
                                <div
                                    class="HeaderBarSytral-desktopNavBarItem-InnerMenu"
                                    :class="{stretched: item.illustratedColumn}"
                                >
                                    <template v-if="item.illustratedColumn">
                                        <div class="HeaderBarSytral-destkopNavBarIllustratedColumn">
                                            <ul class="HeaderBarSytral-DesktopNavBarSubMenu">
                                                <li
                                                    v-for="(subItem, subItemIndex) in item.subMenu"
                                                    :key="subItemIndex"
                                                >
                                                    <a
                                                        :href="subItem.link ? sanitizeUrl(subItem.link) : ''"
                                                        :to="subItem.route ?? null"
                                                        class="HeaderBarSytral-subItem--desktop"
                                                    >
                                                        {{ subItem.title }}
                                                        <svg
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            width="20"
                                                            height="17"
                                                            viewBox="0 0 20 17"
                                                            class="HeaderBarSytral-subItemIcon"
                                                        >
                                                            <path
                                                                fill="currentColor"
                                                                fill-rule="evenodd"
                                                                d="M19.984 8.432a1.086 1.086 0 0 0-.303-.752v-.001L12.532.31a.997.997 0 0 0-1.452.005c-.4.42-.397 1.095.005 1.51l5.378 5.543H1.027C.459 7.37 0 7.846 0 8.437c0 .59.459 1.067 1.027 1.067h15.468l-5.414 5.677c-.4.42-.397 1.095.005 1.51a1.002 1.002 0 0 0 1.453-.007l7.143-7.492.002-.002.002-.002c.019-.02.025-.045.041-.065.07-.085.136-.174.178-.278.037-.092.046-.194.057-.294.004-.04.022-.074.022-.114v-.003-.002"
                                                            />
                                                        </svg>
                                                    </a>
                                                </li>
                                            </ul>

                                            <div
                                                class="mapCard"
                                                :style="{backgroundImage: `url(${mapCardImg})`}"
                                            >
                                                <div
                                                    class="mapCard_gradient"
                                                    style="z-index: 1"
                                                />
                                                <v-row
                                                    class="d-inline mb-2 text-center"
                                                    style="z-index: 2"
                                                >
                                                    <v-col
                                                        cols="6"
                                                        class="ma-auto"
                                                        style="z-index: 2"
                                                    >
                                                        <a
                                                            v-if="item.illustratedColumn.firstButton"
                                                            class="HeaderBarSytral-firstButton"
                                                            :href="item.illustratedColumn.firstButton.link ? sanitizeUrl(item.illustratedColumn.firstButton.link) : ''"
                                                            :to="item.illustratedColumn.firstButton.route ?? null"
                                                        >
                                                            {{ item.illustratedColumn.firstButton.title }}
                                                        </a>
                                                    </v-col>
                                                    <v-col
                                                        cols="6"
                                                        class="ma-auto justify-center"
                                                        style="z-index: 2"
                                                    >
                                                        <ul class="HeaderBarSytral-list text--white pa-0">
                                                            <template v-for="(itemIllustratedColumn, indexItemIllustratedColumn) in item.illustratedColumn">
                                                                <li
                                                                    v-if="indexItemIllustratedColumn !== 'firstButton'"
                                                                    :key="indexItemIllustratedColumn"
                                                                    class="d-inline pa-1"
                                                                >
                                                                    <a
                                                                        class="HeaderBarSytral-itemIllustratedColumn"
                                                                        :href="itemIllustratedColumn.link ? sanitizeUrl(itemIllustratedColumn.link) : ''"
                                                                        :to="itemIllustratedColumn.route ?? null"
                                                                    >
                                                                        {{ itemIllustratedColumn.title }}
                                                                    </a>
                                                                </li>
                                                            </template>
                                                        </ul>
                                                    </v-col>
                                                </v-row>
                                            </div>
                                        </div>
                                    </template>
                                    <template v-else>
                                        <div>
                                            <ul class="HeaderBarSytral-DesktopNavBarSubMenu">
                                                <li
                                                    v-for="(subItem, indexSubItem) in item.subMenu"
                                                    :key="indexSubItem"
                                                >
                                                    <a
                                                        class="HeaderBarSytral-subItem--desktop"
                                                        :href="subItem.link ? sanitizeUrl(subItem.link) : ''"
                                                        :to="subItem.route ?? null"
                                                    >
                                                        {{ subItem.title }}
                                                        <svg
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            width="20"
                                                            height="17"
                                                            viewBox="0 0 20 17"
                                                            class="HeaderBarSytral-subItemIcon"
                                                        >
                                                            <path
                                                                fill="currentColor"
                                                                fill-rule="evenodd"
                                                                d="M19.984 8.432a1.086 1.086 0 0 0-.303-.752v-.001L12.532.31a.997.997 0 0 0-1.452.005c-.4.42-.397 1.095.005 1.51l5.378 5.543H1.027C.459 7.37 0 7.846 0 8.437c0 .59.459 1.067 1.027 1.067h15.468l-5.414 5.677c-.4.42-.397 1.095.005 1.51a1.002 1.002 0 0 0 1.453-.007l7.143-7.492.002-.002.002-.002c.019-.02.025-.045.041-.065.07-.085.136-.174.178-.278.037-.092.046-.194.057-.294.004-.04.022-.074.022-.114v-.003-.002"
                                                            />
                                                        </svg>
                                                    </a>
                                                </li>
                                            </ul>
                                        </div>
                                    </template>
                                </div>
                            </template>
                            <a
                                v-else
                                class="HeaderBarSytral-desktopNavBarItemButton"
                                :href="item.link ? sanitizeUrl(item.link) : ''"
                                :to="item.route ?? null"
                            >
                                {{ item.title }}
                            </a>
                        </li>
                    </ul>
                </nav>
            </div>

            <div class="HeaderBarSytral-userMenu">
                <header-notification-list
                    v-if="shouldShowNotifications"
                    class="HeaderBarSytral-notifications"
                />
                <header-cart class="HeaderBarSytral-cart" />
                <header-account
                    :with-rounded-modal="false"
                    class="HeaderBarSytral-account"
                />
                <lang-selector
                    v-if="$vuetify.display.mdAndUp"
                    class="HeaderBarSytral-langSelector"
                />
                <div class="HeaderBarSytral-fakeAccessibilityWidget" />
            </div>
            <button
                class="HeaderBarSytral-mobileMenuButton"
                @click="showMobileMenu = !showMobileMenu"
            >
                {{ $t('menu') }}
            </button>
            <v-dialog
                v-model="showMobileMenu"
                width="auto"
                fullscreen
                attach="#HeaderBarSytral"
                transition="slide-x-transition"
                :opacity="0"
            >
                <div
                    class="HeaderBarSytral-mobileDrawer"
                >
                    <div
                        class="HeaderBarSytral-mobileDrawerHeader"
                    >
                        <div
                            class="HeaderBarSytral-mobileDrawerHeaderUser"
                        >
                            <header-notification-list
                                v-if="shouldShowNotifications"
                                class="HeaderBarSytral-notifications"
                            />
                            <header-cart class="HeaderBarSytral-cart" />
                            <header-account class="HeaderBarSytral-account" />
                        </div>
                        <div
                            class="HeaderBarSytral-mobileDrawerHeaderEnd"
                        >
                            <lang-selector class="HeaderBarSytral-langSelector--mobile" />
                            <button
                                @click="showMobileMenu = !showMobileMenu"
                            >
                                <v-icon icon="mdi-close" />
                            </button>
                        </div>
                    </div>
                    <v-divider class="HeaderBarSytral-insideDivider" />
                    <nav
                        role="navigation"
                    >
                        <ul>
                            <li
                                v-for="item in headerMenuLinks"
                                :key="item.id"
                            >
                                <header-bar-sytral-mobile-submenu
                                    v-if="item.subMenu"
                                    :item="item"
                                    @close="showMobileMenu = false"
                                />

                                <a
                                    v-else
                                    :href="item.link ? sanitizeUrl(item.link) : ''"
                                    :to="item.route ?? null"
                                    class="HeaderBarSytral-mobileDrawerLink"
                                >
                                    {{ item.title }}
                                    <v-icon
                                        size="large"
                                        icon="mdi-arrow-right-thin"
                                        color="#d1d7d7"
                                    />
                                </a>
                            </li>
                        </ul>
                    </nav>
                </div>
            </v-dialog>
        </div>
    </header>
</template>

<script setup>
import { ref, computed, watch, onMounted } from 'vue'
import { useStore } from 'vuex'
import { sanitizeUrl } from '@braintree/sanitize-url'
import HeaderAccount from '@/StoreWeb/components/account/HeaderAccount'
import HeaderNotificationList from '@/StoreWeb/components/account/notification/HeaderNotificationList'
import HeaderCart from '@/StoreWeb/components/cart/HeaderCart'
import LangSelector from '@/StoreWeb/components/common/LangSelector'
import HeaderBarSytralMobileSubmenu from './HeaderBarSytralMobileSubmenu.vue'
import { isEmpty } from 'global-utils'
import config from 'config'
import emitter from 'global-emitter'
import { useMenu } from '@/StoreWeb/js/composables/sytral/use-menu'
import { useElementSize, useDebounceFn } from '@vueuse/core'
import { useRoute } from 'vue-router'

const store = useStore()

const logo = ref(process.env.VUE_APP_NETWORK_ID ? process.env.VUE_APP_CDN_URL + 'img/' + process.env.VUE_APP_NETWORK_ID + '/logo.svg' : process.env.VUE_APP_CDN_URL + 'img/default/logo.svg')
const mapCardImg = ref(process.env.VUE_APP_CDN_URL + 'img/' + process.env.VUE_APP_NETWORK_ID + '/map-card.png')
const showMobileMenu = ref(false)

const authenticated = computed(() => store.state.login.authenticated)

const { headerMenuLinks, loadRemoteMenus } = useMenu()

const getAuthenticated = computed(() => authenticated.value)
const getAppTitle = computed(() => !isEmpty(config.app_title) ? config.app_title : '')

const shouldShowNotifications = computed(() => config.features.notifications?.enabled && getAuthenticated.value)

const headerHtmlElement = ref(null)
const { height } = useElementSize(headerHtmlElement)

const debouncedResize = useDebounceFn(() => {
    resetBodyPadding()
}, 100)

watch([height], debouncedResize)

const route = useRoute()

// This is a way to make sure the main page has the good padding what ever the header contains
// We will watch two things : the header height (which moves when the menu content changes or the page)
// has a different width ; and the route (which moves when the user navigates)
const resetBodyPadding = () => {
    ['.DefaultLayout-body--sytral', '.AccountLayout-body--sytral'].forEach((selector) => {
        const element = document.querySelector(selector)

        if (!element) {
            return
        }

        element.style.paddingTop = height.value + 'px'
    })
}

watch(route, () => {
    // We use a setTimeout to delay the task to the next tick because the route change occurs before
    // the real content changes
    debouncedResize()
})

watch(height, () => {
    debouncedResize()
})

onMounted(async () => {
    await loadRemoteMenus()
})

const showGlobalLoader = computed(() => store.state.showGlobalLoader)

emitter.on('closeCart', () => {
    showMobileMenu.value = false
})

watch(showGlobalLoader, (newValue, oldValue) => {
    if (!newValue.value) {
        return
    }

    showMobileMenu.value = false
})
</script>

<style lang="scss" scoped>
@import 'globalScss';

.HeaderBarSytral {
    display: flex;
    justify-content: center;
    align-items: stretch;
    position: fixed;
    width: 100%;
    max-width: 100vw;
    height: 68px;
    border-bottom: rem-calc(1) solid #e8e8e8;
    box-shadow: 0 0 9px 0 rgba(0, 0, 0, .09);
    background: $color-white;
    -webkit-font-smoothing: unset !important;

    @media (min-width: $desktop-breakpoint) {
        height: auto;
    }

    &-fakeAccessibilityWidget {
        width: 50px;
        margin: 14px 10px;
    }

    &-container {
        display: flex;
        justify-content: space-between;
        align-items: center;
        position: relative;
        width: 100%;
        max-width: none;
        padding: 0 25px;

        @media (min-width: $desktop-breakpoint) {
            justify-content: flex-start;
            max-width: 1300px;
        }
    }

    &-userMenu {
        display: none;

        @media (min-width: $desktop-breakpoint) {
            display: flex;
            align-items: center;
        }
    }

    &-list {
        list-style: none;
        font-weight: 500;
    }

    &-account,
    &-cart,
    &-mobileMenuButton,
    &-notifications {
        border-left: 1px solid $color-lightgray3;

        @media (min-width: $desktop-breakpoint) {
            border-left: none;
        }
    }

    &-account,
    &-cart,
    &-langSelector,
    &-notifications {
        display: inline-flex;
        justify-content: center;
        align-items: center;
        width: 55px;
        height: 100%;

        @media (min-width: $desktop-breakpoint) {
            width: auto;
            min-width: 55px;
        }
    }

    &-account {
        @media (min-width: $desktop-breakpoint) {
            width: auto;
        }
    }

    &-langSelector {
        @media (min-width: $tablet-breakpoint) {
            width: auto;
        }
    }

    :deep(.LangSelector-toggleContent) {
        padding: 14px 18px;
        border-radius: 5px;
        background-color: #f1f5f5;
        color: #707173;
        transition: all .3s;

        &:hover {
            background-color: #e3e4e4;
            color: #58585a;
        }
    }

    :deep(.HeaderAccount-toggleButton) {
        color: #58585a;

        &:hover {
            color: #3a3636;
        }
    }

    &-logoWrapper {
        height: 100%;
    }

    &-logoLink {
        display: flex;
        align-items: center;
        margin: 6px 0;
        padding: 0;

        @media (min-width: $desktop-breakpoint) and (max-width: 1100px) {
            min-width: 70px;
            padding-right: 10px;
        }
    }

    &-logoImg {
        height: 46px;

        @media (min-width: $desktop-breakpoint) {
            height: 70px;
        }
    }

    &-mobileNavBar {
        justify-content: flex-end;
        flex-grow: 1;

        @media (min-width: $desktop-breakpoint) {
            flex-grow: initial;
        }
    }

    &-desktopNavBar {
        display: none;
        height: 100%;
        margin: 0 auto;
        padding: 0 20px;

        @media (min-width: $desktop-breakpoint) {
            display: inline-flex;
            justify-content: space-around;
            flex-grow: 1;
            gap: 10px;
            margin: 0 auto;
            padding: 0 20px;
        }
    }

    &-desktopNavBarNav {
        display: flex;
        justify-content: space-around;
        align-items: center;
        height: 100%;
    }

    &-desktopNavBarItem-InnerMenu {
        display: none;
        position: absolute;
        top: calc(100% - 0px);
        overflow: hidden;
        border-radius: 5px;
        box-shadow: 0 6px 34px 0 rgba(112, 113, 115, .25);
        background-color: white;
    }

    &-desktopNavBarItem-InnerMenu.stretched {
        right: 0;
        left: 0;
    }

    &-desktopNavBarItem {
        display: inline-flex;
        justify-content: center;
        align-items: center;
        height: 100%;
        padding: 0;

        &:hover &-InnerMenu,
        &:focus-within &-InnerMenu {
            display: block;
            animation: menu-open .2s;
        }

        &:hover .HeaderBarSytral-desktopNavBarItemButton::before,
        &:focus-within .HeaderBarSytral-desktopNavBarItemButton::before {
            content: '';
            position: absolute;
            z-index: 1;
            bottom: -2px;
            left: 50%;
            width: 36px;
            height: 5px;
            margin-left: -18px;
            border-radius: 8px / 5px;
            background-color: #e2001a;
        }
    }

    &-desktopNavBarItemMenu {
        height: 100%;
    }

    &-destkopNavBarIllustratedColumn {
        display: flex;
    }

    &-desktopNavBarItemButton {
        display: inline-flex;
        justify-content: center;
        align-items: center;
        position: relative;
        overflow: hidden;
        height: 100%;
        padding: 33px 20px;
        font-weight: 500;
        line-height: 1.3em;
        text-align: center;
        color: #58585a;

        &:hover {
            color: #3a3636;
        }
    }

    &-DesktopNavBarSubMenu {
        display: flex;
        flex-direction: column;
        flex: 0 1 50%;
        padding: 0;
        list-style-type: none;

        li a {
            display: flex;
            align-items: center;
        }
    }

    &-mobileMenuButton {
        height: 100%;
        margin-right: -20px;
        padding: 0 20px;
        font-weight: 500;

        @media (min-width: $desktop-breakpoint) {
            display: none;
        }

        &.v-btn {
            font-size: 1rem;
            color: $color-lighterText;
        }
    }

    &-mobileDrawer {
        width: 100vw;
        height: 100vh;
        background-color: white;
    }

    &-mobileDrawerHeader {
        display: flex;
        justify-content: space-between;
        align-items: center;
        position: relative;
        z-index: 5;
        margin-bottom: -8px;
        padding: 0 16px 0 8px;
    }

    &-mobileDrawerHeaderUser {
        display: flex;
        align-items: center;
        gap: 1rem;
    }

    &-mobileDrawerLink {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        padding: 20px 32px;
        border-bottom: 1px solid #e3e4e4;
        font-size: 18px;
        font-weight: 500;
        color: #58585a;
    }

    &-insideDivider {
        margin-top: 10px;
    }

    &-mobileDrawerHeaderEnd {
        display: flex;
        align-items: center;
        gap: 1em;
    }

    &-mobileDrawerHeaderUser .HeaderBarSytral-notifications,
    &-mobileDrawerHeaderUser .HeaderBarSytral-account,
    &-mobileDrawerHeaderUser .HeaderBarSytral-cart {
        border-left: none;
    }

    &-firstButton {
        padding: 7px 25px;
        border-radius: 35px;
        background-color: white;
        font-size: 1rem;
        font-weight: 500;
        text-align: center;
        color: $primary_color !important;
    }

    &-firstButton:hover {
        border: 1px solid $primary_color;
    }

    &-itemIllustratedColumn {
        font-size: 15px !important;
        font-weight: normal !important;
        color: white;
    }

    &-itemIllustratedColumn:hover {
        border-bottom: 1px solid $primary_color;
    }

    &-firstButton::before {
        background-color: white;
    }

    &-illustratedColumnItem {
        left: 50% !important;
        transform: translateX(-50%);
        width: 1250px;
    }

    &-subItem--desktop {
        display: block;
        min-height: 0;
        padding: 18px 45px;
        border-bottom: 1px solid #e3e4e4;
        font-size: 18px;
        font-weight: 500;
        line-height: 23px;
        color: #58585a;

        &:hover,
        &:focus {
            padding: 18px 50px;
            color: #3a3636;
        }
    }

    &-subItemIcon {
        width: 21px;
        height: 18px;
        margin-left: 20px;
        color: $color_lightgray4;
    }

    &-subItem--desktop:hover .HeaderBarSytral-subItemIcon,
    &-subItem--desktop:focus .HeaderBarSytral-subItemIcon {
        color: #e2001a;
    }
}

.v-list-item__title {
    font-size: 18px !important;
}

.v-menu__content {
    overflow-y: hidden !important;
}

.mapCard {
    display: flex;
    align-items: flex-end;
    flex: 0 1 50%;
    position: relative;
    overflow: hidden;
    width: 100%;
    height: auto;
    min-height: 100%;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;

    &_gradient {
        position: absolute;
        z-index: 1;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background:
            linear-gradient(
                to top,
                rgba(0, 0, 0, .9) 0,
                rgba(0, 0, 0, .738) 19%,
                rgba(0, 0, 0, .541) 34%,
                rgba(0, 0, 0, .382) 47%,
                rgba(0, 0, 0, .278) 56.5%,
                rgba(0, 0, 0, .194) 65%,
                rgba(0, 0, 0, .126) 73%,
                rgba(0, 0, 0, .075) 80.2%,
                rgba(0, 0, 0, .042) 86.1%,
                rgba(0, 0, 0, .021) 90%,
                rgba(0, 0, 0, .008) 90%,
                rgba(0, 0, 0, .002) 90%,
                rgba(0, 0, 0, 0) 90%
            ) no-repeat bottom center;
        background-size: 100% 90%;
        opacity: .8;
    }
}

:deep(.v-list.v-sheet) {
    position: relative;
    z-index: 4;
}

:deep(.v-toolbar.v-sheet) {
    position: relative;
    z-index: 5;
}

:deep(.HeaderBarSytral-toolbar) > .v-toolbar__content {
    display: flex;
    justify-content: space-between;
    height: 100%;
    padding: 0;

    @media (min-width: $tablet-breakpoint) {
        gap: 20px;
    }

    @media (min-width: $desktop-breakpoint) and (max-width: 1100px) {
        gap: 10px;
    }
}

@keyframes menu-open {
    0% {
        top: calc(100% - 40px);
        opacity: 0;
    }

    100% {
        top: calc(100% - 0px);
        opacity: 1;
    }
}
</style>
