import { isEmpty } from 'global-utils'
import config from 'config'

export default class {
    constructor () {
        this._store = null
    }

    get store () {
        return this._store
    }

    set store (store) {
        this._store = store
    }

    convertBase64ForProvider (base64URL) {
        let convertedURL = ''
        const segment = 'base64,'
        const index = base64URL.indexOf(segment)
        if (index >= 0) {
            convertedURL = base64URL.slice(index + segment.length)
        }
        /* eslint-disable no-useless-escape */
        convertedURL = convertedURL.replaceAll('/', '\/')
        /* eslint-enable no-useless-escape */

        return convertedURL
    }

    getFormattedSaleOptionBase (saleOption) {
        const formattedSaleOptionBase = {}

        if (!isEmpty(saleOption)) {
            if (!isEmpty(saleOption.target)) {
                formattedSaleOptionBase.target = saleOption.target.id
            }
            if (!isEmpty(saleOption.basketInfo)) {
                formattedSaleOptionBase.basketInfo = saleOption.basketInfo.id
            }
            if (!isEmpty(saleOption.deliveryMode)) {
                formattedSaleOptionBase.deliveryMode = saleOption.deliveryMode.id
            }
            if (!isEmpty(saleOption.provider)) {
                formattedSaleOptionBase.providerId = saleOption.provider.id
            }
            if (!isEmpty(saleOption.deviceId)) {
                formattedSaleOptionBase.deviceId = saleOption.deviceId
            }
            if (!isEmpty(saleOption.deviceLabel)) {
                formattedSaleOptionBase.deviceLabel = saleOption.deviceLabel
            }
        }

        return formattedSaleOptionBase
    }

    getAddToCartBody ({ orderId, data }) {
        const body = {
            orderId,
            items: []
        }
        body.items.push(this.getAddToCartItemConfig({
            flow: data
        }))
        return body
    }

    getCartConfirmBody ({ orderId, data }) {
        const body = {
            orderId,
            addresses: []
        }
        let address
        if (this.store.state.userModule.isAuthenticated) {
            address = data.billingAddress.getValues()
            address.type = 'BILLING'
            body.addresses.push(address)
        } else {
            let email = localStorage.getItem('cartEmail')
            email = !isEmpty(email) ? email : ''
            address = {
                type: 'BILLING',
                email
            }
            body.addresses.push(address)
        }

        if (!isEmpty(data.deliveryClickAndCollectAddress)) {
            body.addresses.push(data.deliveryClickAndCollectAddress)
        } else if (!isEmpty(data.deliveryAddress)) {
            address = data.deliveryAddress.getValues()
            address.type = 'DELIVERY'
            body.addresses.push(address)
        }

        if (!isEmpty(data.paymentInfos)) {
            body.paymentInfos = data.paymentInfos
        }

        return body
    }

    getAddToCartItemConfig ({ flow, itemConfig }) {
        if (typeof itemConfig === 'undefined') {
            itemConfig = {}
        }
        if (
            typeof flow[0].modalType === 'undefined' &&
            !isEmpty(flow[0].steps) &&
            flow[0].steps[0].product.modalType === 'MODALPACKAGE'
        ) {
            itemConfig = this.getModalPackageConfig(flow[0].steps)
        } else {
            for (let i = 0, count = flow.length; i < count; i++) {
                if (!isEmpty(flow[i])) {
                    const flowStep = flow[i]
                    if (typeof flowStep.product === 'undefined' || flowStep.type === 'dynamic') {
                        itemConfig = this.getAddToCartItemConfig({
                            flow: flowStep.steps,
                            itemConfig
                        })
                    } else {
                        switch (flowStep.product.modalType) {
                            case 'MODALPRODUCT':
                                itemConfig = this.getModalProductConfig({
                                    itemConfig,
                                    flowStep
                                })
                                break
                            case 'MODALPLAN':
                                itemConfig = this.getModalPlanConfig({
                                    itemConfig,
                                    flowStep
                                })
                                break
                            case 'MODALPACKAGE':
                                itemConfig = this.getModalPackageConfig(flow)
                                break
                            case 'MODALPRODUCTGROUP':
                                itemConfig = this.getModalProductGroupConfig({
                                    itemConfig,
                                    flowStep
                                })
                                break
                        }
                    }
                }
            }
        }

        return itemConfig
    }

    getModalProductConfig ({ itemConfig, flowStep }) {
        if (isEmpty(itemConfig)) {
            itemConfig = {
                type: flowStep.product.modalType,
                id: flowStep.product.id,
                version: flowStep.product.version,
                saleableUnit: flowStep.product.saleableUnit.id,
                quantity: flowStep.product.quantity,
                documents: [],
                parameters: []
            }
        } else {
            itemConfig.type = flowStep.product.modalType
            itemConfig.id = flowStep.product.id
            itemConfig.version = flowStep.product.version
            itemConfig.saleableUnit = flowStep.product.saleableUnit.id
            if (isEmpty(itemConfig.quantity)) {
                itemConfig.quantity = flowStep.product.quantity
            }
            if (typeof itemConfig.documents === 'undefined') {
                itemConfig.documents = []
            }
            if (typeof itemConfig.parameters === 'undefined') {
                itemConfig.parameters = []
            }
        }

        if (flowStep.product?.expressPurchase) {
            itemConfig.expressPurchase = flowStep.product.expressPurchase
        }

        this.applyPropertiesRecursively(itemConfig, flowStep)
        return itemConfig
    }

    getModalProductGroupConfig ({ itemConfig, flowStep }) {
        itemConfig.type = flowStep.selectedVariation.modalType
        itemConfig.id = flowStep.selectedVariation.id
        itemConfig.version = flowStep.selectedVariation.version
        itemConfig.saleableUnit = flowStep.selectedVariation.saleableUnit.id
        itemConfig.quantity = flowStep.selectedVariation.quantity
        itemConfig.documents = []
        itemConfig.parameters = []

        this.applyPropertiesRecursively(itemConfig, flowStep)
        return itemConfig
    }

    applyPropertiesRecursively (itemConfig, flowStep) {
        if (itemConfig.expressPurchase) {
            this.setItemProperties({ itemConfig, flowStep })
            return
        }

        if (!isEmpty(flowStep.steps)) {
            for (const step of flowStep.steps) {
                if (!isEmpty(step)) {
                    this.applyPropertiesRecursively(itemConfig, step)
                }
            }
        } else {
            this.setItemProperties({ itemConfig, flowStep })
        }
    }

    getModalPlanConfig ({ itemConfig, flowStep }) {
        itemConfig.documents = []
        itemConfig.id = flowStep.product.id
        itemConfig.items = []
        itemConfig.parameters = []
        itemConfig.quantity = flowStep.product.quantity
        itemConfig.saleableUnit = flowStep.product.saleableUnit.id
        itemConfig.type = flowStep.product.modalType

        itemConfig.userId = config.use_web_harmony
            ? this.store.state.userModule.userInfos.externalUserId
            : this.store.state.login.userInfos.externalUserId
        itemConfig.version = flowStep.product.version

        this.applyPropertiesRecursively(itemConfig, flowStep)

        return itemConfig
    }

    getModalPackageConfig (flow) {
        const product = flow[0].product
        const itemConfig = {
            documents: [],
            id: product.id,
            items: [],
            parameters: [],
            quantity: product.quantity,
            saleableUnit: product.saleableUnit.id,
            type: product.modalType,
            version: product.version
        }
        for (let i = 0, count = flow.length; i < count; i++) {
            if (!isEmpty(flow[i])) {
                if (flow[i].type === 'subProductFlow') {
                    const flowStep = flow[i]
                    let subItemConfig = {
                        documents: [],
                        id: flowStep.subProduct.id,
                        items: [],
                        parameters: [],
                        quantity: flowStep.subProduct.quantity,
                        saleableUnit: flowStep.subProduct.saleableUnit.id,
                        type: flowStep.subProduct.modalType,
                        version: flowStep.subProduct.version
                    }

                    if (!isEmpty(flowStep.steps)) {
                        subItemConfig = this.getAddToCartItemConfig({
                            flow: flowStep.steps,
                            itemConfig: subItemConfig
                        })
                    }
                    itemConfig.items.push(subItemConfig)
                } else if (itemConfig.id === flow[i].product.id) {
                    this.setItemProperties({
                        itemConfig,
                        flowStep: flow[i]
                    })
                }
            }
        }

        return itemConfig
    }

    getFormattedDocuments (documents) {
        const formattedDocuments = []
        documents.forEach(document => {
            if (!isEmpty(document.uploadedFileId)) {
                formattedDocuments.push({
                    documentId: document.id,
                    uploadedFileId: document.uploadedFileId
                })
            } else {
                formattedDocuments.push({
                    data: document.base64String,
                    name: document.label,
                    type: document.id
                })
            }
        })
        return formattedDocuments
    }

    isAssociatedProviderUser (providerId, providerUserExternalId) {
        return this.store.getters.getWalletAssociatedProviderAccounts.some(
            providerAccount => providerAccount.provider === providerId &&
            providerAccount.providerUserExternalId === providerUserExternalId
        )
    }

    getMainAccountProviderUser (providerId) {
        const firstCorrespondingAccount = this.store.getters.getWalletProviderAccounts.find(providerAccount => providerAccount.provider === providerId)

        if (!isEmpty(firstCorrespondingAccount)) {
            return firstCorrespondingAccount
        }

        return null
    }

    setItemProperties ({ itemConfig, flowStep }) {
        switch (flowStep.type) {
            case 'quantity':
                itemConfig.quantity = flowStep.selectedQuantity
                break
            case 'documents':
                itemConfig.documents = this.getFormattedDocuments(flowStep.providedDocuments)
                break
            case 'productParameter':
                flowStep.productParameterValues.forEach(productParameter => {
                    itemConfig.parameters.push(productParameter)
                })
                break
            case 'saleOption':
                itemConfig.saleOption = this.getFormattedSaleOptionBase(flowStep.selectedSaleOption)
                if (!isEmpty(flowStep.selectedSaleOptionParameters?.providerId)) {
                    itemConfig.saleOption.providerId = flowStep.selectedSaleOptionParameters.providerId
                } else if (!isEmpty(flowStep.selectedSaleOption?.provider)) {
                    itemConfig.saleOption.providerId = flowStep.selectedSaleOption.provider.id
                }
                if (!isEmpty(flowStep.selectedSaleOptionParameters?.providerUserId)) {
                    itemConfig.saleOption.providerUserId = flowStep.selectedSaleOptionParameters.providerUserId
                }
                if (!isEmpty(flowStep.selectedSaleOptionParameters?.providerUserFirstName)) {
                    itemConfig.saleOption.providerUserFirstName = flowStep.selectedSaleOptionParameters.providerUserFirstName
                }
                if (!isEmpty(flowStep.selectedSaleOptionParameters?.providerUserLastName)) {
                    itemConfig.saleOption.providerUserLastName = flowStep.selectedSaleOptionParameters.providerUserLastName
                }
                if (!isEmpty(flowStep.selectedSaleOptionParameters?.providerUserExternalId)) {
                    itemConfig.saleOption.providerUserExternalId = flowStep.selectedSaleOptionParameters.providerUserExternalId

                    if (this.isAssociatedProviderUser(flowStep.selectedSaleOptionParameters.providerId, flowStep.selectedSaleOptionParameters.providerUserExternalId)) {
                        const mainAccountProviderUser = this.getMainAccountProviderUser(flowStep.selectedSaleOptionParameters.providerId)

                        if (mainAccountProviderUser.id) {
                            itemConfig.saleOption.providerUserId = mainAccountProviderUser.id
                        }
                        if (mainAccountProviderUser.providerUserExternalId) {
                            itemConfig.saleOption.providerUserExternalId = mainAccountProviderUser.providerUserExternalId
                        }
                    }
                }
                if (!isEmpty(flowStep.selectedSaleOptionParameters?.fareMediaId)) {
                    itemConfig.saleOption.fareMediaId = flowStep.selectedSaleOptionParameters.fareMediaId
                } else if (!isEmpty(flowStep.selectedSaleOption?.faremedia)) {
                    itemConfig.saleOption.fareMediaId = flowStep.selectedSaleOption.faremedia.id
                }
                if (!isEmpty(flowStep.selectedSaleOptionParameters?.deviceId)) {
                    itemConfig.saleOption.deviceId = flowStep.selectedSaleOptionParameters.deviceId
                }
                if (!isEmpty(flowStep.selectedSaleOptionParameters?.deviceLabel)) {
                    itemConfig.saleOption.deviceLabel = flowStep.selectedSaleOptionParameters.deviceLabel
                }
                if (
                    flowStep?.selectedSaleOptionParameters?.recipientUser?.associationId ||
                    flowStep?.selectedSaleOptionParameters?.recipientUser?.associationType
                ) {
                    itemConfig.recipientUser = flowStep.selectedSaleOptionParameters.recipientUser.getAddToCartBody()
                }
                // NB : EMAIL basketInfo does not need any params as email is already provided for EMAILMANDATORY userAuthMode and for USERACCOUNT userAuthMode, it is retrievable using the userToken
                break
            case 'subProductFlow':
                break
            case 'variation':
                itemConfig.documents = []
                itemConfig.id = flowStep.selectedVariation.id
                itemConfig.items = []
                itemConfig.parameters = []
                itemConfig.quantity = flowStep.selectedVariation.quantity
                itemConfig.saleableUnit = flowStep.selectedVariation.saleableUnit.id
                itemConfig.type = flowStep.selectedVariation.modalType
                itemConfig.version = flowStep.selectedVariation.version
                break
            case 'dynamic':
                switch (flowStep.product.modalType) {
                    case 'MODALPRODUCT':
                        this.getModalProductConfig({
                            itemConfig,
                            flowStep
                        })
                        break
                    case 'MODALPLAN':
                        this.getModalPlanConfig({
                            itemConfig,
                            flowStep
                        })
                        break
                    case 'MODALPACKAGE':
                        this.getModalPackageConfig(flowStep)
                        break
                    case 'MODALPRODUCTGROUP':
                        this.getModalProductGroupConfig({
                            itemConfig,
                            flowStep
                        })
                        break
                }
                break
        }
    }
}
