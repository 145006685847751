import i18n from 'i18n'

/**
 * Return true if value is '' or [] or {} or null or undefined (false otherwise)
 * @param value
 * @returns {boolean}
 */
export const isEmpty = (value) => {
    if (typeof (value) === 'number' || typeof (value) === 'boolean') {
        return false
    }
    if (typeof (value) === 'undefined' || value === null) {
        return true
    }
    if (typeof (value.length) !== 'undefined') {
        return value.length === 0
    }
    if (value.constructor === Object && Object.keys(value).length === 0) {
        return true
    }
    let count = 0
    for (const i in value) {
        if (Object.prototype.hasOwnProperty.call(value, i)) {
            count++
        }
    }
    return count === 0
}

export const isEmptyTranslation = (translationKey) => {
    return !(i18n.global.t(translationKey) !== '' && i18n.global.t(translationKey) !== translationKey)
}

/**
 * Determine whether the given value is iterable, excluding strings.
 *
 * @returns {boolean}
 */
export const isIterable = (value) => {
    if (value === null || value === undefined) {
        return false
    }

    return typeof value !== 'string' && (typeof value[Symbol.iterator] === 'function' || typeof value === 'object')
}

/**
 * Return Array modified
 * @param arr
 * @param oldIndex
 * @param newIndex
 * @returns {array}
 */
export const moveArrayItemToNewIndex = (arr, oldIndex, newIndex) => {
    if (newIndex >= arr.length) {
        let k = newIndex - arr.length + 1
        while (k--) {
            arr.push(undefined)
        }
    }
    arr.splice(newIndex, 0, arr.splice(oldIndex, 1)[0])
    return arr
}

/**
 * Get element offset relative to window
 * @param element
 * @returns {{top: number, left: number}}
 */
export const getOffset = (element) => {
    const rect = element.getBoundingClientRect()
    const scrollLeft = window.pageXOffset || document.documentElement.scrollLeft
    const scrollTop = window.pageYOffset || document.documentElement.scrollTop
    return {
        top: rect.top + scrollTop,
        left: rect.left + scrollLeft
    }
}

/**
 * Get available space information for showing an element attached to an other (eg. dropdown menu, contextual menu, ...)
 * @param element
 * @param referenceElement
 * @returns {{top: number, left: number, bottom: number, right: number, availability: {leftTop: boolean, rightBottom: boolean, leftBottom: boolean, rightTop: boolean}}}
 */
export const getAvailableSpaceInfo = (element, referenceElement) => {
    const offset = getOffset(referenceElement)
    const leftSpace = offset.left
    const rightSpace = document.documentElement.clientWidth - offset.left - referenceElement.offsetWidth
    const topSpace = offset.top
    const bottomSpace = document.documentElement.clientHeight - offset.top - referenceElement.offsetHeight
    const elementWidth = element.offsetWidth
    const elementHeight = element.offsetHeight

    return {
        top: offset.top + referenceElement.offsetHeight,
        right: rightSpace,
        bottom: bottomSpace + referenceElement.offsetHeight,
        left: offset.left,
        availability: {
            rightBottom: (bottomSpace >= elementHeight && rightSpace >= elementWidth),
            leftBottom: (bottomSpace >= elementHeight && leftSpace >= elementWidth),
            rightTop: (topSpace >= elementHeight && rightSpace >= elementWidth),
            leftTop: (topSpace >= elementHeight && leftSpace >= elementWidth)
        }
    }
}

/**
 * Set element position depending on display preferences
 * @param element
 * @param referenceElement
 * @param preferences
 */
export const setElementPosition = (element, referenceElement, preferences) => {
    const availableSpaceInfos = getAvailableSpaceInfo(element, referenceElement)
    let fixedPosition = false

    if (element.style.position === 'fixed') {
        fixedPosition = true
    }

    if (typeof preferences === 'undefined') {
        preferences = ['rightBottom', 'leftBottom', 'rightTop', 'leftTop']
    }

    for (const i in preferences) {
        switch (preferences[i]) {
            case 'rightBottom':
                if (availableSpaceInfos.availability.rightBottom) {
                    element.style.left = availableSpaceInfos.left + 'px'
                    element.style.right = 'auto'
                    element.style.top = (availableSpaceInfos.top - (fixedPosition ? window.scrollY : 0)) + 'px'
                    element.style.bottom = 'auto'
                    return
                }
                break
            case 'leftBottom':
                if (availableSpaceInfos.availability.leftBottom) {
                    element.style.left = 'auto'
                    element.style.right = availableSpaceInfos.right + 'px'
                    element.style.top = (availableSpaceInfos.top - (fixedPosition ? window.scrollY : 0)) + 'px'
                    element.style.bottom = 'auto'
                    return
                }
                break
            case 'rightTop':
                if (availableSpaceInfos.availability.rightTop) {
                    element.style.left = availableSpaceInfos.left + 'px'
                    element.style.right = 'auto'
                    element.style.top = 'auto'
                    element.style.bottom = (availableSpaceInfos.bottom + (fixedPosition ? window.scrollY : 0)) + 'px'
                    return
                }
                break
            case 'leftTop':
                if (availableSpaceInfos.availability.leftTop) {
                    element.style.left = 'auto'
                    element.style.right = availableSpaceInfos.right + 'px'
                    element.style.top = 'auto'
                    element.style.bottom = (availableSpaceInfos.bottom + (fixedPosition ? window.scrollY : 0)) + 'px'
                    return
                }
                break
        }
    }
    element.style.left = availableSpaceInfos.left + 'px'
    element.style.right = 'auto'
    element.style.top = availableSpaceInfos.top + 'px'
    element.style.bottom = 'auto'
}

/**
 * Check if target is watchedElement or a child of watchedElement
 * @param target
 * @param watchedElement
 * @returns {boolean|*}
 */
export const isOrContainsTarget = (target, watchedElement) => {
    if (target && watchedElement) {
        return target === watchedElement || watchedElement.contains(target)
    }
    return false
}

/**
 * Get a cookie
 * @param name
 * @returns {string}
 */
export const getCookie = (name) => {
    const cname = name + '='
    const decodedCookie = decodeURIComponent(document.cookie)
    const ca = decodedCookie.split(';')
    for (let i = 0; i < ca.length; i++) {
        let c = ca[i]
        while (c.charAt(0) === ' ') {
            c = c.substring(1)
        }
        if (c.indexOf(cname) === 0) {
            return JSON.parse(c.substring(cname.length, c.length))
        }
    }
    return ''
}

/**
 * Set a cookie
 * @param name
 * @param value
 * @param attributes Optional object that can contains the following attributes : expires (GMT formatted date string), maxAge (number of seconds before expiration), domain (authorized domain string), path (authorized path string)
 */
export const setCookie = (name, value, attributes) => {
    let cookieString = `${name}=${value};`
    if (!isEmpty(attributes)) {
        cookieString += (!isEmpty(attributes.expires)) ? ` expires=${attributes.expires};` : ''
        cookieString += (!isEmpty(attributes.maxAge)) ? ` max-age=${attributes.maxAge};` : ''
        cookieString += (!isEmpty(attributes.domain)) ? ` domain=${attributes.domain};` : ''
        cookieString += (!isEmpty(attributes.path)) ? ` path=${attributes.path};` : ''
    } else {
        cookieString += ` domain=${location.hostname}; path=/;`
    }
    document.cookie = cookieString
}

/**
 * Delete a cookie
 * @param name
 */
export const deleteCookie = (name, attributes) => {
    let cookieString = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 GMT;`
    if (!isEmpty(attributes)) {
        cookieString += (!isEmpty(attributes.domain)) ? ` domain=${attributes.domain};` : ''
        cookieString += (!isEmpty(attributes.path)) ? ` path=${attributes.path};` : ''
    } else {
        cookieString += ` domain=${location.hostname}; path=/;`
    }
    document.cookie = cookieString
}
