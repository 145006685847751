<template lang="html">
    <div class="ProductListPlaceholders">
        <v-skeleton-loader
            type="image"
            class="ProductListPlaceholders-title"
        />
        <v-sheet class="ProductListPlaceholders-block">
            <v-skeleton-loader
                v-for="index in 3"
                :key="index"
                type="image"
                class="ProductListPlaceholders-card"
            />
        </v-sheet>
        <v-skeleton-loader
            type="image"
            class="ProductListPlaceholders-title"
        />
        <v-sheet class="ProductListPlaceholders-block">
            <v-skeleton-loader
                v-for="index in 5"
                :key="index"
                type="image"
                class="ProductListPlaceholders-card"
            />
        </v-sheet>
    </div>
</template>

<style lang="scss" scoped>
@import 'globalScss';

.ProductListPlaceholders {
    &-title {
        width: 100%;
        height: 50px;
        margin-bottom: 15px;
        padding: 0 15px;
    }

    &-block {
        display: flex;
        position: relative;
        overflow-x: auto;
        padding: 20px 0 30px;

        &::-webkit-scrollbar {
            display: none;
        }

        @media #{map-get($display-breakpoints, 'sm-and-up')} {
            display: grid;
            grid-template-columns: repeat(2, calc(50% - 15px / 2));
            gap: 15px;
            overflow: visible;
            padding: 20px 15px 60px;
        }

        @media #{map-get($display-breakpoints, 'md-and-up')} {
            grid-template-columns: repeat(3, calc(100% / 3 - 30px / 3));
        }
    }

    &-card {
        min-width: 300px;
        max-width: 300px;
        min-height: 110px;
        margin-left: 15px;

        &:last-child {
            margin-right: 15px;

            @media #{map-get($display-breakpoints, 'sm-and-up')} {
                margin: 0;
            }
        }

        @media #{map-get($display-breakpoints, 'sm-and-up')} {
            min-width: 0;
            max-width: none;
            margin: 0;
        }
    }
}
</style>
