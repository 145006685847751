import { isEmpty } from '@/StoreWeb/utils/global-utils'

export const useSaleOptionParameters = () => {
    const hasRecipientUser = (saleOptionValue) => !isEmpty(saleOptionValue.recipientUser)

    const applyParametersForEmail = (saleOptionValue) => {
        return {
            email: saleOptionValue.email
        }
    }

    const applyParametersForProviderAccount = (saleOptionValue) => {
        const parameters = {
            providerId: saleOptionValue.providerId,
            providerUserFirstName: saleOptionValue.providerUserFirstName,
            providerUserLastName: saleOptionValue.providerUserLastName,
            providerUserExternalId: saleOptionValue.providerUserExternalId,
            providerUserId: saleOptionValue.providerUserId
        }

        if (hasRecipientUser(saleOptionValue)) {
            parameters.recipientUser = saleOptionValue.recipientUser
        }

        return parameters
    }

    const applyParametersForProviderAccountAndDevice = (saleOptionValue) => {
        return {
            providerId: saleOptionValue.providerId,
            providerUserFirstName: saleOptionValue.providerUserFirstName,
            providerUserLastName: saleOptionValue.providerUserLastName,
            providerUserExternalId: saleOptionValue.providerUserExternalId,
            providerUserId: saleOptionValue.providerUserId,
            deviceId: saleOptionValue.deviceId,
            deviceLabel: saleOptionValue.deviceLabel
        }
    }

    const applyParametersForProviderAccountAndFareMedia = (saleOptionValue) => {
        const parameters = {
            providerId: saleOptionValue.providerId,
            providerUserFirstName: saleOptionValue.providerUserFirstName,
            providerUserLastName: saleOptionValue.providerUserLastName,
            providerUserExternalId: saleOptionValue.providerUserExternalId,
            providerUserId: saleOptionValue.providerUserId,
            fareMediaId: saleOptionValue.fareMediaId
        }

        if (hasRecipientUser(saleOptionValue)) {
            parameters.recipientUser = saleOptionValue.recipientUser
        }

        return parameters
    }

    const applyParametersForProviderFareMedia = (saleOptionValue) => {
        return {
            providerId: saleOptionValue.providerId,
            fareMediaId: saleOptionValue.fareMediaId,
            providerUserExternalId: saleOptionValue.providerUserExternalId
        }
    }

    return {
        applyParametersForEmail,
        applyParametersForProviderAccount,
        applyParametersForProviderAccountAndDevice,
        applyParametersForProviderAccountAndFareMedia,
        applyParametersForProviderFareMedia
    }
}
