import { isEmpty } from 'global-utils'

export const types = [
    'boolean',
    'choice',
    'date',
    'file',
    'hidden',
    'text'
]

export default class {
    constructor (id, type) {
        this._afterAssetPlaceholder = ''
        this._beforeAssetPlaceholder = ''
        this._description = ''
        this._id = id
        this._label = ''
        this._params = null
        this._placeholder = ''
        this._readonly = false
        this._immutable = false
        this._required = false
        this._requiredConditions = null
        this._tooltip = ''
        this._type = type
        this._value = ''
    }

    static get types () {
        return types
    }

    get afterAssetPlaceholder () {
        return this._afterAssetPlaceholder
    }

    set afterAssetPlaceholder (afterAssetPlaceholder) {
        this._afterAssetPlaceholder = afterAssetPlaceholder
    }

    get beforeAssetPlaceholder () {
        return this._beforeAssetPlaceholder
    }

    set beforeAssetPlaceholder (beforeAssetPlaceholder) {
        this._beforeAssetPlaceholder = beforeAssetPlaceholder
    }

    get description () {
        return this._description
    }

    set description (description) {
        this._description = description
    }

    get id () {
        return this._id
    }

    set id (id) {
        this._id = id
    }

    get label () {
        return this._label
    }

    set label (label) {
        this._label = label
    }

    get params () {
        return this._params
    }

    set params (params) {
        this._params = params
    }

    get placeholder () {
        return this._placeholder
    }

    set placeholder (placeholder) {
        this._placeholder = placeholder
    }

    get readonly () {
        return this._readonly
    }

    set readonly (readonly) {
        this._readonly = readonly
    }

    get immutable () {
        return this._immutable
    }

    set immutable (immutable) {
        this._immutable = immutable
    }

    get required () {
        return this._required
    }

    set required (required) {
        this._required = required
    }

    get requiredConditions () {
        return this._requiredConditions
    }

    set requiredConditions (requiredConditions) {
        this._requiredConditions = requiredConditions
    }

    get tooltip () {
        return this._tooltip
    }

    set tooltip (tooltip) {
        this._tooltip = tooltip
    }

    get type () {
        return this._type
    }

    set type (type) {
        this._type = type
    }

    get value () {
        return this._value
    }

    set value (value) {
        this._value = value
    }

    checkIsCompatibleValue (value) {
        switch (this.type) {
            case 'choice':
                if (!isEmpty(this.params)) {
                    if (!isEmpty(this.params.list)) {
                        const foundChoice = this.params.list.find(option => option.id === value)
                        return !isEmpty(foundChoice)
                    }
                }
                return false
        }
        return true
    }

    getChoiceList () {
        if (this.type === 'choice' && !isEmpty(this.params.list)) {
            return this.params.list
        }
        return []
    }

    getConfig () {
        const config = {
            id: this.id,
            inputType: 'text',
            label: this.label,
            options: [],
            readonly: this.readonly,
            immutable: this.immutable,
            required: this.required
        }

        if (this.afterAssetPlaceholder) {
            config.afterAssetPlaceholder = this.afterAssetPlaceholder
        }

        if (this.beforeAssetPlaceholder) {
            config.beforeAssetPlaceholder = this.beforeAssetPlaceholder
        }

        if (typeof this.value !== 'undefined') {
            config.value = this.value
        }

        if (!isEmpty(this.params.autocomplete)) {
            config.autocomplete = this.params.autocomplete
        }

        switch (this.type) {
            case 'boolean':
                if (!isEmpty(this.params) && !isEmpty(this.params.displayMode) && this.params.displayMode === 'switch') {
                    config.componentName = 'ToggleSwitchWithLabel'
                    config.vuetifyComponentName = 'v-switch'
                // } else {
                //     config.componentName = 'Checkbox'
                }
                break
            case 'choice':
                if (!isEmpty(this.params.subType) && this.params.subType === 'select') {
                    config.componentName = 'CustomSelect'
                    config.vuetifyComponentName = 'v-select'
                    config.options = this.params.list
                    config.labelInside = true
                // } else if (!isEmpty(this.params.subType) && this.params.subType === 'expanded_list') {
                //     config.options = this.params.list
                //     if (!isEmpty(this.params.multiple) && this.params.multiple) {
                //         // Checkboxes
                //     } else {
                //         // RadioButtons
                //     }
                }
                break
            case 'date':
                config.componentName = 'DateInputFieldWithLabel'
                config.vuetifyComponentName = 'v-text-field'
                config.description = this.description ?? 'cart:get_cart:error:illogical_cart_structure:message'
                config.inputType = this.params.subType
                config.max = this.params.maxDate ?? ''
                config.min = this.params.minDate ?? ''
                config.placeholder = this.placeholder ?? ''
                break
            // case 'file':
            //     if (!isEmpty(this.params.subType) && this.params.subType === '') {
            //
            //     } else if (!isEmpty(this.params.subType) && this.params.subType === '') {
            //
            //     }
            //     break
            // case 'hidden':
            //     return 'hidden-input'
            case 'text':
                // if (!isEmpty(this.params.subType) && this.params.subType === 'textarea') {
                //     config.componentName = 'textarea'
                // } else {
                config.componentName = 'LabelledInput'
                config.vuetifyComponentName = 'v-text-field'
                config.description = this.description ?? ''
                config.inputType = this.params.subType
                config.maxLength = this.params.maxLength ?? 0
                config.minLength = this.params.minLength ?? 0
                config.placeholder = this.placeholder ?? ''
                // }
                break
        }

        return config
    }

    getValueLabel () {
        if (this.type === 'choice' && !isEmpty(this.params.list)) {
            return this.value.label
        }
        return this.value
    }

    toJSON () {
        return {
            afterAssetPlaceholder: this.afterAssetPlaceholder,
            beforeAssetPlaceholder: this.beforeAssetPlaceholder,
            description: this.description,
            id: this.id,
            label: this.label,
            params: this.params.toJSON(),
            placeholder: this.placeholder,
            readonly: this.readonly,
            immutable: this.immutable,
            required: this.required,
            requiredConditions: this.requiredConditions,
            tooltip: this.tooltip,
            type: this.type,
            value: this.value
        }
    }
}
