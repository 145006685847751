import { ref } from 'vue'
import ErrorManager from '@/StoreWeb/managers/ErrorManager'
import { isEmpty } from '@/StoreWeb/utils/global-utils'
import emitter from 'global-emitter'
import config from 'config'
import store from '@/StoreWeb/store/store'

export function checkHasToCreateMspAccount (product) {
    if (product.modalType === 'MODALPRODUCT' && product?.productType?.id && !isEmpty(product.saleOptions)) {
        const [firstSaleOption] = product.saleOptions

        // TODO update the productType test once the CARDREADER added from the backend
        if (['PROVIDERACCOUNT', 'PROVIDERACCOUNTANDFAREMEDIA', 'PROVIDERACCOUNTANDDEVICE'].includes(firstSaleOption.basketInfo.id) &&
         ['FAREMEDIA'].includes(product.productType.id)) {
            const providerId = firstSaleOption.provider.id
            const provider = store.getters.getWalletProviders.find(provider => provider.id === providerId)

            if (!isEmpty(provider) && config.purchase_flow.type === 'default' && provider?.accounts.length <= 0) {
                return {
                    hasToCreateMspAccount: true,
                    provider
                }
            }
        }
    }

    return {
        hasToCreateMspAccount: false
    }
}

export function triggerAddAccountEvent (provider) {
    if (isEmpty(provider)) {
        return
    }

    emitter.emit('showAddAccountModal', provider)
}

export function useMspAccount () {
    const addAccountModalTemplate = ref()
    const isAddAccountModalShown = ref(false)

    async function getAddAccountFormTemplate (provider) {
        const templateUrl = `${process.env.VUE_APP_CDN_URL}${process.env.VUE_APP_NETWORK_ID}/template/${provider.walletCreateAccount.template}.json`

        try {
            const response = await fetch(templateUrl)

            if (!response.ok) {
                ErrorManager.displayErrorModal('HTTP error', response.status)
            }

            addAccountModalTemplate.value = await response.json()
        } catch (error) {
            ErrorManager.displayErrorModal('Error fetching and parsing JSON', error)
        }
    }

    async function showAddAccountModal (provider) {
        if (isEmpty(addAccountModalTemplate.value)) {
            await getAddAccountFormTemplate(provider)
        }

        isAddAccountModalShown.value = true
    }

    function listenAddAccountEvent () {
        emitter.on('showAddAccountModal', showAddAccountModal)
    }

    return {
        addAccountModalTemplate,
        isAddAccountModalShown,
        listenAddAccountEvent,
        showAddAccountModal
    }
}
