<template>
    <div class="PaymentForm">
        <div class="Container PaymentForm-container">
            <v-btn
                color="primary"
                variant="tonal"
                @click.prevent="returnButtonClicked"
            >
                {{ $t('cart:return') }}
            </v-btn>
            <h1 class="PaymentForm-title">
                {{ $t('payment:title') }}
            </h1>
            <pay-zen-v4-form
                v-if="paymentMethod === 'PayZenV4'"
                :token="code"
                class="PaymentForm-form"
            />
        </div>
    </div>
</template>

<script setup>
import { computed, onMounted } from 'vue'
import { useStore } from 'vuex'
import PayZenV4Form from '@/StoreWeb/components/payment/psp-forms/PayZenV4Form'
import { useRouter } from 'vue-router'

const store = useStore()
const router = useRouter()

const payment = computed(() => store.state.paymentModule.payment)
const code = computed(() => {
    if (codeType.value === 'TOKEN') {
        return payment.value.code
    }
    return ''
})
const codeType = computed(() => {
    if (payment.value !== null) {
        return payment.value.codeType
    }
    return ''
})
const paymentMethod = computed(() => {
    if (codeType.value === 'TOKEN') {
        return 'PayZenV4'
    }
    return ''
})

const returnButtonClicked = () => {
    router.push({ name: 'cartConfirmation' })
}

onMounted(() => {
    localStorage.setItem('paymentType', codeType.value)
})
</script>

<style lang='scss' scoped>
@import 'globalScss';

.PaymentForm {
    background: $color-lightest;

    &-title {
        @extend %h1;

        margin-bottom: 30px;
    }

    &-paymentFormWrapper {
        display: none;
    }
}
</style>
