import { isEmpty } from 'global-utils'
import store from '@/StoreWeb/store/store'
import * as cartMutationTypes from '@/StoreWeb/store/modules/cart/mutation-types'
import * as cartActionTypes from '@/StoreWeb/store/modules/cart/action-types'
import * as mainMutationTypes from '@/StoreWeb/store/modules/main/mutation-types'
import * as userMutationTypes from '@/StoreWeb/store/modules/user/mutation-types'
import Cart from '@/StoreWeb/models/cart/Cart'
import emitter from 'global-emitter'
import i18n from 'i18n'
import * as paymentMutationTypes from '@/StoreWeb/store/modules/payment/mutation-types'
import DebuggingManager from '@/StoreWeb/managers/DebuggingManagerSingleton'
import NavigationGuardManager from '@/StoreWeb/managers/NavigationGuardManagerSingleton'
import router from '@/router/router'
import { getSelectedFareMediaCookie, setSelectedFareMediaCookie, removeSelectedFareMediaCookie } from '@/StoreWeb/js/mixins/wallet-utils'
import FareMedia from '@/StoreWeb/models/user/wallet/FareMedia'
import IdLabelPattern from '@/StoreWeb/models/patterns/IdLabelPattern'

const debuggingManager = DebuggingManager.getInstance()
const navigationGuardManager = NavigationGuardManager.getInstance()

export async function loadCart (params) {
    const cartEmail = params?.email ? params.email : localStorage.getItem('cartEmail')
    const getCartParams = {}

    if (!isEmpty(cartEmail)) {
        await store.commit(cartMutationTypes.SET_ADD_TO_CART_EMAIL, cartEmail)
        getCartParams.email = cartEmail
    }

    if (
        debuggingManager.getMockActionState('getCart') ||
        store.state.userModule.isAuthenticated ||
        !isEmpty(cartEmail)
    ) {
        const cart = await store.dispatch(cartActionTypes.GET_CART, getCartParams)

        return cart
    }

    return false
}

export function constructCartUrl (params) {
    let url = `${process.env.VUE_APP_API_URL}/cart`

    if (store.state.userModule.isAuthenticated) {
        return url
    }

    const email = params.email || store.state.cartModule.addToCartEmail

    if (email) {
        url += `?email=${email}`
        localStorage.setItem('cartEmail', email)
    }

    return url
}

export function showImpossibleToReopenCartAlert (data) {
    emitter.emit('showAlertModal', {
        debugObject: data,
        title: i18n.global.t('cart:reopen:error:title'),
        messageHTML: i18n.global.t('cart:reopen:error:message'),
        closeAction: {
            label: i18n.global.t('cart:reopen:error:close_action_label'),
            onClick: async () => {
                const isCartDeleted = await store.dispatch(cartActionTypes.CLEAR_CART, {
                    id: data.cartId
                })

                if (!isCartDeleted) {
                    return
                }

                cartDeletedHandler({
                    redirectToRouteName: 'catalog'
                })
            }
        }
    })
}

export function showIllogicalCartStructureAlert (data) {
    emitter.emit('showAlertModal', {
        action: {
            label: i18n.global.t('cart:get_cart:error:illogical_cart_structure:action_label'),
            onClick: () => {
                store.dispatch(cartActionTypes.CLEAR_CART, {
                    callback: () => {
                        emitter.emit('hideAlertModal')
                    },
                    id: data.id
                })
            }
        },
        debugObject: data,
        messageHTML: i18n.global.t('cart:get_cart:error:illogical_cart_structure:message'),
        title: i18n.global.t('cart:get_cart:error:default:title')
    })
}

export function isValidGetCartResponse (data) {
    if (isEmpty(data) || isEmpty(data.empty) || isEmpty(data.status)) {
        return false
    }

    const isEmptyCart = isEmpty(data.baskets) && isEmpty(data.packages) && isEmpty(data.plans)

    if (data.empty || isEmptyCart) {
        return !Cart.notEmptyAuthorizedCartStatuses.includes(data.status)
    }

    return !Cart.emptyAuthorizedCartStatuses.includes(data.status)
}

export function isCartDefective (status) {
    return Cart.defectiveStatuses.includes(status)
}

export async function handleDefectiveCart (cartId) {
    store.commit(mainMutationTypes.SET_FEEDBACK_ERROR, i18n.global.t('cart:get_cart:error:defective_cart:message'))
    await store.dispatch(cartActionTypes.CLEAR_CART, { id: cartId })
}

export function handleValidCart (data) {
    store.commit(cartMutationTypes.SET_CART, data)
    store.commit(paymentMutationTypes.SET_PAYMENT_MODES, data.paymentModes ?? [])

    const selectedFareMedia = getSelectedFareMediaCookie()

    if (
        selectedFareMedia &&
        selectedFareMedia.isTemporary &&
        !isSelectedSupportBelongsToCart(selectedFareMedia, data)
    ) {
        removeTemporarySelectedFareMedia()
    }

    if (
        store.state.currentRoute?.name &&
        !navigationGuardManager.isAuthorizedInPaymentProcessingRoute(store.state.currentRoute?.name)
    ) {
        router.push({ name: 'cartConfirmation' })
    }

    return data
}

export function getCartFareMedias (baskets) {
    if (!isEmpty(baskets)) {
        const fareMediasBaskets = baskets.filter((basket) => basket.basketType === 'FAREMEDIA')
        const pendingPurchasingFareMedias = []
        fareMediasBaskets.forEach((basket) => {
            pendingPurchasingFareMedias.push(getFareMediaFromBasket(basket))
        })

        return pendingPurchasingFareMedias
    }

    return []
}

export function getFareMediaFromBasket (basket) {
    const newFareMedia = new FareMedia(basket.fareMediaId, basket.providerId)

    if (basket?.recipientUser?.associationId && !isEmpty(store.getters.getWalletAssociatedAccounts)) {
        const associatedAccount = store.getters.getWalletAssociatedAccounts.find(associatedAccount => associatedAccount.id === basket.recipientUser.associationId)

        if (associatedAccount) {
            const correspondingProvider = associatedAccount.providers.find(provider => provider.id === basket.providerId)

            const correspondingProviderAccount = correspondingProvider.accounts.find(providerAccount => {
                return providerAccount.providerUserExternalId === basket.recipientUser.userId
            })

            if (correspondingProviderAccount) {
                newFareMedia.accountId = correspondingProviderAccount.providerUserExternalId
                newFareMedia.firstName = !isEmpty(correspondingProviderAccount.firstName) ? correspondingProviderAccount.firstName : associatedAccount.firstName
                newFareMedia.lastName = !isEmpty(correspondingProviderAccount.lastName) ? correspondingProviderAccount.lastName : associatedAccount.lastName
                newFareMedia.providerUserId = correspondingProviderAccount.providerUserExternalId
                newFareMedia.providerUserExternalId = correspondingProviderAccount.providerUserExternalId
            }
        }
    } else {
        newFareMedia.accountId = basket.providerUserId
        newFareMedia.firstName = basket.providerUserFirstName
        newFareMedia.lastName = basket.providerUserFirstName
        newFareMedia.providerUserId = basket.providerUserId
        newFareMedia.providerUserExternalId = basket.providerUserExternalId
    }

    newFareMedia.isTemporary = true
    newFareMedia.label = basket.fareMediaId
    newFareMedia.providerFareMediaId = basket.fareMediaId
    newFareMedia.status = new IdLabelPattern('CREATING', i18n.global.t('cart:new_faremedia'))

    return newFareMedia
}

export function isSelectedSupportBelongsToCart (selectedFareMedia, cart) {
    const cartFareMedias = getCartFareMedias(cart.baskets)

    return !isEmpty(cartFareMedias.find(cartFareMedia => cartFareMedia.fareMediaId === selectedFareMedia.fareMediaId))
}

export async function validCartResponseHandler (data) {
    if (data.empty) {
        store.commit(cartMutationTypes.SET_IS_GUEST_PURCHASE_FLOW, false)
    }

    if (isCartDefective(data.status)) {
        await handleDefectiveCart(data.id)
        return
    }

    handleValidCart(data)
}

export function applyDeletedCartConsequences (options = { redirectToRouteName: 'home' }) {
    const redirectRoute = { name: options?.redirectToRouteName ?? 'home' }

    if (
        store.state.currentRoute?.name &&
        !navigationGuardManager.isAuthorizedWithEmptyCartRoute(store.state.currentRoute.name) &&
        store.state.currentRoute.name !== redirectRoute.name
    ) {
        router.push(redirectRoute)
    }
}

export async function cartDeletedHandler (options) {
    emitEventCart('update')
    localStorage.removeItem('cartEmail')
    store.commit(cartMutationTypes.SET_CART, new Cart())
    store.commit(cartMutationTypes.SET_IS_GUEST_PURCHASE_FLOW, false)
    removeTemporarySelectedFareMedia()

    await loadCart()

    applyDeletedCartConsequences(options)
}

export function removeTemporarySelectedFareMedia () {
    const selectedFareMedia = getSelectedFareMediaCookie()

    if (selectedFareMedia && selectedFareMedia.isTemporary) {
        store.commit(userMutationTypes.SET_SELECTED_SUPPORT, null)
        removeSelectedFareMediaCookie()
    }
}

export async function cartLineDeletedHandler () {
    const cart = await loadCart()

    if (cart && cart.status === 'INITIALIZED') {
        store.commit(cartMutationTypes.SET_IS_GUEST_PURCHASE_FLOW, false)

        applyDeletedCartConsequences()
    }
}

export function storeConfirmedCartInfos (data) {
    const authenticated = store.state.userModule.isAuthenticated
    const cart = store.getters.getCart
    let payload = {}
    const hasPostalDelivery = !isEmpty(data?.deliveryClickAndCollectAddress) || !isEmpty(data?.deliveryAddress)
    if (hasPostalDelivery) {
        payload = {
            billingAddress: data.billingAddress,
            deliveryAddress: !isEmpty(data.deliveryAddress) ? data.deliveryAddress : null,
            deliveryClickAndCollectAddress: !isEmpty(data.deliveryClickAndCollectAddress) ? data.deliveryClickAndCollectAddress : null
        }
        if (!isEmpty(cart.id)) {
            payload.authenticated = authenticated
            payload.id = cart.id
        }
        localStorage.removeItem('lastConfirmedCartInfos')
        localStorage.setItem('lastConfirmedCartInfos', JSON.stringify(payload))
    } else {
        payload = {
            billingAddress: data.billingAddress
        }
        if (!isEmpty(cart.id)) {
            payload.authenticated = authenticated
            payload.id = cart.id
            if (!authenticated) {
                const cartEmail = localStorage.getItem('cartEmail')
                if (!isEmpty(cartEmail)) {
                    payload.email = cartEmail
                }
            }
            localStorage.removeItem('lastConfirmedCartInfos')
            localStorage.setItem('lastConfirmedCartInfos', JSON.stringify(payload))
        }
    }
}

export function getCartConfirmPayload (normalizeAddressFieldsFunction) {
    const cartConfirmPayloadStr = localStorage.getItem('lastConfirmedCartInfos')

    if (!cartConfirmPayloadStr) {
        return null
    }

    const cartConfirmPayload = JSON.parse(cartConfirmPayloadStr)

    if (Object.keys(cartConfirmPayload).length === 0) {
        return null
    }

    if (typeof normalizeAddressFieldsFunction === 'function') {
        normalizeAddressFieldsFunction(cartConfirmPayload)
    }

    return cartConfirmPayload
}

export function emitEventCart (status) {
    document.body.dispatchEvent(new CustomEvent('cartUpdated', {
        detail: {
            status
        }
    }))
}

export function checkNeedsAddProductOnFareMedia (data) {
    const lastBasket = data.baskets[data.baskets.length - 1]

    if (lastBasket?.basketType === 'FAREMEDIA' && lastBasket.products.length === 1) {
        const fareMedia = getFareMediaFromBasket(lastBasket)

        store.commit(userMutationTypes.SET_SELECTED_SUPPORT, fareMedia)

        const supportContentCookie = {
            fareMediaId: fareMedia.fareMediaId,
            isTemporary: fareMedia.isTemporary,
            providerId: fareMedia.providerId,
            providerUserExternalId: fareMedia.providerUserExternalId,
            providerUserId: fareMedia.providerUserId
        }

        setSelectedFareMediaCookie(supportContentCookie)
        emitter.emit('selectedSupportChanged')
    }
}
