import { createVuetify } from 'vuetify'
import * as vuetifyComponent from 'vuetify/components'
import 'vuetify/styles'
import '@/StoreWeb/assets/scss/includes/_variables.scss'
import config from 'config'
import * as labsComponents from 'vuetify/labs/components'

export default createVuetify({
    theme: {
        defaultTheme: 'storeweb',
        themes: {
            storeweb: {
                colors: {
                    error: config.theme.color_danger,
                    primary: config.theme.primary_color,
                    secondary: config.theme.color_brand_secondary,
                    info: config.theme.color_info,
                    success: config.theme.color_success,
                    warning: config.theme.color_warning,
                    lightGray1: config.theme.color_lightgray1,
                    lightGray3: config.theme.color_lightgray3
                },
                variables: {
                    'high-emphasis-opacity': config.theme.high_emphasis_opacity,
                    'medium-emphasis-opacity': config.theme.medium_emphasis_opacity,
                    'disabled-opacity': config.theme.disabled_opacity,
                    'idle-opacity': config.theme.idle_opacity,
                    'hover-opacity': config.theme.hover_opacity,
                    'focus-opacity': config.theme.focus_opacity,
                    'selected-opacity': config.theme.selected_opacity,
                    'activated-opacity': config.theme.activated_opacity, // Used for tonal variant background color
                    'pressed-opacity': config.theme.pressed_opacity,
                    'dragged-opacity': config.theme.dragged_opacity
                }
            }
        }
    },
    components: {
        ...vuetifyComponent,
        ...labsComponents
    }
})
